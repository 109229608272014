
import { useState, useEffect } from "react"
import { Hidden, useMediaQuery } from "@material-ui/core"
import {
    Button,
    CircularProgress,
    Menu,
    MenuItem
  } from "@yosgo/swap-ui"
import { Rnd } from "react-rnd"
import InvisibleUploadImageInput from "../../Common/InvisibleUploadImageInput"
import { MemberDataProps } from "@/types/MemberTypes"

interface UploadCoverProps {
    isPreview?: boolean
    setMemberData: (memberData: MemberDataProps) => void
    memberData: MemberDataProps
    setIsOpenDeleteCoverModal: (isOpenDeleteCoverModal: boolean) => void
    handleUpdateCover: (cover: string) => void
    temporaryCover: string
    setTemporaryCover: (temporaryCover: string) => void
    coverForBase64: string
    setCoverForBase64: (coverForBase64: string) => void
    uploadLoading: boolean
    setUploadLoading: (uploadLoading: boolean) => void
    isMobile?: boolean
  }
  
  export const UploadCover = ({
    isPreview,
    setMemberData,
    memberData,
    setIsOpenDeleteCoverModal,
    handleUpdateCover,
    temporaryCover,
    setTemporaryCover,
    coverForBase64,
    setCoverForBase64,
    uploadLoading,
    setUploadLoading,
    isMobile,
  }: UploadCoverProps) => {
    const [y, setY] = useState(0)
    const [moveLimit, setMoveLimit] = useState(0)
    const [anchorEl, setAnchorEl] = useState(null)
    const [isAdjustCover, setIsAdjustCover] = useState(false)
    const [alignItems, setAlignItems] = useState<
      "center" | "flex-start" | "flex-end"
    >("center")
  
    const handleMenuClick = (e: any) => {
      setAnchorEl(e.currentTarget)
    }
  
    const handleMenuClose = () => {
      setAnchorEl(null)
    }
  
    const handleGetCoverYPositon = (cover: string) => {
      if (cover && cover.indexOf("$") !== -1) {
        return Number(cover.split("$")[1])
      } else {
        return 0
      }
    }
  
    useEffect(() => {
      setY(handleGetCoverYPositon(memberData.cover_picture))
    }, [memberData.cover_picture])
  
    const handleUpdateCoverYPositon = (cover: string, y: number) => {
      const value = handleGetCoverYPositon(cover)
      if (value !== 0) {
        const reg = new RegExp(`\\?\\$${value}`, "g")
        const newCover = `${cover.replace(reg, "")}?$${y}`
        return newCover
      } else {
        const newCover = `${cover}?$${y}`
        return newCover
      }
    }
    const matched992 = useMediaQuery("(min-width:992px)")
    useEffect(() => {
      if (y > 0) {
        setAlignItems("flex-start")
      } else if (y < 0) {
        setAlignItems("flex-end")
      } else {
        setAlignItems("center")
      }
    }, [y])
  
    const [imgData, setImgData] = useState({ width: 0, height: 0 })
    const [imgLeft, setImgLeft] = useState(0)
    const [isChangeCutCover, setIsChangeCutCover] = useState(false)
  
    useEffect(() => {
      if (imgData.width !== 0) {
        const limitHeight =
          (isMobile ? 165 : 190) +
          (alignItems === "center" ? 0 : (imgData.height - 190) / 2 - Math.abs(y))
        const limitWidth =
          window.innerWidth > 1200
            ? 1040
            : window.innerWidth > 992
            ? 1040 - (1200 - window.innerWidth)
            : window.innerWidth
        const imgHeightNow = imgData.height * (limitWidth / imgData.width)
  
        if (imgHeightNow < limitHeight) {
          setIsChangeCutCover(true)
          const w = imgData.width * (limitHeight / imgData.height)
          const left = (w - limitWidth) / 2
          setImgLeft(left)
          setMoveLimit(0)
        } else {
          setIsChangeCutCover(false)
          if (alignItems === "center") {
            setMoveLimit((imgData.height - 190) / 2)
          } else {
            setMoveLimit(imgData.height - 190)
          }
        }
      }
      window.addEventListener("resize", () => {
        if (imgData.width !== 0) {
          const limitHeight =
            (isMobile ? 165 : 190) +
            (alignItems === "center"
              ? 0
              : (imgData.height - 190) / 2 - Math.abs(y))
  
          const limitWidth =
            window.innerWidth > 1200
              ? 1040
              : window.innerWidth > 992
              ? 1040 - (1200 - window.innerWidth)
              : window.innerWidth
          const imgHeightNow = imgData.height * (limitWidth / imgData.width)
  
          if (imgHeightNow < limitHeight) {
            setIsChangeCutCover(true)
            const w = imgData.width * (limitHeight / imgData.height)
            const left = (w - limitWidth) / 2
            setImgLeft(left)
            setMoveLimit(0)
          } else {
            setIsChangeCutCover(false)
            if (alignItems === "center") {
              setMoveLimit((imgData.height - 190) / 2)
            } else {
              setMoveLimit(imgData.height - 190)
            }
          }
        }
      })
    }, [imgData, isMobile, y, alignItems])
  
    useEffect(() => {
      let a = new Image()
      a.onload = () => {
        setImgData({ width: 1040, height: (a.height * 1040) / a.width })
      }
      a.src = coverForBase64
    }, [coverForBase64])
  
    return (
      <>
        <div
          style={{
            width: "100%",
            height: isMobile ? 160 : 190,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: matched992 ? "0px 0px 12px 12px" : undefined,
            backgroundColor: "#ECECEC",
            position: "relative",
            overflow: "hidden",
            backgroundImage:
              (isPreview && !temporaryCover) || !temporaryCover
                ? `url("https://swap-img.swap.work/media/profile/cover_emptystate.png")`
                : undefined,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            // maxWidth: 1140,
          }}
        >
          {uploadLoading ? (
            <CircularProgress size={32} thickness={3} />
          ) : temporaryCover ? (
            <Rnd
              disableDragging={!isAdjustCover}
              enableResizing={false}
              dragAxis="y"
              style={{
                cursor: isAdjustCover ? "move" : undefined,
                display: "flex",
                alignItems: alignItems,
              }}
              size={{ width: "100%", height: "100%" }}
              position={{
                x: 0,
                y: isChangeCutCover
                  ? 0
                  : y +
                    (alignItems === "flex-start"
                      ? -moveLimit / 2
                      : alignItems === "flex-end"
                      ? moveLimit / 2
                      : 0),
              }}
              onDragStop={(_e, d) => {
                setY(
                  alignItems === "center"
                    ? d.lastY < -moveLimit
                      ? -moveLimit
                      : d.lastY > moveLimit
                      ? moveLimit
                      : d.lastY
                    : alignItems === "flex-start"
                    ? d.lastY > 0
                      ? moveLimit / 2
                      : d.lastY < -moveLimit / 2
                      ? 0
                      : d.lastY + moveLimit / 2
                    : d.lastY < 0
                    ? -moveLimit / 2 + 5
                    : d.lastY > moveLimit / 2
                    ? 0
                    : d.lastY - moveLimit / 2
                )
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: isChangeCutCover
                    ? alignItems === "center"
                      ? isMobile
                        ? 165
                        : 190
                      : (isMobile ? 165 : 190) +
                        ((imgData.height - 190) / 2 - Math.abs(y))
                    : "fit-content",
                  position: isChangeCutCover ? "absolute" : undefined,
                  left: isChangeCutCover ? -imgLeft : 0,
                  bottom:
                    isChangeCutCover && alignItems === "flex-start"
                      ? 0
                      : undefined,
                  top:
                    isChangeCutCover && alignItems === "flex-end" ? 0 : undefined,
                }}
              >
                <img
                  alt="cover photo"
                  draggable={false}
                  src={coverForBase64}
                  width={isChangeCutCover ? undefined : "100%"}
                  height={"100%"}
                />
              </div>
            </Rnd>
          ) : null}
          <Hidden mdDown>
            {isPreview ? null : !isAdjustCover ? (
              temporaryCover ? (
                <Button
                  variant="secondary"
                  size="small"
                  onClick={handleMenuClick}
                  style={{ position: "absolute", right: 40, bottom: 15 }}
                >
                  編輯封面圖片
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  size="small"
                  onClick={() => {}}
                  style={{ position: "absolute", right: 40, bottom: 15 }}
                >
                  <InvisibleUploadImageInput
                    width={"100%"}
                    height={"100%"}
                    beforeUploadFunction={() => { setUploadLoading(true) }}
                    afterUploadFunction={(r, base64) => {
                      setTemporaryCover(r)
                      setCoverForBase64(base64)
                      setMemberData({ ...memberData, cover_picture: r })
                      handleUpdateCover(r)
                      setUploadLoading(false)
                    }}
                  />
                  新增封面圖片
                </Button>
              )
            ) : (
              <Button
                variant="secondary"
                size="small"
                onClick={() => {
                  setIsAdjustCover(false)
                  const newCover = handleUpdateCoverYPositon(temporaryCover, y)
                  setTemporaryCover(newCover)
                  setMemberData({ ...memberData, cover_picture: newCover })
                  handleUpdateCover(newCover)
                }}
                style={{ position: "absolute", right: 40, bottom: 15 }}
              >
                儲存
              </Button>
            )}
          </Hidden>
          {isAdjustCover && (
            <div
              style={{
                width: 156,
                height: 48,
                borderRadius: 12,
                boxShadow: " 0px 6px 24px rgba(0, 0, 0, 0.1)",
                backgroundColor: "rgba(45, 45, 45, 0.6)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                color: "#ffffff",
              }}
            >
              拖曳圖片調整位置
            </div>
          )}
        </div>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: -82,
            vertical: 48,
          }}
          getContentAnchorEl={null}
          keepMounted
          onClose={handleMenuClose}
        >
          <MenuItem
            width={200}
            onClick={() => {
              handleMenuClose()
            }}
            style={{ position: "relative" }}
          >
            {icon_edit_gray}
            上傳封面
            <InvisibleUploadImageInput
              width={"100%"}
              height={"100%"}
              beforeUploadFunction={() => {
                setUploadLoading(true)
              }}
              afterUploadFunction={(r, base64) => {
                setTemporaryCover(r)
                setCoverForBase64(base64)
                setMemberData({ ...memberData, cover_picture: r })
                setUploadLoading(false)
                handleUpdateCover(r)
              }}
            />
          </MenuItem>
          <MenuItem
            width={200}
            onClick={() => {
              handleMenuClose()
              setIsAdjustCover(true)
            }}
          >
            {icon_move}
            調整位置
          </MenuItem>
          <MenuItem
            width={200}
            onClick={() => {
              handleMenuClose()
              setIsOpenDeleteCoverModal(true)
            }}
          >
            {icon_delete}
            刪除封面
          </MenuItem>
        </Menu>
      </>
    )
  }

  const icon_edit_gray = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 8 }}
    >
      <path
        d="M4.16667 2.5C3.24167 2.5 2.5 3.24167 2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V10H15.8333V15.8333H4.16667V4.16667H10V2.5H4.16667ZM14.8167 3.33333C14.675 3.33333 14.525 3.39167 14.4167 3.5L13.4 4.50833L15.4833 6.59167L16.5 5.58333C16.7167 5.36667 16.7167 5 16.5 4.79167L15.2083 3.5C15.1 3.39167 14.9583 3.33333 14.8167 3.33333ZM12.8083 5.1L6.66667 11.25V13.3333H8.75L14.8917 7.18333L12.8083 5.1Z"
        fill="#6F6F6F"
      />
    </svg>
  )
  
  const icon_delete = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 8 }}
    >
      <path
        d="M7.49998 2.5V3.33333H3.33331V5H4.16665V15.8333C4.16665 16.2754 4.34224 16.6993 4.6548 17.0118C4.96736 17.3244 5.39129 17.5 5.83331 17.5H14.1666C14.6087 17.5 15.0326 17.3244 15.3452 17.0118C15.6577 16.6993 15.8333 16.2754 15.8333 15.8333V5H16.6666V3.33333H12.5V2.5H7.49998ZM5.83331 5H14.1666V15.8333H5.83331V5ZM7.49998 6.66667V14.1667H9.16665V6.66667H7.49998ZM10.8333 6.66667V14.1667H12.5V6.66667H10.8333Z"
        fill="#6F6F6F"
      />
    </svg>
  )


const icon_move = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 8 }}
    >
      <path
        d="M8.3335 7.50016H11.6668V5.00016H14.1668L10.0002 0.833496L5.8335 5.00016H8.3335V7.50016ZM7.50016 8.3335H5.00016V5.8335L0.833496 10.0002L5.00016 14.1668V11.6668H7.50016V8.3335ZM19.1668 10.0002L15.0002 5.8335V8.3335H12.5002V11.6668H15.0002V14.1668L19.1668 10.0002ZM11.6668 12.5002H8.3335V15.0002H5.8335L10.0002 19.1668L14.1668 15.0002H11.6668V12.5002Z"
        fill="#6F6F6F"
      />
    </svg>
  )