import {
  Typography,
  SWAPSpace,
  SWAPShare,
  Modal,
  TextField,
  Tooltip,
} from '@yosgo/swap-ui'
import { IconButton, makeStyles, Theme } from '@material-ui/core'
import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { ShareStyleProps } from '@/types/StyleTypes'

const useStyles = makeStyles<Theme, ShareStyleProps>((theme: Theme) => ({
  icon: {
    width: 32,
    height: 32,
    borderRadius: 8,
    '& span': {
      borderRadius: 8,
    },
    '&:hover': {
      backgroundColor: theme.black.black400,
      borderRadius: 8,
    },
  },
  tooltip: (props) => ({
    backgroundColor: props.backgroundColor,
    padding: '7.5px 12px',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: 1.4,
  }),
  arrow: (props) => ({
    color: props.arrowColor,
  }),
}))

interface ProfileShareModalProps {
  open: boolean
  onClose: () => void
  id: string
}

const ProfileShareModal = ({ open, onClose, id }: ProfileShareModalProps) => {
  const [isCopied, setIsCopied] = useState(false)

  const styleProps: ShareStyleProps = {
    backgroundColor: isCopied ? '#006305' : '#2D2D2D',
    arrowColor: isCopied ? '#006305' : '#2D2D2D',
  }
  const classes = useStyles(styleProps)
  const shareLink = `${window.location.origin}/profile/${id}`
  return (
    <Modal
      size="medium"
      open={open}
      onClose={onClose}
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {icon_share} 分享接案履歷
        </div>
      }
      primaryButton={{ title: '回到接案履歷', onClick: onClose }}
      children={
        <>
          <Typography variant="title">
            將個人的連結分享出去，讓別人了解你的相關內容
          </Typography>
          <SWAPSpace size={24} />
          <TextField
            fullWidth
            height={56}
            label="接案履歷連結"
            value={shareLink}
            InputProps={{
              endAdornment: (
                <CopyToClipboard text={shareLink}>
                  <Tooltip
                    title={isCopied ? '已複製！' : '複製連結'}
                    arrow
                    placement="top"
                    classes={{
                      tooltip: classes.tooltip,
                      arrow: classes.arrow,
                    }}
                  >
                    <IconButton
                      className={classes.icon}
                      onClick={() => {
                        setIsCopied(true)
                      }}
                    >
                      {icon_copy}
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              ),
            }}
          />
          <SWAPSpace />
          <Typography variant="title">或是透過以下方法分享給客戶：</Typography>
          <SWAPSpace />
          <SWAPShare url={shareLink} />
        </>
      }
    />
  )
}

const icon_copy = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8333 17.4999H6.66663V5.83325H15.8333V17.4999ZM15.8333 4.16658H6.66663C6.2246 4.16658 5.80067 4.34218 5.48811 4.65474C5.17555 4.9673 4.99996 5.39122 4.99996 5.83325V17.4999C4.99996 17.9419 5.17555 18.3659 5.48811 18.6784C5.80067 18.991 6.2246 19.1666 6.66663 19.1666H15.8333C16.2753 19.1666 16.6992 18.991 17.0118 18.6784C17.3244 18.3659 17.5 17.9419 17.5 17.4999V5.83325C17.5 5.39122 17.3244 4.9673 17.0118 4.65474C16.6992 4.34218 16.2753 4.16658 15.8333 4.16658ZM13.3333 0.833252H3.33329C2.89127 0.833252 2.46734 1.00885 2.15478 1.32141C1.84222 1.63397 1.66663 2.05789 1.66663 2.49992V14.1666H3.33329V2.49992H13.3333V0.833252Z"
      fill="#6F6F6F"
    />
  </svg>
)

const icon_share = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: 8 }}
  >
    <path
      d="M16.0001 5L14.5801 6.42L12.9901 4.83V16H11.0101V4.83L9.42012 6.42L8.00012 5L12.0001 1L16.0001 5ZM20.0001 10V21C20.0001 22.1 19.1001 23 18.0001 23H6.00012C4.89012 23 4.00012 22.1 4.00012 21V10C4.00012 8.89 4.89012 8 6.00012 8H9.00012V10H6.00012V21H18.0001V10H15.0001V8H18.0001C19.1001 8 20.0001 8.89 20.0001 10Z"
      fill="#6F6F6F"
    />
  </svg>
)

export default ProfileShareModal
