import { debounce, makeStyles } from "@material-ui/core"
import {
  CircularProgress,
  Link,
  Modal,
  SWAPSpace,
  TextField,
  Typography,
} from "@yosgo/swap-ui"
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react"
import { TablerIconsKeyProps } from "./create/declare"
import * as TablerIcons from "@tabler/icons"
import { searchIcon } from "./create/utils/searchIcon"
import InvisibleUploadImageInput from "../../Common/InvisibleUploadImageInput"
import { LinkProps } from "@/types/LinkTypes"

const useStyles = makeStyles(() => ({
  uploadBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 16,
    width: 60,
    height: 60,
    minWidth: 60,
    minHeight: 60,
    marginRight: 16,
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
  },
  uploadBlockHover: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(1,1,1,0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& path": {
      fill: "#ffffff",
    },
  },
  uploadBlockEmpty: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      opacity: 0.6,
    },
  },
  selectBlock: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #cccccc",
    boxShadow: "border-box",
    borderRadius: 8,
    width: "100%",
    height: 80,
    overflow: "hidden",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.6,
    },
  },
  editButton: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& path": {
      fill: "#909090",
    },
    "&:hover": {
      "& path": {
        fill: "#000000",
      },
    },
  },
  inputStyle: {
    width: "100%",
    border: "unset",
    fontSize: 16,
    padding: 0,
    height: 22,
    "&:focus": {
      outline: "unset",
    },
  },
  iconStyle: {
    width: 60,
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    border: "1px solid #cccccc",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #000000",
    },
  },
}))

interface EditIconProps {
  onClick: () => void
}

interface LinkEditBlockProps {
  setLinks: (links: LinkProps[]) => void
  blockIndex: number
  links: LinkProps[]
  isAddLink?: boolean
  isMobile?: boolean
}

interface UploadPicProps {
  onClick: () => void
  pic: string
  loading: boolean
}

const LinkEditBlock = ({
  setLinks,
  links,
  blockIndex,
  isAddLink,
  isMobile,
}: LinkEditBlockProps) => {
  const [isEditTitle, setIsEditTitle] = useState(false)
  const [isEditLink, setIsEditLink] = useState(false)
  const [temporaryTitle, setTemporaryTitle] = useState("")
  const [temporaryLinkUrl, setTemporaryLinkUrl] = useState("")
  const [isOpenIconsModal, setIsOpenIconsModal] = useState(false)
  const [searchIconResult, setSearchIconResult] = useState<string[]>([])
  const [searchWord, setSearchWord] = useState("")
  const [uploadPicLoading, setUploadPicLoading] = useState(false)
  const [isOpenUploadPicModal, setIsOpenUploadPicModal] = useState(false)
  const link = useMemo(() => {
    return links[blockIndex]
  }, [links])

  useEffect(() => {
    if (link) {
      setTemporaryLinkUrl(link.link)
      setTemporaryTitle(link.title)
    }
  }, [link])

  const iconArray =
    searchIconResult && searchWord ? searchIconResult : Object.keys(TablerIcons)

  const ref = useRef(null)
  const classes = useStyles()
  return (
    <>
      <div
        style={{
          height: 92,
          width: "100%",
          border: "1px solid #cccccc",
          backgroundColor: "#ffffff",
          borderRadius: 8,
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          padding: 16,
        }}
      >
        <UploadPic
          pic={link?.meta_picture_base64 || link?.meta_picture}
          onClick={() => setIsOpenUploadPicModal(true)}
          loading={uploadPicLoading}
        />
        <div style={{ width: "100%" }}>
          <div
            style={{ marginBottom: 8, display: "flex", alignItems: "center" }}
          >
            {isEditTitle ? (
              <input
                autoFocus
                placeholder="輸入標題..."  
                title="輸入標題" 
                className={classes.inputStyle}
                value={temporaryTitle}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setTemporaryTitle(e.target.value)
                }}
                onBlur={() => {
                  let a: LinkProps[] = []
                  if (isAddLink) {
                    for (let i = 0; i < blockIndex + 1; i++) {
                      if (i === blockIndex) {
                        a[i] = {
                          id: blockIndex + 1,
                          meta_picture: link?.meta_picture,
                          title: temporaryTitle,
                          link: link?.link,
                          index: i,
                          type: "normal",
                          meta_picture_base64: link?.meta_picture_base64,
                        }
                      } else {
                        a[i] = links[i]
                      }
                    }
                  } else {
                    for (let i = 0; i < links.length; i++) {
                      if (i === blockIndex) {
                        a[i] = {
                          id: link?.id,
                          meta_picture: link?.meta_picture,
                          title: temporaryTitle,
                          link: link?.link,
                          index: i,
                          type: "normal",
                          meta_picture_base64: link?.meta_picture_base64,
                        }
                      } else {
                        a[i] = links[i]
                      }
                    }
                  }
                  setLinks(a)
                  setIsEditTitle(false)
                }}
              />
            ) : (
              <>
                <Typography
                  variant="title"
                  style={{
                    marginRight: 8,
                    wordBreak: "break-all",
                    whiteSpace: "pre-line",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {link?.title ? link?.title : "標題"}
                </Typography>
                <EditIcon
                  onClick={() => {
                    setIsEditTitle(true)
                  }}
                />
              </>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {isEditLink ? (
              <input
                autoFocus
                className={classes.inputStyle}
                value={temporaryLinkUrl}
                placeholder="www."  
                title="輸入連結" 
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setTemporaryLinkUrl(e.target.value)
                }}
                onBlur={() => {
                  let a: LinkProps[] = []
                  if (isAddLink) {
                    for (let i = 0; i < blockIndex + 1; i++) {
                      if (i === blockIndex) {
                        a[i] = {
                          id: blockIndex + 1,
                          meta_picture: link?.meta_picture,
                          title: link?.title,
                          link: temporaryLinkUrl,
                          index: i,
                          type: "normal",
                          meta_picture_base64: link?.meta_picture_base64,
                        }
                      } else {
                        a[i] = links[i]
                      }
                    }
                  } else {
                    for (let i = 0; i < links.length; i++) {
                      if (i === blockIndex) {
                        a[i] = {
                          id: link?.id,
                          meta_picture: link?.meta_picture,
                          title: link?.title,
                          link: temporaryLinkUrl,
                          index: i,
                          type: "normal",
                          meta_picture_base64: link?.meta_picture_base64,
                        }
                      } else {
                        a[i] = links[i]
                      }
                    }
                  }
                  setLinks(a)
                  setIsEditLink(false)
                }}
                type="url"
              />
            ) : (
              <>
                {link?.link ? (
                  <Link
                    target="_blank"
                    href={
                      link?.link.indexOf("http") === -1
                        ? `https://${link?.link}`
                        : link?.link
                    }
                    style={{
                      fontSize: 16,
                      fontWeight: 400,
                      marginRight: 8,
                      wordBreak: "break-all",
                      whiteSpace: "pre-line",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {link?.link}
                  </Link>
                ) : (
                  <Typography variant="body1" style={{ marginRight: 8 }}>
                    連結
                  </Typography>
                )}
                <EditIcon
                  onClick={() => {
                    setIsEditLink(true)
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        mobile={isMobile}
        fullWidth={isMobile}
        open={isOpenUploadPicModal}
        onClose={() => {
          setIsOpenUploadPicModal(false)
        }}
        title={"新增連結"}
        maxWidth={640}
        children={
          <>
            <Typography variant="title">請選擇縮圖樣式</Typography>
            <SWAPSpace />
            {[
              {
                picPath: "https://swap-img.swap.work/media/profile/pic1.png",
                text: "上傳圖片",
                onClick: () => {},
              },
              {
                picPath: "https://swap-img.swap.work/media/profile/pic2.png",
                text: "使用 ICON",
                onClick: () => {
                  setIsOpenIconsModal(true)
                  setIsOpenUploadPicModal(false)
                },
              },
            ].map((i, index) => (
              <div
                key={index}
                style={{
                  marginBottom: index === 0 ? 16 : 0,
                }}
                onClick={i.onClick}
                className={classes.selectBlock}
              >
                {index === 0 && (
                  <InvisibleUploadImageInput
                    width={"calc(100% - 48px)"}
                    height={80}
                    beforeUploadFunction={() => {
                      setIsOpenUploadPicModal(false)
                      setUploadPicLoading(true)
                    }}
                    afterUploadFunction={(r, base64) => {
                      setUploadPicLoading(false)
                      let a: LinkProps[] = []
                      if (isAddLink) {
                        for (let i = 0; i < blockIndex + 1; i++) {
                          if (i === blockIndex) {
                            a[i] = {
                              id: blockIndex + 1,
                              meta_picture: r,
                              title: links[blockIndex]?.title,
                              link: links[blockIndex]?.link,
                              index: i,
                              type: "normal",
                              meta_picture_base64: base64,
                            }
                          } else {
                            a[i] = links[i]
                          }
                        }
                      } else {
                        for (let i = 0; i < links.length; i++) {
                          if (i === blockIndex) {
                            a[i] = {
                              id: links[blockIndex]?.id,
                              meta_picture: r,
                              title: links[blockIndex]?.title,
                              link: links[blockIndex]?.link,
                              index: i,
                              type: "normal",
                              meta_picture_base64: base64,
                            }
                          } else {
                            a[i] = links[i]
                          }
                        }
                      }

                      setLinks(a)

                      setIsOpenUploadPicModal(false)
                    }}
                  />
                )}
                <img
                  alt="picPath"
                  src={i.picPath}
                  height={80}
                  width={80}
                  style={{ marginRight: 24 }}
                />
                <Typography variant="title">{i.text}</Typography>
              </div>
            ))}
          </>
        }
      />
      <Modal
        mobile={isMobile}
        fullWidth={isMobile}
        open={isOpenIconsModal}
        onClose={() => {
          setIsOpenIconsModal(false)
          setIsOpenUploadPicModal(true)
          setSearchWord("")
        }}
        title="選擇縮圖"
        maxWidth={645}
        height={isMobile ? undefined : 415}
        children={
          <>
            <Typography variant="caption1">Icons by Tabler Icons</Typography>
            <SWAPSpace />
            <TextField
              inputRef={ref}
              height={40}
              fullWidth
              placeholder="搜尋圖示"
              onChange={debounce(async () => {
                const r: any = ref.current
                if (r) {
                  const result = await searchIcon(r.value)
                  setSearchWord(r.value)
                  setSearchIconResult(result)
                }
              }, 500)}
              InputProps={{ startAdornment: icon_search }}
            />
            <SWAPSpace />
            {iconArray.length > 0 ? (
              <div
                style={{
                  display: "grid",
                  alignItems: "center",
                  gap: 16,
                  gridTemplateColumns: "repeat(auto-fill, 60px)",
                }}
              >
                {iconArray.map((i, index) => {
                  const Icon = TablerIcons[`${i}` as TablerIconsKeyProps]
                  return (
                    <div
                      className={classes.iconStyle}
                      key={index}
                      onClick={() => {
                        let a: LinkProps[] = []

                        if (isAddLink) {
                          for (let j = 0; j < blockIndex + 1; j++) {
                            if (j === blockIndex) {
                              a[j] = {
                                id: blockIndex + 1,
                                meta_picture: i,
                                title: links[blockIndex]?.title,
                                link: links[blockIndex]?.link,
                                index: j,
                                type: "normal",
                                meta_picture_base64: "",
                              }
                            } else {
                              a[j] = links[j]
                            }
                          }
                        } else {
                          for (let j = 0; j < links.length; j++) {
                            if (j === blockIndex) {
                              a[j] = {
                                id: links[blockIndex]?.id,
                                meta_picture: i,
                                title: links[blockIndex]?.title,
                                link: links[blockIndex]?.link,
                                index: j,
                                type: "normal",
                                meta_picture_base64: "",
                              }
                            } else {
                              a[j] = links[j]
                            }
                          }
                        }

                        setLinks(a)
                        setIsOpenIconsModal(false)
                        setSearchWord("")
                      }}
                    >
                      <Icon color="#909090" stroke={1.5} size={40} />
                    </div>
                  )
                })}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 40,
                }}
              >
                <div style={{ textAlign: "center" }}>
                  {icon_search_big}
                  <Typography
                    variant="body2"
                    color="black800"
                    style={{ marginTop: 16 }}
                  >
                    搜尋不到，請重新輸入關鍵字
                  </Typography>
                </div>
              </div>
            )}
          </>
        }
      />
    </>
  )
}

const UploadPic = ({ loading, onClick, pic }: UploadPicProps) => {
  const [isHover, setIsHover] = useState(false)
  const classes = useStyles()
  const Icon = TablerIcons[`${pic}` as TablerIconsKeyProps]

  return (
    <div
      className={classes.uploadBlock}
      onClick={onClick}
      style={{ backgroundColor: "#ececec" }}
      onMouseEnter={() => {
        if (pic) {
          setIsHover(true)
        }
      }}
      onMouseLeave={() => {
        if (pic) {
          setIsHover(false)
        }
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : pic ? (
        pic.indexOf("https") !== -1 || pic.indexOf("base64") !== -1 ? (
          <img alt="pic" src={pic} height={60} width={60} />
        ) : (
          <Icon color="#909090" stroke={1.5} size={40} />
        )
      ) : (
        <div className={classes.uploadBlockEmpty}>{icon_plus}</div>
      )}
      {isHover && <div className={classes.uploadBlockHover}>{icon_edit}</div>}
    </div>
  )
}

const EditIcon = ({ onClick }: EditIconProps) => {
  const classes = useStyles()
  return (
    <div className={classes.editButton} onClick={onClick}>
      {icon_edit}
    </div>
  )
}

const icon_edit = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.16667 2.5C3.24167 2.5 2.5 3.24167 2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V10H15.8333V15.8333H4.16667V4.16667H10V2.5H4.16667ZM14.8167 3.33333C14.675 3.33333 14.525 3.39167 14.4167 3.5L13.4 4.50833L15.4833 6.59167L16.5 5.58333C16.7167 5.36667 16.7167 5 16.5 4.79167L15.2083 3.5C15.1 3.39167 14.9583 3.33333 14.8167 3.33333ZM12.8083 5.1L6.66667 11.25V13.3333H8.75L14.8917 7.18333L12.8083 5.1Z" />
  </svg>
)

const icon_plus = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="#6F6F6F" />
  </svg>
)

const icon_search = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginRight: 4 }}
  >
    <path
      d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
      fill="#6F6F6F"
    />
  </svg>
)

const icon_search_big = (
  <svg
    width="64"
    height="64"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5 14H14.71L14.43 13.73C15.41 12.59 16 11.11 16 9.5C16 5.91 13.09 3 9.5 3C5.91 3 3 5.91 3 9.5C3 13.09 5.91 16 9.5 16C11.11 16 12.59 15.41 13.73 14.43L14 14.71V15.5L19 20.49L20.49 19L15.5 14ZM9.5 14C7.01 14 5 11.99 5 9.5C5 7.01 7.01 5 9.5 5C11.99 5 14 7.01 14 9.5C14 11.99 11.99 14 9.5 14Z"
      fill="#6F6F6F"
    />
  </svg>
)

export default LinkEditBlock
