import React, { useState, useEffect, useCallback, useRef } from "react";

import { makeStyles } from "@material-ui/core";
import { 
  Typography, 
  SWAPSpace, 
  Button, 
  Modal, 
  Switch,
  Skeleton,
  Link } from "@yosgo/swap-ui";
import { Grid } from "@material-ui/core";
import { getPersonalAchievementsFromSOHO, 
        toggleAchievementVisibleFromSOHO } from "@/utils/appRequest/achievements";
import CenterLoading from "@/components/Common/CenteredLoading";
import { useMediaQuery } from '@material-ui/core';

import type { AchievementProps } from "@/types/AchievementTypes.d";
import type { MemberDataProps  } from "@/types/MemberTypes.d"; 

const useStyles = makeStyles((theme,) => ({
    container: {
      backgroundColor: "#ffffff",
      borderRadius: "12px",
      // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    },
    achievementsGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(180px, 1fr))",
      gap: "16px",
      alignItems: "start",
      [theme.breakpoints.between(992, 1063)]: { // 調整 992px 到 1063px 的排版
        gridTemplateColumns: "repeat(2, 1fr)",  // 這段寬度區間每排顯示兩個成就
      },
      [theme.breakpoints.between(460, 1991)]: { // 調整 992px 到 1063px 的排版
        gridTemplateColumns: "repeat(3, 1fr)",  // 這段寬度區間每排顯示兩個成就
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: "repeat(2, 1fr)",
      },
    },
    achievementItem: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      padding: "12px 16px 12px 24px",
      border: "1px solid #ccc",
      background : "#fff",
      borderRadius: "12px",
      transition: "background-color 0.3s",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    },
    achievementItemOuter: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "12px 16px",
      background: "#fff",
      borderRadius: "12px",
      transition: "background-color 0.3s",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    },
    achievementImageWrapper: {
      position: "relative",
      width: "80px",  // Increased size to accommodate shadow
      height: "80px", // Increased size to accommodate shadow
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    achievementImage: {
      width: "60px",
      height: "60px",
      objectFit: "contain",
      borderRadius: "50%",
      position: "relative",
      zIndex: 2,
    },
    achievementShadow: {
      position: "absolute",
      width: "80px",
      height: "80px",
      // width: "90%",
      // height: "90%",
      borderRadius: "50%",
      // top: "35%",
      // left: "50%",
      // transform: "translate(-50%, -50%)",
      zIndex: 1,
      opacity: 0.8,
      transition: "all 0.3s ease",
    },
    operationShadowManage : {
      // top: "40%",
      // left: "50%",
    },
    paymentShadowManage : {
      // top: "40%",
      // left: "50%",
    },
    communityShadowManage : {
      // top: "10%",
      // left: "50%",
    },
    operationShadowPublic : {
      top: -10,
      // left: "50%",
    },
    paymentShadowPublic : {
      top: -10,
      // left: "50%",
    },
    communityShadowPublic : {
      top: -10,
      // left: "50%",
    },
    operationShadow: {
      background: `
      radial-gradient(circle at 30% 30%, rgba(116, 194, 236, 0.8) 0%, rgba(116, 197, 228, 0.2) 60%, rgba(108, 127, 213, 0) 70%),
      radial-gradient(circle at 70% 70%, rgba(108, 127, 213, 0.8) 0%, rgba(108, 127, 213, 0.2) 60%, rgba(108, 127, 213, 0) 70%)
      `,
      filter: "blur(8px)",
      transform: "scale(0.5)",
      "&:hover": {
        transform: "scale(0.6)",
      },
    },
    paymentShadow: {
      background: `
      radial-gradient(circle at 30% 30%, rgba(231, 243, 120, 0.2) 0%, rgba(239, 228, 83, 0.05) 60%, rgba(247, 181, 44, 0) 70%),
      radial-gradient(circle at 70% 70%, rgba(238, 168, 28, 0.4) 0%, rgba(248, 175, 29, 0.1) 60%, rgba(247, 181, 44, 0) 70%)
      `,
      filter: "blur(8px)",
      transform: "scale(0.5)",
      "&:hover": {
        transform: "scale(0.6)",
      },
    },
    communityShadow: {
      background: `
      radial-gradient(circle at 30% 30%, rgba(246, 150, 230, 0.2) 0%, rgba(243, 135, 169, 0.05) 60%, rgba(247, 108, 69, 0) 70%),
      radial-gradient(circle at 70% 70%, rgba(245, 84, 39, 0.4) 0%, rgba(223, 87, 50, 0.1) 60%, rgba(247, 108, 69, 0) 70%)
      `,
      filter: "blur(8px)",
      transform: "scale(0.5)",
      "&:hover": {
        transform: "scale(0.6)",
      },
    },
    operationShadowBig: {
      background: `
      radial-gradient(circle at 30% 30%, rgba(116, 194, 236, 0.8) 0%, rgba(116, 197, 228, 0.2) 60%, rgba(108, 127, 213, 0) 70%),
      radial-gradient(circle at 70% 70%, rgba(108, 127, 213, 0.8) 0%, rgba(108, 127, 213, 0.2) 60%, rgba(108, 127, 213, 0) 70%)
      `,
      filter: "blur(8px)",
      transform: "scale(0.6)",
      "&:hover": {
        transform: "scale(0.7)",
      },
    },
    paymentShadowBig: {
      background: `
      radial-gradient(circle at 30% 30%, rgba(231, 243, 120, 0.2) 0%, rgba(239, 228, 83, 0.05) 60%, rgba(247, 181, 44, 0) 70%),
      radial-gradient(circle at 70% 70%, rgba(238, 168, 28, 0.4) 0%, rgba(248, 175, 29, 0.1) 60%, rgba(247, 181, 44, 0) 70%)
      `,
      filter: "blur(8px)",
      transform: "scale(0.6)",
      "&:hover": {
        transform: "scale(0.7)",
      },
    },
    communityShadowBig: {
      background: `
      radial-gradient(circle at 30% 30%, rgba(246, 150, 230, 0.2) 0%, rgba(243, 135, 169, 0.05) 60%, rgba(247, 108, 69, 0) 70%),
      radial-gradient(circle at 70% 70%, rgba(245, 84, 39, 0.4) 0%, rgba(223, 87, 50, 0.1) 60%, rgba(247, 108, 69, 0) 70%)
      `,
      filter: "blur(8px)",
      transform: "scale(0.6)",
      "&:hover": {
        transform: "scale(0.7)",
      },
    },
    starContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "4px",
      marginTop: "4px",
    },
    focusVisible: {
      outline: `2px solid ${theme.palette.primary.main}`,
      outlineOffset: 2,
    },
  }));
interface AchievementProfileComponentProps {
  isFromGuest: boolean;
  isPreview: boolean;
  memberData: MemberDataProps;
  hasVisibleAchievements: boolean;
  checkAchievements: () => Promise<void>;
  goRefreshTrigger?: number;
  isDesktop?: boolean;
}

export const AchievementProfileComponent: React.FC<AchievementProfileComponentProps> = ({ 
  isFromGuest, 
  isPreview, 
  memberData, 
  hasVisibleAchievements, 
  checkAchievements, 
  goRefreshTrigger,
  isDesktop = true
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');

  const [achievements, setAchievements] = useState<AchievementProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tempVisibilityChanges, setTempVisibilityChanges] = useState<{[key: string]: boolean}>({});

  const [selectedAchievement, setSelectedAchievement] = useState<AchievementProps | null>(null);

  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);
  const [showLearnMore, setShowLearnMore] = useState<boolean>(false);
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    const img = new Image();
    img.src = "https://swap-img.swap.work/achievement/Img_Profile_Achievement+Badge+Empty@2x.png";
    img.onload = () => setImageLoaded(true);
  }, []);

  const descriptionRef = useRef<HTMLDivElement>(null);
  const MAX_HEIGHT = 200;

  const fetchAchievements = useCallback(async () => {
    if (isFromGuest) {
      // 如果是訪客模式，直接使用 memberData 中的成就
      setAchievements(memberData.achievements);
      setLoading(false);
    } else {
      try {
        setLoading(true);
        const result = await getPersonalAchievementsFromSOHO();

        if (result && result.status === "200" && result.errors === null) {
          setAchievements(result.payload);
        } else {
          throw result.errors || new Error("Failed to fetch achievements");
        }
      } catch (error) {
        console.log("Error fetching achievements:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [isFromGuest, memberData]);


  useEffect(() => {
    fetchAchievements();
  }, [fetchAchievements, goRefreshTrigger]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    let observer: MutationObserver;

    const checkContentHeight = () => {
      if (descriptionRef.current) {
        const scrollHeight = descriptionRef.current.scrollHeight;
        setContentHeight(scrollHeight);
        setShowLearnMore(scrollHeight > MAX_HEIGHT);
      }
    };

    if (isDetailModalOpen) {
      timeoutId = setTimeout(() => {
        checkContentHeight();
        observer = new MutationObserver(checkContentHeight);

        if (descriptionRef.current) {
          observer.observe(descriptionRef.current, { childList: true, subtree: true });
        }
      }, 0);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      if (observer) observer.disconnect();
    };
  }, [isDetailModalOpen]);

  const handleAchievementClick = useCallback((achievement: AchievementProps) => {
    setSelectedAchievement(achievement);
    setIsDetailModalOpen(true);
    setIsExpanded(false);
  }, []);

  const toggleExpand = useCallback(() => {
    setIsExpanded(prev => !prev);
  }, []);
  
  const handleToggleVisibility = useCallback((achievementId: string, currentVisibility: boolean) => {
    setTempVisibilityChanges(prev => ({
      ...prev,
      [achievementId]: !currentVisibility
    }));
  }, []);

  const applyVisibilityChanges = useCallback(async () => {
    try {
      if((Object.keys(tempVisibilityChanges).length !== 0)){
        const promises = Object.entries(tempVisibilityChanges).map(([id]) => 
          toggleAchievementVisibleFromSOHO(id)
        );
        await Promise.all(promises);
        await fetchAchievements();
        setIsModalOpen(false);
        await checkAchievements();
        setTempVisibilityChanges({});
      } else {
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error("Error updating achievements visibility:", error);
    }
  }, [tempVisibilityChanges, fetchAchievements, checkAchievements]);

  const getAchievementShadowClass = (type: string, showState?:string) => {
    let shadowClass = classes.achievementShadow;
    if (showState === "public") {
      if (type.startsWith("operation")) {
        shadowClass += ` ${classes.operationShadowBig}`;
        shadowClass += ` ${classes.operationShadowPublic}`;
      } else if (type.startsWith("payment")) {
        shadowClass += ` ${classes.paymentShadowBig}`;
        shadowClass += ` ${classes.paymentShadowPublic}`;
      } else if (type.startsWith("community")) {
        shadowClass += ` ${classes.communityShadowBig}`;
        shadowClass += ` ${classes.communityShadowPublic}`;
      }
    } else if (showState === "profile") {
      if (type.startsWith("operation")) {
        shadowClass += ` ${classes.operationShadow}`;
      } else if (type.startsWith("payment")) {
        shadowClass += ` ${classes.paymentShadow}`;
      } else if (type.startsWith("community")) {
        shadowClass += ` ${classes.communityShadow}`;
      }
    } else if (showState === "manage") {
      if (type.startsWith("operation")) {
        shadowClass += ` ${classes.operationShadowBig}`;
        shadowClass += ` ${classes.operationShadowManage}`;
      } else if (type.startsWith("payment")) {
        shadowClass += ` ${classes.paymentShadowBig}`;
        shadowClass += ` ${classes.paymentShadowManage}`;
      } else if (type.startsWith("community")) {
        shadowClass += ` ${classes.communityShadowBig}`;
        shadowClass += ` ${classes.communityShadowManage}`;
      }
    }
    return shadowClass;
  };
  
  const renderDetailModal = () => {
    return (
      <Modal
        open={isDetailModalOpen}
        onClose={() => {
          setIsDetailModalOpen(false);
          setIsExpanded(false);
        }}
        title={selectedAchievement?.name}
        size="large"
        bodyMaxHeight="calc(100vh - 200px)"
        fullWidth={isMobile}
      >
        {selectedAchievement && (
          <div style={{ padding: "24px" }}>
            <div style={{ display: "flex", marginBottom: "24px", flexDirection: isMobile ? "column" : "row" }}>
              <div style={{ marginRight: "24px", position: "relative"}}>
                <div style={{ width: "100%", height: "100%", position: "relative", marginBottom: "8px",    display: "flex",
                flexDirection: "column",
                alignItems: "center" }}>
                  <div className={`${classes.achievementShadow} ${getAchievementShadowClass(selectedAchievement.type, "public")}`} 
                  style={{ width: "180px", height: "180px" }} ></div>
                  <img 
                    src={selectedAchievement.picture_url} 
                    alt={selectedAchievement.name}
                    style={{ width: "132px", height: "132px", objectFit: "contain", position: "relative", zIndex: 2 }}
                  />
                  <div style={{ 
                    display: "flex", 
                    justifyContent: "center",
                    gap: "4px"
                  }}>
                    {Array.from({ length: renderStars(selectedAchievement.percentage) }, (_, index) => (
                      <img 
                        key={index}
                        style={{ width: 24, height: 24 }}
                        alt="star-image"
                        src="https://swap-img.swap.work/achievement/Star@2x.png"
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ position: "relative", marginBottom: "16px", padding: "16px 24px" }}>
                  <div style={{
                    width: "16px",
                    height: "16px",
                    position: "absolute",
                    left: "0px",
                    top: "0px",
                    borderTop: "6px solid #ECECEC",
                    borderLeft: "6px solid #ECECEC"
                  }} />
                  <div style={{
                    width: "16px",
                    height: "16px",
                    position: "absolute",
                    right: "0px",
                    bottom: "0px",
                    borderBottom: "6px solid #ECECEC",
                    borderRight: "6px solid #ECECEC"
                  }} />
                  <Typography variant="body2">
                    {/* {memberData.nick_name}已在 SWAP 平台成功{selectedAchievement.name.includes("SWAP 贊助者") ? "成為 " + selectedAchievement.name: selectedAchievement.name.includes("自由工作者") ? "完成" + selectedAchievement.name: selectedAchievement.name }。 */}
                    {memberData.nick_name ? memberData.nick_name + selectedAchievement.subtitle.replace("您", "").trim()+"。" : selectedAchievement.subtitle+"。"  }
                  </Typography>
                </div>
                <Typography variant="body2" style={{ fontWeight: "bold", marginBottom: "8px" }}>獲得方式</Typography>
                <Typography variant="body1" style={{ marginBottom: "16px" }}>
                  {selectedAchievement.acquisition_method}
                </Typography>
                {/* <Typography variant="body2" style={{ fontWeight: "bold", marginBottom: "8px" }}>關於成就</Typography> */}
                <div 
                  ref={descriptionRef}
                  style={{
                    maxHeight: isExpanded ? `${contentHeight}px` : `${MAX_HEIGHT}px`,
                    overflow: "hidden",
                    transition: "max-height 0.3s ease-out"
                  }}
                >
                  <div dangerouslySetInnerHTML={{__html:selectedAchievement.description}}/>
                </div>
                {showLearnMore && (
                  <Link 
                    onClick={toggleExpand}
                    color="primary400"
                    variant="subtitle1"
                    style={{
                      cursor: "pointer", 
                      marginTop: "16px", 
                      display: "block",
                      transition: "opacity 0.3s ease-out",
                    }}
                    underline="none"
                  >
                    {isExpanded ? "收起" : "了解更多"}
                  </Link>
                )}
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  };

  const renderAchievementModal = () => (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setIsModalOpen(false);
        setTempVisibilityChanges({});
      }}
      title="管理成就"
      bodyMaxHeight="calc(100vh - 200px)"
      size="large"
      primaryButton={{
        title : "完成",
        onClick: applyVisibilityChanges,
      }}
      secondaryButton={{
        title : "取消",
        onClick: () => {
          setIsModalOpen(false);
          setTempVisibilityChanges({});
        },
      }}
    >
       {acquiredAchievements.length === 0 ? (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "24px" }}>
          <img
            src="https://swap-img.swap.work/achievement/Img_Profile_Achievement+Badge+Empty@2x.png"
            alt="empty-achievements"
            style={{ width: "250px", height: "auto", filter: "grayscale(100%)" , marginTop : -30}}
          />
          <Typography variant="title" style={{ marginTop : -30}}>
            尚無成就徽章，快去完成任務、獲得成就徽章吧！
          </Typography>
          <SWAPSpace size="l" />
          <Link href="/member/achievements">
            <Button variant="secondary" onClick={()=>{}} style={{ border: "2px solid"}}>前往成就任務</Button>
          </Link>
        </div>
      ) : (
      <Grid container spacing={2}>
        {acquiredAchievements.length === 0 && 
          <Typography variant="body1">No acquired achievements.</Typography>
        }
        {acquiredAchievements.map((achievement) => {
          const achievementId = achievement.personal_achievement?.id || "";
          const isVisible = tempVisibilityChanges.hasOwnProperty(achievementId)
            ? tempVisibilityChanges[achievementId]
            : achievement.personal_achievement?.is_visible || false;

          const starCount = renderStars(achievement.percentage);

            return (
          <Grid item xs={12} key={achievement.id}>
            <div className={classes.achievementItem}>
              <div className={classes.achievementImageWrapper}>
                <div style={{ display: "flex", 
                              flexDirection: "column", 
                              alignItems: "center", 
                              justifyContent: "center" }}>
                 <div className={`${classes.achievementShadow} ${getAchievementShadowClass(achievement.type, "manage")}`}></div>
                  <img
                    alt={`${achievement.name} achievement icon`}
                    className={classes.achievementImage}
                    src={achievement.picture_url}
                  />
                  <div style={{ 
                                display: "flex", 
                                justifyContent: "center", 
                              }}>
                  {Array.from({ length: starCount }, (_, index) => (
                      <img 
                        key={index}
                        style={{ width: 16, height: 16 }}
                        src="https://swap-img.swap.work/achievement/Star@2x.png"
                        alt="star-image"
                      />
                  ))}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                <Typography variant="title">{achievement.name}</Typography>
                <SWAPSpace size="xxs" />
                <Typography variant="body2">{achievement.subtitle}</Typography>
              </div>
              <Switch
                  checked={isVisible}
                  onChange={() => handleToggleVisibility(achievementId, isVisible)}
                  focusVisibleClassName={classes.focusVisible}
                  focusRipple
                  TouchRippleProps={{
                    center: true,
                  }}
                  aria-label={`Toggle visibility for ${achievement.name}`}
                />
            </div>
          </Grid>
          );
          })}
      </Grid>
      )}
    </Modal>
  );

  if (loading) {
    return <CenterLoading />;
  }

  const acquiredAchievements = achievements.filter(a => a.personal_achievement !== null);
  const visibleAchievements = isFromGuest
  ? memberData.achievements.filter(a => a.personal_achievement !== null)
  : achievements.filter(a => a.personal_achievement?.is_visible);

  // if ((isPreview || isFromGuest) && visibleAchievements.length === 0) {
  //   return null;
  // }
  if ((isPreview || isFromGuest) && !hasVisibleAchievements) {
    return null;
  }

  const renderStars = (percentage: number) => {
    if (percentage <= 25) {
      return 4;
    } else if (percentage <= 50) {
      return 3;
    } else if (percentage <= 75) {
      return 2;
    } else {
      return 1;
    }
  };

  return (
    <div className={classes.container}
         style={{  padding: "40px 40px 24px 40px" }}
         >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h6">我的成就</Typography>
        { !isFromGuest && !isPreview && isDesktop &&
          <Button size="small" onClick={() => setIsModalOpen(true)}>管理成就</Button>
        }
      </div>
      {visibleAchievements.length > 0 ? (
        <>
      <div style={{
            width: "100%",
            height: 1,
            backgroundColor: "#ececec",
            margin: "24px 0",
          }}
        />
      <SWAPSpace size="m" />
      <div className={classes.achievementsGrid}>
        {visibleAchievements.map((achievement, index) => {

          const starCount = renderStars(achievement.percentage);

          return (
          <div 
            key={`ach_${index}`} 
            className={classes.achievementItemOuter}
            style={{ cursor : "pointer"}}
            onClick={() => handleAchievementClick(achievement)}
          >
            <div className={classes.achievementImageWrapper}>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 8 }}>
                <div style={{ width: 132, height: 132 }} 
                      className={`${classes.achievementShadow} ${getAchievementShadowClass(achievement.type, "profile")}`}>
                </div>
                  <img
                    alt="achievement_img"
                    className={classes.achievementImage}
                    src={achievement.picture_url}
                    style={{ width: 80, height: 80 }}
                  />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {Array.from({ length: starCount }, (_, index) => (
                      <img 
                        key={index}
                        style={{ width: 16, height: 16 }}
                        src="https://swap-img.swap.work/achievement/Star@2x.png"
                        alt="star-image"
                      />
                  ))}
                </div>
              </div>
            </div>
            <SWAPSpace size="l" />
            <Typography variant="title">{achievement.name}</Typography>
          </div>
          )
        })}
      </div> 
      </>): (!isFromGuest && !isPreview) && (
        <div style={{ display : "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          { imageLoaded ? (
          <img
            src="https://swap-img.swap.work/achievement/Img_Profile_Achievement+Badge+Empty@2x.png"
            alt="empty-achievements"
            style={{ width: "250px", height: "auto" }}
          /> ):(
          <Skeleton
            variant="rect"
            width={250}
            height={250}
            animation="wave"
          />
          )}
          <Typography variant="h6">
            展示你的成就徽章
          </Typography>
          <SWAPSpace size="m" />
          <Typography variant="body2">  
          透過成就徽章，客戶可以看到你完成的各項工作者成就，增加專業信任度
          </Typography>
          <SWAPSpace size="xl" />
        </div>
      )}
      {renderAchievementModal()}
      {renderDetailModal()}
    </div>
  );
};