import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  loadingContainer: {
    position: "fixed",
    top: -50,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)", // 半透明白色背景
    zIndex: 9999,
  },
  loadingText: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#4862CC", // 使用 SWAP UI 的主色调
    animation: "$ellipsis 0.3s infinite",
  },
  "@keyframes ellipsis": {
    "0%": {
      content: '"Loading"',
    },
    "33%": {
      content: '"Loading."',
    },
    "66%": {
      content: '"Loading.."',
    },
    "100%": {
      content: '"Loading..."',
    },
  },
});


const CenteredLoading: React.FC<{word? : string}> = ({ word = "" }) => {
  const classes = useStyles();

  return (
    <div className={classes.loadingContainer}>
      <div className={classes.loadingText}> 
        {word}
      </div>
    </div>
  );
};

export default CenteredLoading;