const searchTags = {
  Icon2fa: {
    category: '',
    tags: ['2fa', 'login', 'password', 'verification', 'code', 'two-step'],
    version: '1.17',
    unicode: 'eca0',
  },
  Icon3dCubeSphere: {
    category: '',
    tags: ['3d', 'cube', 'sphere', 'printing', 'vector', 'shape'],
    version: '1.20',
    unicode: 'ecd7',
  },
  Icon3dRotate: {
    category: '',
    tags: ['3d', 'rotate'],
    version: '1.55',
    unicode: 'f020',
  },
  IconAB: {
    category: '',
    tags: ['a', 'b', 'test', 'visual', 'user'],
    version: '1.11',
    unicode: 'ec36',
  },
  IconAccessPointOff: {
    category: 'Devices',
    tags: [
      'access',
      'point',
      'off',
      'device',
      'hosts',
      'airwaves',
      'wireless',
      'network',
    ],
    version: '1.25',
    unicode: 'ed1a',
  },
  IconAccessPoint: {
    category: 'Devices',
    tags: [
      'access',
      'point',
      'device',
      'hosts',
      'airwaves',
      'wireless',
      'network',
    ],
    version: '1.25',
    unicode: 'ed1b',
  },
  IconAccessible: {
    category: '',
    tags: ['accessible', 'low-vision', 'blind', 'disability', 'handicapped'],
    version: '1.4',
    unicode: 'eba9',
  },
  IconActivity: {
    category: '',
    tags: ['activity', 'pulse', 'motion', 'health', 'action'],
    version: '1.0',
    unicode: 'ed23',
  },
  IconAd2: {
    category: 'Design',
    tags: ['ad', '2'],
    version: '1.41',
    unicode: 'ef1f',
  },
  IconAd: {
    category: 'Design',
    tags: [
      'ad',
      'advert',
      'advertisement',
      'marketing',
      'commercial',
      'traffic',
    ],
    version: '1.1',
    unicode: 'ea02',
  },
  IconAddressBook: {
    category: '',
    tags: ['address', 'book'],
    version: '1.55',
    unicode: 'f021',
  },
  IconAdjustmentsAlt: {
    category: 'System',
    tags: [
      'adjustments',
      'alt',
      'equalizer',
      'sliders',
      'controls',
      'settings',
      'filter',
    ],
    version: '1.11',
    unicode: 'ec37',
  },
  IconAdjustmentsHorizontal: {
    category: 'System',
    tags: [
      'adjustments',
      'horizontal',
      'equalizer',
      'sliders',
      'controls',
      'settings',
      'filter',
    ],
    version: '1.11',
    unicode: 'ec38',
  },
  IconAdjustments: {
    category: 'System',
    tags: [
      'adjustments',
      'equalizer',
      'sliders',
      'controls',
      'settings',
      'filter',
    ],
    version: '1.0',
    unicode: 'ea03',
  },
  IconAerialLift: {
    category: 'Vehicles',
    tags: [
      'aerial',
      'lift',
      'cable',
      'car',
      'gondola',
      'mountains',
      'ski',
      'tramway',
    ],
    version: '1.39',
    unicode: 'edfe',
  },
  IconAffiliate: {
    category: '',
    tags: [
      'affiliate',
      'network',
      'connection',
      'collaboration',
      'people',
      'connect',
      'organization',
      'networking',
    ],
    version: '1.39',
    unicode: 'edff',
  },
  IconAlarm: {
    category: '',
    tags: ['alarm', 'time', 'watch', 'clock', 'ring'],
    version: '1.1',
    unicode: 'ea04',
  },
  IconAlbum: {
    category: '',
    tags: ['album'],
    version: '1.55',
    unicode: 'f022',
  },
  IconAlertCircle: {
    category: '',
    tags: ['alert', 'circle', 'warning', 'danger', 'caution', 'risk'],
    version: '1.0',
    unicode: 'ea05',
  },
  IconAlertOctagon: {
    category: '',
    tags: ['alert', 'octagon', 'warning', 'danger', 'caution', 'risk'],
    version: '1.19',
    unicode: 'ecc6',
  },
  IconAlertTriangle: {
    category: '',
    tags: ['alert', 'triangle', 'warning', 'danger', 'caution', 'risk'],
    version: '1.0',
    unicode: 'ea06',
  },
  IconAlien: {
    category: '',
    tags: [
      'alien',
      'universe',
      'extraterrestrial',
      'ufo',
      'space',
      'galaxy',
      'planet',
    ],
    version: '1.7',
    unicode: 'ebde',
  },
  IconAlignCenter: {
    category: 'Text',
    tags: ['align', 'center', 'text', 'alignment', 'position'],
    version: '1.0',
    unicode: 'ea07',
  },
  IconAlignJustified: {
    category: 'Text',
    tags: ['align', 'justified', 'text', 'alignment', 'position'],
    version: '1.0',
    unicode: 'ea08',
  },
  IconAlignLeft: {
    category: 'Text',
    tags: ['align', 'left', 'text', 'alignment', 'position'],
    version: '1.0',
    unicode: 'ea09',
  },
  IconAlignRight: {
    category: 'Text',
    tags: ['align', 'right', 'text', 'alignment', 'position'],
    version: '1.0',
    unicode: 'ea0a',
  },
  IconAmbulance: {
    category: 'Vehicles',
    tags: [
      'ambulance',
      'vehicle',
      'car',
      'hospital',
      'ward',
      'doctor',
      'rescuer',
    ],
    version: '1.8',
    unicode: 'ebf5',
  },
  IconAnchor: {
    category: 'Map',
    tags: ['anchor', 'hold', 'ship', 'harbor', 'docks'],
    version: '1.3',
    unicode: 'eb76',
  },
  IconAngle: {
    category: 'Design',
    tags: ['angle'],
    version: '1.41',
    unicode: 'ef20',
  },
  IconAntennaBars1: {
    category: 'Devices',
    tags: ['antenna', 'bars', '1', 'signal', 'wireless', 'wi-fi', 'quality'],
    version: '1.19',
    unicode: 'ecc7',
  },
  IconAntennaBars2: {
    category: 'Devices',
    tags: ['antenna', 'bars', '2', 'signal', 'wireless', 'wi-fi', 'quality'],
    version: '1.19',
    unicode: 'ecc8',
  },
  IconAntennaBars3: {
    category: 'Devices',
    tags: ['antenna', 'bars', '3', 'signal', 'wireless', 'wi-fi', 'quality'],
    version: '1.19',
    unicode: 'ecc9',
  },
  IconAntennaBars4: {
    category: 'Devices',
    tags: ['antenna', 'bars', '4', 'signal', 'wireless', 'wi-fi', 'quality'],
    version: '1.19',
    unicode: 'ecca',
  },
  IconAntennaBars5: {
    category: 'Devices',
    tags: ['antenna', 'bars', '5', 'signal', 'wireless', 'wi-fi', 'quality'],
    version: '1.19',
    unicode: 'eccb',
  },
  IconAperture: {
    category: 'Photography',
    tags: ['aperture', 'hole', 'opening', 'vent'],
    version: '1.2',
    unicode: 'eb58',
  },
  IconApiApp: {
    category: '',
    tags: ['api', 'app'],
    version: '1.53',
    unicode: 'effc',
  },
  IconApi: {
    category: '',
    tags: ['api'],
    version: '1.53',
    unicode: 'effd',
  },
  IconAppWindow: {
    category: '',
    tags: ['app', 'window'],
    version: '1.52',
    unicode: 'efe6',
  },
  IconApple: {
    category: 'Food',
    tags: ['apple'],
    version: '1.41',
    unicode: 'ef21',
  },
  IconApps: {
    category: '',
    tags: ['apps', 'application', 'add-on', 'user', 'download', 'mobile'],
    version: '1.5',
    unicode: 'ebb6',
  },
  IconArchive: {
    category: '',
    tags: ['archive', 'box', 'index', 'records', 'old', 'collect'],
    version: '1.0',
    unicode: 'ea0b',
  },
  IconArmchair2: {
    category: '',
    tags: ['armchair', '2'],
    version: '1.52',
    unicode: 'efe7',
  },
  IconArmchair: {
    category: '',
    tags: ['armchair'],
    version: '1.48',
    unicode: 'ef9e',
  },
  IconArrowAutofitContent: {
    category: 'Arrows',
    tags: ['arrow', 'autofit', 'content'],
    version: '1.42',
    unicode: 'ef31',
  },
  IconArrowAutofitDown: {
    category: 'Arrows',
    tags: ['arrow', 'autofit', 'down'],
    version: '1.42',
    unicode: 'ef32',
  },
  IconArrowAutofitHeight: {
    category: 'Arrows',
    tags: ['arrow', 'autofit', 'height'],
    version: '1.42',
    unicode: 'ef33',
  },
  IconArrowAutofitLeft: {
    category: 'Arrows',
    tags: ['arrow', 'autofit', 'left'],
    version: '1.42',
    unicode: 'ef34',
  },
  IconArrowAutofitRight: {
    category: 'Arrows',
    tags: ['arrow', 'autofit', 'right'],
    version: '1.42',
    unicode: 'ef35',
  },
  IconArrowAutofitUp: {
    category: 'Arrows',
    tags: ['arrow', 'autofit', 'up'],
    version: '1.42',
    unicode: 'ef36',
  },
  IconArrowAutofitWidth: {
    category: 'Arrows',
    tags: ['arrow', 'autofit', 'width'],
    version: '1.42',
    unicode: 'ef37',
  },
  IconArrowBackUp: {
    category: 'Arrows',
    tags: ['arrow', 'back', 'up', 'pointer', 'return', 'revert', 'reverse'],
    version: '1.3',
    unicode: 'eb77',
  },
  IconArrowBack: {
    category: 'Arrows',
    tags: ['arrow', 'back', 'pointer', 'return', 'revert', 'reverse'],
    version: '1.1',
    unicode: 'ea0c',
  },
  IconArrowBarDown: {
    category: 'Arrows',
    tags: ['arrow', 'bar', 'down', 'drag', 'move'],
    version: '1.0',
    unicode: 'ea0d',
  },
  IconArrowBarLeft: {
    category: 'Arrows',
    tags: ['arrow', 'bar', 'left', 'drag', 'move'],
    version: '1.0',
    unicode: 'ea0e',
  },
  IconArrowBarRight: {
    category: 'Arrows',
    tags: ['arrow', 'bar', 'right', 'drag', 'move'],
    version: '1.0',
    unicode: 'ea0f',
  },
  IconArrowBarToDown: {
    category: 'Arrows',
    tags: ['arrow', 'bar', 'to', 'down', 'drag', 'move'],
    version: '1.15',
    unicode: 'ec88',
  },
  IconArrowBarToLeft: {
    category: 'Arrows',
    tags: ['arrow', 'bar', 'to', 'left', 'drag', 'move'],
    version: '1.15',
    unicode: 'ec89',
  },
  IconArrowBarToRight: {
    category: 'Arrows',
    tags: ['arrow', 'bar', 'to', 'right', 'drag', 'move'],
    version: '1.15',
    unicode: 'ec8a',
  },
  IconArrowBarToUp: {
    category: 'Arrows',
    tags: ['arrow', 'bar', 'to', 'up', 'drag', 'move'],
    version: '1.15',
    unicode: 'ec8b',
  },
  IconArrowBarUp: {
    category: 'Arrows',
    tags: ['arrow', 'bar', 'up', 'drag', 'move'],
    version: '1.0',
    unicode: 'ea10',
  },
  IconArrowBigDownLine: {
    category: 'Arrows',
    tags: ['arrow', 'big', 'down', 'line'],
    version: '1.52',
    unicode: 'efe8',
  },
  IconArrowBigDownLines: {
    category: 'Arrows',
    tags: ['arrow', 'big', 'down', 'lines'],
    version: '1.52',
    unicode: 'efe9',
  },
  IconArrowBigDown: {
    category: 'Arrows',
    tags: ['arrow', 'big', 'down'],
    version: '1.37',
    unicode: 'edda',
  },
  IconArrowBigLeftLine: {
    category: 'Arrows',
    tags: ['arrow', 'big', 'left', 'line'],
    version: '1.52',
    unicode: 'efea',
  },
  IconArrowBigLeftLines: {
    category: 'Arrows',
    tags: ['arrow', 'big', 'left', 'lines'],
    version: '1.52',
    unicode: 'efeb',
  },
  IconArrowBigLeft: {
    category: 'Arrows',
    tags: ['arrow', 'big', 'left'],
    version: '1.37',
    unicode: 'eddb',
  },
  IconArrowBigRightLine: {
    category: 'Arrows',
    tags: ['arrow', 'big', 'right', 'line'],
    version: '1.52',
    unicode: 'efec',
  },
  IconArrowBigRightLines: {
    category: 'Arrows',
    tags: ['arrow', 'big', 'right', 'lines'],
    version: '1.52',
    unicode: 'efed',
  },
  IconArrowBigRight: {
    category: 'Arrows',
    tags: ['arrow', 'big', 'right'],
    version: '1.37',
    unicode: 'eddc',
  },
  IconArrowBigTop: {
    category: 'Arrows',
    tags: ['arrow', 'big', 'top'],
    version: '1.37',
    unicode: 'eddd',
  },
  IconArrowBigUpLine: {
    category: 'Arrows',
    tags: ['arrow', 'big', 'up', 'line'],
    version: '1.52',
    unicode: 'efee',
  },
  IconArrowBigUpLines: {
    category: 'Arrows',
    tags: ['arrow', 'big', 'up', 'lines'],
    version: '1.52',
    unicode: 'efef',
  },
  IconArrowBottomBar: {
    category: 'Arrows',
    tags: ['arrow', 'bottom', 'bar', 'drag', 'move'],
    version: '1.35',
    unicode: 'ed98',
  },
  IconArrowBottomCircle: {
    category: 'Arrows',
    tags: ['arrow', 'bottom', 'circle', 'drag', 'move'],
    version: '1.35',
    unicode: 'ed99',
  },
  IconArrowBottomSquare: {
    category: 'Arrows',
    tags: ['arrow', 'bottom', 'square', 'drag', 'move'],
    version: '1.35',
    unicode: 'ed9a',
  },
  IconArrowBottomTail: {
    category: 'Arrows',
    tags: ['arrow', 'bottom', 'tail', 'drag', 'move'],
    version: '1.35',
    unicode: 'ed9b',
  },
  IconArrowDownCircle: {
    category: 'Arrows',
    tags: ['arrow', 'down', 'circle', 'proceed', 'swipe', 'below', 'bottom'],
    version: '1.0',
    unicode: 'ea11',
  },
  IconArrowDownLeftCircle: {
    category: 'Arrows',
    tags: ['arrow', 'down', 'left', 'circle', 'corner', 'bottom', 'point'],
    version: '1.0',
    unicode: 'ea12',
  },
  IconArrowDownLeft: {
    category: 'Arrows',
    tags: ['arrow', 'down', 'left', 'corner', 'bottom', 'point'],
    version: '1.0',
    unicode: 'ea13',
  },
  IconArrowDownRightCircle: {
    category: 'Arrows',
    tags: ['arrow', 'down', 'right', 'circle', 'corner', 'bottom', 'point'],
    version: '1.0',
    unicode: 'ea14',
  },
  IconArrowDownRight: {
    category: 'Arrows',
    tags: ['arrow', 'down', 'right', 'corner', 'bottom', 'point'],
    version: '1.0',
    unicode: 'ea15',
  },
  IconArrowDown: {
    category: 'Arrows',
    tags: ['arrow', 'down', 'proceed', 'swipe', 'below', 'bottom'],
    version: '1.0',
    unicode: 'ea16',
  },
  IconArrowForwardUp: {
    category: 'Arrows',
    tags: ['arrow', 'forward', 'up', 'point', 'turn', 'next'],
    version: '1.3',
    unicode: 'eb78',
  },
  IconArrowForward: {
    category: 'Arrows',
    tags: ['arrow', 'forward', 'point', 'turn', 'next'],
    version: '1.1',
    unicode: 'ea17',
  },
  IconArrowLeftBar: {
    category: 'Arrows',
    tags: ['arrow', 'left', 'bar', 'drag', 'move'],
    version: '1.35',
    unicode: 'ed9c',
  },
  IconArrowLeftCircle: {
    category: 'Arrows',
    tags: ['arrow', 'left', 'circle', 'drag', 'move'],
    version: '1.35',
    unicode: 'ea18',
  },
  IconArrowLeftSquare: {
    category: 'Arrows',
    tags: ['arrow', 'left', 'square', 'drag', 'move'],
    version: '1.35',
    unicode: 'ed9d',
  },
  IconArrowLeftTail: {
    category: 'Arrows',
    tags: ['arrow', 'left', 'tail', 'drag', 'move'],
    version: '1.35',
    unicode: 'ed9e',
  },
  IconArrowLeft: {
    category: 'Arrows',
    tags: ['arrow', 'left', 'back', 'swipe', 'return'],
    version: '1.0',
    unicode: 'ea19',
  },
  IconArrowLoopLeft: {
    category: 'Arrows',
    tags: ['arrow', 'loop', 'left', 'drag', 'move', 'turn'],
    version: '1.35',
    unicode: 'ed9f',
  },
  IconArrowLoopRight: {
    category: 'Arrows',
    tags: ['arrow', 'loop', 'right', 'drag', 'move', 'turn'],
    version: '1.35',
    unicode: 'eda0',
  },
  IconArrowNarrowDown: {
    category: 'Arrows',
    tags: ['arrow', 'narrow', 'down', 'bottom', 'proceed', 'swipe', 'next'],
    version: '1.0',
    unicode: 'ea1a',
  },
  IconArrowNarrowLeft: {
    category: 'Arrows',
    tags: ['arrow', 'narrow', 'left', 'back', 'previous', 'pointer', 'point'],
    version: '1.0',
    unicode: 'ea1b',
  },
  IconArrowNarrowRight: {
    category: 'Arrows',
    tags: ['arrow', 'narrow', 'right', 'next', 'proceed', 'point', 'pointer'],
    version: '1.0',
    unicode: 'ea1c',
  },
  IconArrowNarrowUp: {
    category: 'Arrows',
    tags: ['arrow', 'narrow', 'up', 'top', 'point'],
    version: '1.0',
    unicode: 'ea1d',
  },
  IconArrowRampLeft: {
    category: 'Arrows',
    tags: ['arrow', 'ramp', 'left', 'direction', 'side', 'turn'],
    version: '1.28',
    unicode: 'ed3c',
  },
  IconArrowRampRight: {
    category: 'Arrows',
    tags: ['arrow', 'ramp', 'right', 'direction', 'side', 'turn'],
    version: '1.28',
    unicode: 'ed3d',
  },
  IconArrowRightBar: {
    category: 'Arrows',
    tags: ['arrow', 'right', 'bar', 'drag', 'move'],
    version: '1.35',
    unicode: 'eda1',
  },
  IconArrowRightCircle: {
    category: 'Arrows',
    tags: ['arrow', 'right', 'circle', 'drag', 'move'],
    version: '1.35',
    unicode: 'ea1e',
  },
  IconArrowRightSquare: {
    category: 'Arrows',
    tags: ['arrow', 'right', 'square'],
    version: '1.35',
    unicode: 'eda2',
  },
  IconArrowRightTail: {
    category: 'Arrows',
    tags: ['arrow', 'right', 'tail'],
    version: '1.35',
    unicode: 'eda3',
  },
  IconArrowRight: {
    category: 'Arrows',
    tags: ['arrow', 'right', 'next', 'proceed', 'swipe'],
    version: '1.0',
    unicode: 'ea1f',
  },
  IconArrowTopBar: {
    category: 'Arrows',
    tags: ['arrow', 'top', 'bar'],
    version: '1.35',
    unicode: 'eda4',
  },
  IconArrowTopCircle: {
    category: 'Arrows',
    tags: ['arrow', 'top', 'circle'],
    version: '1.35',
    unicode: 'eda5',
  },
  IconArrowTopSquare: {
    category: 'Arrows',
    tags: ['arrow', 'top', 'square'],
    version: '1.35',
    unicode: 'eda6',
  },
  IconArrowTopTail: {
    category: 'Arrows',
    tags: ['arrow', 'top', 'tail'],
    version: '1.35',
    unicode: 'eda7',
  },
  IconArrowUpCircle: {
    category: 'Arrows',
    tags: ['arrow', 'up', 'circle', 'top', 'point'],
    version: '1.0',
    unicode: 'ea20',
  },
  IconArrowUpLeftCircle: {
    category: 'Arrows',
    tags: ['arrow', 'up', 'left', 'circle', 'top', 'corner', 'point'],
    version: '1.0',
    unicode: 'ea21',
  },
  IconArrowUpLeft: {
    category: 'Arrows',
    tags: ['arrow', 'up', 'left', 'top', 'corner', 'point'],
    version: '1.0',
    unicode: 'ea22',
  },
  IconArrowUpRightCircle: {
    category: 'Arrows',
    tags: ['arrow', 'up', 'right', 'circle', 'top', 'corner', 'point'],
    version: '1.0',
    unicode: 'ea23',
  },
  IconArrowUpRight: {
    category: 'Arrows',
    tags: ['arrow', 'up', 'right', 'top', 'corner', 'point'],
    version: '1.0',
    unicode: 'ea24',
  },
  IconArrowUp: {
    category: 'Arrows',
    tags: ['arrow', 'up', 'top', 'point'],
    version: '1.0',
    unicode: 'ea25',
  },
  IconArrowWaveLeftDown: {
    category: 'Arrows',
    tags: ['arrow', 'wave', 'left', 'down'],
    version: '1.35',
    unicode: 'eda8',
  },
  IconArrowWaveLeftUp: {
    category: 'Arrows',
    tags: ['arrow', 'wave', 'left', 'up'],
    version: '1.35',
    unicode: 'eda9',
  },
  IconArrowWaveRightDown: {
    category: 'Arrows',
    tags: ['arrow', 'wave', 'right', 'down'],
    version: '1.35',
    unicode: 'edaa',
  },
  IconArrowWaveRightUp: {
    category: 'Arrows',
    tags: ['arrow', 'wave', 'right', 'up'],
    version: '1.35',
    unicode: 'edab',
  },
  IconArrowsCross: {
    category: '',
    tags: ['arrows', 'cross'],
    version: '1.53',
    unicode: 'effe',
  },
  IconArrowsDiagonal2: {
    category: 'Arrows',
    tags: ['arrows', 'diagonal', '2', 'zoom', 'corners', 'stretch'],
    version: '1.0',
    unicode: 'ea26',
  },
  IconArrowsDiagonalMinimize2: {
    category: 'Arrows',
    tags: ['arrows', 'diagonal', 'minimize', '2'],
    version: '1.42',
    unicode: 'ef38',
  },
  IconArrowsDiagonalMinimize: {
    category: 'Arrows',
    tags: ['arrows', 'diagonal', 'minimize'],
    version: '1.42',
    unicode: 'ef39',
  },
  IconArrowsDiagonal: {
    category: 'Arrows',
    tags: ['arrows', 'diagonal', 'zoom', 'corners', 'stretch'],
    version: '1.0',
    unicode: 'ea27',
  },
  IconArrowsDoubleNeSw: {
    category: 'Arrows',
    tags: ['arrows', 'double', 'ne', 'sw'],
    version: '1.37',
    unicode: 'edde',
  },
  IconArrowsDoubleNwSe: {
    category: 'Arrows',
    tags: ['arrows', 'double', 'nw', 'se'],
    version: '1.37',
    unicode: 'eddf',
  },
  IconArrowsDoubleSeNw: {
    category: 'Arrows',
    tags: ['arrows', 'double', 'se', 'nw'],
    version: '1.37',
    unicode: 'ede0',
  },
  IconArrowsDoubleSwNe: {
    category: 'Arrows',
    tags: ['arrows', 'double', 'sw', 'ne'],
    version: '1.37',
    unicode: 'ede1',
  },
  IconArrowsDownUp: {
    category: 'Arrows',
    tags: ['arrows', 'down', 'up'],
    version: '1.35',
    unicode: 'edac',
  },
  IconArrowsDown: {
    category: 'Arrows',
    tags: ['arrows', 'down'],
    version: '1.35',
    unicode: 'edad',
  },
  IconArrowsHorizontal: {
    category: 'Arrows',
    tags: ['arrows', 'horizontal', 'zoom', 'stretch', 'left', 'right'],
    version: '1.2',
    unicode: 'eb59',
  },
  IconArrowsJoin2: {
    category: 'Arrows',
    tags: ['arrows', 'join', '2'],
    version: '1.35',
    unicode: 'edae',
  },
  IconArrowsJoin: {
    category: 'Arrows',
    tags: ['arrows', 'join'],
    version: '1.35',
    unicode: 'edaf',
  },
  IconArrowsLeftDown: {
    category: 'Arrows',
    tags: ['arrows', 'left', 'down', 'drag', 'move'],
    version: '1.39',
    unicode: 'ee00',
  },
  IconArrowsLeftRight: {
    category: 'Arrows',
    tags: ['arrows', 'left', 'right'],
    version: '1.35',
    unicode: 'edb0',
  },
  IconArrowsLeft: {
    category: 'Arrows',
    tags: ['arrows', 'left'],
    version: '1.35',
    unicode: 'edb1',
  },
  IconArrowsMaximize: {
    category: 'Arrows',
    tags: ['arrows', 'maximize', 'fullscreen', 'expand'],
    version: '1.0',
    unicode: 'ea28',
  },
  IconArrowsMinimize: {
    category: 'Arrows',
    tags: ['arrows', 'minimize', 'fullscreen', 'exit', 'close'],
    version: '1.0',
    unicode: 'ea29',
  },
  IconArrowsRightDown: {
    category: 'Arrows',
    tags: ['arrows', 'right', 'down', 'drag', 'move'],
    version: '1.39',
    unicode: 'ee01',
  },
  IconArrowsRightLeft: {
    category: 'Arrows',
    tags: ['arrows', 'right', 'left'],
    version: '1.35',
    unicode: 'edb2',
  },
  IconArrowsRight: {
    category: 'Arrows',
    tags: ['arrows', 'right'],
    version: '1.35',
    unicode: 'edb3',
  },
  IconArrowsShuffle2: {
    category: '',
    tags: ['arrows', 'shuffle', '2'],
    version: '1.53',
    unicode: 'efff',
  },
  IconArrowsShuffle: {
    category: '',
    tags: ['arrows', 'shuffle'],
    version: '1.53',
    unicode: 'f000',
  },
  IconArrowsSort: {
    category: 'Arrows',
    tags: ['arrows', 'sort', 'top', 'bottom', 'parallel', 'order'],
    version: '1.2',
    unicode: 'eb5a',
  },
  IconArrowsSplit2: {
    category: 'Arrows',
    tags: ['arrows', 'split', '2'],
    version: '1.35',
    unicode: 'edb4',
  },
  IconArrowsSplit: {
    category: 'Arrows',
    tags: ['arrows', 'split'],
    version: '1.35',
    unicode: 'edb5',
  },
  IconArrowsUpDown: {
    category: 'Arrows',
    tags: ['arrows', 'up', 'down'],
    version: '1.35',
    unicode: 'edb6',
  },
  IconArrowsUpLeft: {
    category: 'Arrows',
    tags: ['arrows', 'up', 'left', 'drag', 'move'],
    version: '1.39',
    unicode: 'ee02',
  },
  IconArrowsUpRight: {
    category: 'Arrows',
    tags: ['arrows', 'up', 'right', 'drag', 'move'],
    version: '1.39',
    unicode: 'ee03',
  },
  IconArrowsUp: {
    category: 'Arrows',
    tags: ['arrows', 'up'],
    version: '1.35',
    unicode: 'edb7',
  },
  IconArrowsVertical: {
    category: 'Arrows',
    tags: ['arrows', 'vertical', 'expand', 'stretch', 'top', 'bottom'],
    version: '1.2',
    unicode: 'eb5b',
  },
  IconArtboard: {
    category: '',
    tags: ['artboard', 'graphics', 'drawing', 'design', 'art', 'canvas'],
    version: '1.1',
    unicode: 'ea2a',
  },
  IconAspectRatio: {
    category: 'Media',
    tags: [
      'aspect',
      'ratio',
      'size',
      'dimension',
      'width',
      'height',
      'orientation',
    ],
    version: '1.27',
    unicode: 'ed30',
  },
  IconAsteriskSimple: {
    category: 'Text',
    tags: ['asterisk', 'simple'],
    version: '1.51',
    unicode: 'efd4',
  },
  IconAsterisk: {
    category: 'Text',
    tags: ['asterisk'],
    version: '1.51',
    unicode: 'efd5',
  },
  IconAt: {
    category: '',
    tags: ['at', 'email', 'message', 'mention', 'sign', '@'],
    version: '1.0',
    unicode: 'ea2b',
  },
  IconAtom2: {
    category: '',
    tags: [
      'atom',
      '2',
      'unit',
      'element',
      'part',
      'electrons',
      'protons',
      'neutrons',
    ],
    version: '1.7',
    unicode: 'ebdf',
  },
  IconAtom: {
    category: '',
    tags: ['atom', 'unit', 'element', 'part', 'electrons'],
    version: '1.3',
    unicode: 'eb79',
  },
  IconAugmentedReality: {
    category: '',
    tags: ['augmented', 'reality'],
    version: '1.55',
    unicode: 'f023',
  },
  IconAward: {
    category: '',
    tags: ['award', 'prize', 'reward', 'competition', 'contest', 'win'],
    version: '1.1',
    unicode: 'ea2c',
  },
  IconAxe: {
    category: '',
    tags: ['axe'],
    version: '1.48',
    unicode: 'ef9f',
  },
  IconAxisX: {
    category: 'Arrows',
    tags: ['axis', 'x'],
    version: '1.43',
    unicode: 'ef45',
  },
  IconAxisY: {
    category: 'Arrows',
    tags: ['axis', 'y'],
    version: '1.43',
    unicode: 'ef46',
  },
  IconBackhoe: {
    category: 'Vehicles',
    tags: [
      'backhoe',
      'rear',
      'equipment',
      'digger',
      'excavation',
      'tractor',
      'loader',
      'construction',
      'site',
      'excavator',
    ],
    version: '1.34',
    unicode: 'ed86',
  },
  IconBackpack: {
    category: '',
    tags: ['backpack'],
    version: '1.43',
    unicode: 'ef47',
  },
  IconBackspace: {
    category: 'Text',
    tags: ['backspace', 'delete', 'remove', 'eliminate'],
    version: '1.0',
    unicode: 'ea2d',
  },
  IconBadge: {
    category: '',
    tags: ['badge'],
    version: '1.50',
    unicode: 'efc2',
  },
  IconBadges: {
    category: '',
    tags: ['badges'],
    version: '1.50',
    unicode: 'efc3',
  },
  IconBallAmericanFootball: {
    category: 'Sport',
    tags: [
      'ball',
      'american',
      'football',
      'sport',
      'game',
      'sportsman',
      'play',
      'match',
      'pitch',
    ],
    version: '1.39',
    unicode: 'ee04',
  },
  IconBallBaseball: {
    category: 'Sport',
    tags: ['ball', 'baseball'],
    version: '1.48',
    unicode: 'efa0',
  },
  IconBallBasketball: {
    category: 'Sport',
    tags: ['ball', 'basketball', 'game', 'round', 'quarter', 'basket', 'NBA'],
    version: '1.1',
    unicode: 'ec28',
  },
  IconBallBowling: {
    category: 'Sport',
    tags: ['ball', 'bowling', 'round', 'strike', 'spare', 'pin'],
    version: '1.10',
    unicode: 'ec29',
  },
  IconBallFootballOff: {
    category: 'Sport',
    tags: [
      'ball',
      'football',
      'off',
      'sport',
      'game',
      'sportsman',
      'play',
      'match',
      'pitch',
    ],
    version: '1.39',
    unicode: 'ee05',
  },
  IconBallFootball: {
    category: 'Sport',
    tags: [
      'ball',
      'football',
      'sport',
      'game',
      'sportsman',
      'play',
      'match',
      'pitch',
    ],
    version: '1.39',
    unicode: 'ee06',
  },
  IconBallTennis: {
    category: 'Sport',
    tags: ['ball', 'tennis', 'game', 'set', 'match', 'court', 'racket'],
    version: '1.10',
    unicode: 'ec2a',
  },
  IconBallVolleyball: {
    category: 'Sport',
    tags: [
      'ball',
      'volleyball',
      'point',
      'set',
      'match',
      'attacker',
      'ace',
      'setter',
      'serve',
    ],
    version: '1.10',
    unicode: 'ec2b',
  },
  IconBallon: {
    category: '',
    tags: ['ballon'],
    version: '1.42',
    unicode: 'ef3a',
  },
  IconBan: {
    category: '',
    tags: ['ban', 'no', 'reject', 'restriction', 'prohibited'],
    version: '1.0',
    unicode: 'ea2e',
  },
  IconBandage: {
    category: 'Health',
    tags: ['bandage', 'patch', 'wound', 'cut', 'pain'],
    version: '1.3',
    unicode: 'eb7a',
  },
  IconBarbell: {
    category: 'Sport',
    tags: ['barbell'],
    version: '1.52',
    unicode: 'eff0',
  },
  IconBarcode: {
    category: '',
    tags: ['barcode', 'product', 'shop', 'scan', 'supermarket'],
    version: '1.5',
    unicode: 'ebc6',
  },
  IconBarrierBlock: {
    category: '',
    tags: ['barrier', 'block'],
    version: '1.54',
    unicode: 'f00e',
  },
  IconBaseline: {
    category: '',
    tags: ['baseline'],
    version: '1.55',
    unicode: 'f024',
  },
  IconBasket: {
    category: 'E-commerce',
    tags: ['basket', 'shop', 'store', 'online', 'shopping'],
    version: '1.7',
    unicode: 'ebe1',
  },
  IconBath: {
    category: '',
    tags: ['bath'],
    version: '1.43',
    unicode: 'ef48',
  },
  IconBattery1: {
    category: 'Devices',
    tags: ['battery', '1', 'energy', 'power', 'electricity'],
    version: '1.0',
    unicode: 'ea2f',
  },
  IconBattery2: {
    category: 'Devices',
    tags: ['battery', '2', 'energy', 'power', 'electricity'],
    version: '1.0',
    unicode: 'ea30',
  },
  IconBattery3: {
    category: 'Devices',
    tags: ['battery', '3', 'energy', 'power', 'electricity'],
    version: '1.0',
    unicode: 'ea31',
  },
  IconBattery4: {
    category: 'Devices',
    tags: ['battery', '4', 'energy', 'power', 'electricity'],
    version: '1.0',
    unicode: 'ea32',
  },
  IconBatteryAutomotive: {
    category: 'Vehicles',
    tags: [
      'battery',
      'automotive',
      'vehicle',
      'charge',
      'motor',
      'current',
      'car',
      'electricity',
      'electric',
      'power',
    ],
    version: '1.39',
    unicode: 'ee07',
  },
  IconBatteryCharging2: {
    category: 'Devices',
    tags: ['battery', 'charging', '2'],
    version: '1.42',
    unicode: 'ef3b',
  },
  IconBatteryCharging: {
    category: 'Devices',
    tags: ['battery', 'charging', 'charge', 'energy', 'power', 'electricity'],
    version: '1.0',
    unicode: 'ea33',
  },
  IconBatteryEco: {
    category: 'Devices',
    tags: ['battery', 'eco'],
    version: '1.42',
    unicode: 'ef3c',
  },
  IconBatteryOff: {
    category: 'Devices',
    tags: ['battery', 'off', 'energy', 'power', 'electricity'],
    version: '1.25',
    unicode: 'ed1c',
  },
  IconBattery: {
    category: 'Devices',
    tags: ['battery', 'energy', 'power', 'electricity'],
    version: '1.0',
    unicode: 'ea34',
  },
  IconBeach: {
    category: 'Map',
    tags: ['beach'],
    version: '1.42',
    unicode: 'ef3d',
  },
  IconBed: {
    category: 'Map',
    tags: ['bed', 'sleep', 'night', 'bedroom', 'rest'],
    version: '1.2',
    unicode: 'eb5c',
  },
  IconBeer: {
    category: 'Food',
    tags: ['beer'],
    version: '1.48',
    unicode: 'efa1',
  },
  IconBellMinus: {
    category: 'System',
    tags: ['bell', 'minus'],
    version: '1.37',
    unicode: 'ede2',
  },
  IconBellOff: {
    category: 'System',
    tags: ['bell', 'off'],
    version: '1.22',
    unicode: 'ece9',
  },
  IconBellPlus: {
    category: 'System',
    tags: ['bell', 'plus'],
    version: '1.37',
    unicode: 'ede3',
  },
  IconBellRinging2: {
    category: 'System',
    tags: ['bell', 'ringing', '2', 'alarm', 'sound', 'notification'],
    version: '1.37',
    unicode: 'ede4',
  },
  IconBellRinging: {
    category: 'System',
    tags: ['bell', 'ringing', 'alarm', 'sound', 'notification'],
    version: '1.24',
    unicode: 'ed07',
  },
  IconBellX: {
    category: 'System',
    tags: ['bell', 'x'],
    version: '1.37',
    unicode: 'ede5',
  },
  IconBellZ: {
    category: '',
    tags: ['bell', 'z'],
    version: '1.52',
    unicode: 'eff1',
  },
  IconBell: {
    category: 'System',
    tags: ['bell', 'alarm', 'sound', 'notification'],
    version: '1.0',
    unicode: 'ea35',
  },
  IconBible: {
    category: '',
    tags: ['bible'],
    version: '1.50',
    unicode: 'efc4',
  },
  IconBike: {
    category: 'Vehicles',
    tags: ['bike', 'cycling', 'bicycle', 'sport', 'wheel'],
    version: '1.0',
    unicode: 'ea36',
  },
  IconBinary: {
    category: '',
    tags: ['binary', 'binary'],
    version: '1.39',
    unicode: 'ee08',
  },
  IconBiohazard: {
    category: 'Symbols',
    tags: [
      'biohazard',
      'danger',
      'radioactive',
      'toxic',
      'microbe',
      'virus',
      'biotoxin',
    ],
    version: '1.18',
    unicode: 'ecb8',
  },
  IconBlockquote: {
    category: 'Text',
    tags: [
      'blockquote',
      'citation',
      'quotation',
      'saying',
      'text',
      'section',
      'style',
      'styling',
      'css',
    ],
    version: '1.39',
    unicode: 'ee09',
  },
  IconBluetoothConnected: {
    category: 'Devices',
    tags: ['bluetooth', 'connected', 'wireless', 'connection', 'connect'],
    version: '1.22',
    unicode: 'ecea',
  },
  IconBluetoothOff: {
    category: 'Devices',
    tags: ['bluetooth', 'off', 'wireless', 'conection', 'connect'],
    version: '1.22',
    unicode: 'eceb',
  },
  IconBluetooth: {
    category: 'Devices',
    tags: ['bluetooth', 'wireless', 'connection', 'connect'],
    version: '1.0',
    unicode: 'ea37',
  },
  IconBlur: {
    category: 'Design',
    tags: ['blur'],
    version: '1.47',
    unicode: 'ef8c',
  },
  IconBold: {
    category: 'Text',
    tags: ['bold', 'font', 'style', 'boldface'],
    version: '1.3',
    unicode: 'eb7b',
  },
  IconBoltOff: {
    category: '',
    tags: ['bolt', 'off', 'energy', 'power', 'electricity', 'storm'],
    version: '1.22',
    unicode: 'ecec',
  },
  IconBolt: {
    category: '',
    tags: ['bolt', 'energy', 'power', 'electricity', 'storm'],
    version: '1.0',
    unicode: 'ea38',
  },
  IconBone: {
    category: 'Food',
    tags: ['bone'],
    version: '1.35',
    unicode: 'edb8',
  },
  IconBook2: {
    category: '',
    tags: ['book', '2'],
    version: '1.50',
    unicode: 'efc5',
  },
  IconBook: {
    category: 'Document',
    tags: [
      'book',
      'read',
      'dictionary',
      'magazine',
      'library',
      'booklet',
      'novel',
    ],
    version: '1.0',
    unicode: 'ea39',
  },
  IconBookmarkOff: {
    category: 'Document',
    tags: ['bookmark', 'off', 'read', 'clip', 'marker', 'tag'],
    version: '1.22',
    unicode: 'eced',
  },
  IconBookmark: {
    category: 'Document',
    tags: ['bookmark', 'read', 'clip', 'marker', 'tag'],
    version: '1.0',
    unicode: 'ea3a',
  },
  IconBookmarks: {
    category: 'Document',
    tags: ['bookmarks', 'read', 'clip', 'marker', 'tag'],
    version: '1.24',
    unicode: 'ed08',
  },
  IconBooks: {
    category: '',
    tags: ['books'],
    version: '1.52',
    unicode: 'eff2',
  },
  IconBorderAll: {
    category: 'Design',
    tags: ['border', 'all', 'table', 'side', 'line'],
    version: '1.0',
    unicode: 'ea3b',
  },
  IconBorderBottom: {
    category: 'Design',
    tags: ['border', 'bottom', 'table', 'side', 'line'],
    version: '1.0',
    unicode: 'ea3c',
  },
  IconBorderHorizontal: {
    category: 'Design',
    tags: ['border', 'horizontal', 'table', 'side', 'line'],
    version: '1.0',
    unicode: 'ea3d',
  },
  IconBorderInner: {
    category: 'Design',
    tags: ['border', 'inner', 'table', 'side', 'line'],
    version: '1.0',
    unicode: 'ea3e',
  },
  IconBorderLeft: {
    category: 'Design',
    tags: ['border', 'left', 'table', 'side', 'line'],
    version: '1.0',
    unicode: 'ea3f',
  },
  IconBorderNone: {
    category: 'Design',
    tags: ['border', 'none', 'table'],
    version: '1.0',
    unicode: 'ea40',
  },
  IconBorderOuter: {
    category: 'Design',
    tags: ['border', 'outer', 'table', 'side', 'line'],
    version: '1.0',
    unicode: 'ea41',
  },
  IconBorderRadius: {
    category: 'Design',
    tags: ['border', 'radius', 'corner', 'rounded', 'line'],
    version: '1.3',
    unicode: 'eb7c',
  },
  IconBorderRight: {
    category: 'Design',
    tags: ['border', 'right', 'table', 'side', 'line'],
    version: '1.0',
    unicode: 'ea42',
  },
  IconBorderStyle2: {
    category: 'Design',
    tags: ['border', 'style', '2'],
    version: '1.41',
    unicode: 'ef22',
  },
  IconBorderStyle: {
    category: 'Design',
    tags: ['border', 'style', 'google', 'excel', 'sheets', 'sheets'],
    version: '1.39',
    unicode: 'ee0a',
  },
  IconBorderTop: {
    category: 'Design',
    tags: ['border', 'top', 'table', 'side', 'line'],
    version: '1.0',
    unicode: 'ea43',
  },
  IconBorderVertical: {
    category: 'Design',
    tags: ['border', 'vertical', 'table', 'side', 'line'],
    version: '1.0',
    unicode: 'ea44',
  },
  IconBottle: {
    category: 'Food',
    tags: ['bottle'],
    version: '1.40',
    unicode: 'ef0b',
  },
  IconBoxMargin: {
    category: 'Design',
    tags: [
      'box',
      'margin',
      'css',
      'cascading',
      'style',
      'section',
      'space',
      'text',
      'content',
      'outside',
      'container',
    ],
    version: '1.39',
    unicode: 'ee0b',
  },
  IconBoxModel2: {
    category: 'Design',
    tags: ['box', 'model', '2'],
    version: '1.41',
    unicode: 'ef23',
  },
  IconBoxModel: {
    category: 'Design',
    tags: [
      'box',
      'model',
      'css',
      'cascading',
      'style',
      'section',
      'element',
      'square',
      'website',
      'container',
    ],
    version: '1.39',
    unicode: 'ee0c',
  },
  IconBoxMultiple0: {
    category: 'Numbers',
    tags: [
      'box',
      'multiple',
      '0',
      'css',
      'cascading',
      'style',
      'sheet',
      'background',
      'section',
      'zero',
      'website',
      'layer',
    ],
    version: '1.39',
    unicode: 'ee0d',
  },
  IconBoxMultiple1: {
    category: 'Numbers',
    tags: [
      'box',
      'multiple',
      '1',
      'css',
      'cascading',
      'style',
      'sheet',
      'background',
      'section',
      'one',
      'website',
      'layer',
    ],
    version: '1.39',
    unicode: 'ee0e',
  },
  IconBoxMultiple2: {
    category: 'Numbers',
    tags: [
      'box',
      'multiple',
      '2',
      'css',
      'cascading',
      'style',
      'sheet',
      'background',
      'section',
      'two',
      'website',
      'layer',
    ],
    version: '1.39',
    unicode: 'ee0f',
  },
  IconBoxMultiple3: {
    category: 'Numbers',
    tags: [
      'box',
      'multiple',
      '3',
      'css',
      'cascading',
      'style',
      'sheet',
      'background',
      'section',
      'three',
      'website',
      'layer',
    ],
    version: '1.39',
    unicode: 'ee10',
  },
  IconBoxMultiple4: {
    category: 'Numbers',
    tags: [
      'box',
      'multiple',
      '4',
      'css',
      'cascading',
      'style',
      'sheet',
      'background',
      'section',
      'four',
      'website',
      'layer',
    ],
    version: '1.39',
    unicode: 'ee11',
  },
  IconBoxMultiple5: {
    category: 'Numbers',
    tags: [
      'box',
      'multiple',
      '5',
      'css',
      'cascading',
      'style',
      'sheet',
      'background',
      'section',
      'five',
      'website',
      'layer',
    ],
    version: '1.39',
    unicode: 'ee12',
  },
  IconBoxMultiple6: {
    category: 'Numbers',
    tags: [
      'box',
      'multiple',
      '6',
      'css',
      'cascading',
      'style',
      'sheet',
      'background',
      'section',
      'six',
      'website',
      'layer',
    ],
    version: '1.39',
    unicode: 'ee13',
  },
  IconBoxMultiple7: {
    category: 'Numbers',
    tags: [
      'box',
      'multiple',
      '7',
      'css',
      'cascading',
      'style',
      'sheet',
      'background',
      'section',
      'seven',
      'website',
      'layer',
    ],
    version: '1.39',
    unicode: 'ee14',
  },
  IconBoxMultiple8: {
    category: 'Numbers',
    tags: [
      'box',
      'multiple',
      '8',
      'css',
      'cascading',
      'style',
      'sheet',
      'background',
      'section',
      'eight',
      'website',
      'layer',
    ],
    version: '1.39',
    unicode: 'ee15',
  },
  IconBoxMultiple9: {
    category: 'Numbers',
    tags: [
      'box',
      'multiple',
      '9',
      'css',
      'cascading',
      'style',
      'sheet',
      'background',
      'section',
      'nine',
      'website',
      'layer',
    ],
    version: '1.39',
    unicode: 'ee16',
  },
  IconBoxMultiple: {
    category: '',
    tags: [
      'box',
      'multiple',
      'css',
      'cascading',
      'style',
      'sheet',
      'background',
      'section',
      'website',
      'layer',
    ],
    version: '1.39',
    unicode: 'ee17',
  },
  IconBoxPadding: {
    category: 'Design',
    tags: [
      'box',
      'padding',
      'css',
      'cascading',
      'style',
      'section',
      'space',
      'text',
      'content',
      'website',
      'container',
      'inside',
    ],
    version: '1.39',
    unicode: 'ee18',
  },
  IconBox: {
    category: '',
    tags: ['box', 'cube', 'app', 'application', 'package', 'container'],
    version: '1.0',
    unicode: 'ea45',
  },
  IconBraces: {
    category: 'Math',
    tags: ['braces', 'punctuation', 'additional', 'information'],
    version: '1.6',
    unicode: 'ebcc',
  },
  IconBrackets: {
    category: 'Math',
    tags: ['brackets', 'punctuation', 'additional', 'information'],
    version: '1.6',
    unicode: 'ebcd',
  },
  IconBrandAirbnb: {
    category: 'Brand',
    tags: [
      'brand',
      'airbnb',
      'flat',
      'apartment',
      'holiday',
      'vacation',
      'city',
      'break',
      'book',
    ],
    version: '1.32',
    unicode: 'ed68',
  },
  IconBrandAirtable: {
    category: 'Brand',
    tags: ['brand', 'airtable'],
    version: '1.45',
    unicode: 'ef6a',
  },
  IconBrandAndroid: {
    category: 'Brand',
    tags: [
      'brand',
      'android',
      'OS',
      'company',
      'system',
      'interface',
      'software',
      'logo',
    ],
    version: '1.9',
    unicode: 'ec16',
  },
  IconBrandAngular: {
    category: 'Brand',
    tags: ['brand', 'angular'],
    version: '1.45',
    unicode: 'ef6b',
  },
  IconBrandAppleArcade: {
    category: 'Brand',
    tags: [
      'brand',
      'apple',
      'arcade',
      'technology',
      'video',
      'game',
      'service',
      'macOS',
      'device',
      'play',
      'online',
    ],
    version: '1.32',
    unicode: 'ed69',
  },
  IconBrandApple: {
    category: 'Brand',
    tags: [
      'brand',
      'apple',
      'OS',
      'company',
      'system',
      'interface',
      'software',
      'devices',
      'logo',
    ],
    version: '1.9',
    unicode: 'ec17',
  },
  IconBrandAppstore: {
    category: 'Brand',
    tags: ['brand', 'appstore', 'shop', 'online', 'application', 'logo'],
    version: '1.26',
    unicode: 'ed24',
  },
  IconBrandAsana: {
    category: 'Brand',
    tags: [
      'brand',
      'asana',
      'task',
      'management',
      'project management',
      'manage',
      'collaborate',
      'collaboration',
      'team',
      'teamwork',
      'technology',
    ],
    version: '1.36',
    unicode: 'edc5',
  },
  IconBrandBehance: {
    category: 'Brand',
    tags: [
      'brand',
      'behance',
      'logo',
      'website',
      'Adobe',
      'project',
      'views',
      'marks',
      'platform',
      'designer',
    ],
    version: '1.13',
    unicode: 'ec6e',
  },
  IconBrandBing: {
    category: 'Brand',
    tags: [
      'brand',
      'bing',
      'search',
      'result',
      'find',
      'search',
      'engine',
      'internet',
      'microsoft',
      'web',
      'technology',
    ],
    version: '1.36',
    unicode: 'edc6',
  },
  IconBrandBitbucket: {
    category: 'Brand',
    tags: [
      'brand',
      'bitbucket',
      'version',
      'control',
      'repository',
      'hosting',
      'atlassian',
      'source',
      'code',
      'development',
      'git',
      'technology',
    ],
    version: '1.36',
    unicode: 'edc7',
  },
  IconBrandBooking: {
    category: 'Brand',
    tags: [
      'brand',
      'booking',
      'flat',
      'apartment',
      'holiday',
      'vacation',
      'city',
      'break',
      'book',
      'rent',
    ],
    version: '1.36',
    unicode: 'edc8',
  },
  IconBrandBootstrap: {
    category: 'Brand',
    tags: ['brand', 'bootstrap'],
    version: '1.42',
    unicode: 'ef3e',
  },
  IconBrandChrome: {
    category: 'Brand',
    tags: ['brand', 'chrome', 'browser', 'internet', 'web', 'logo'],
    version: '1.9',
    unicode: 'ec18',
  },
  IconBrandCodepen: {
    category: 'Brand',
    tags: [
      'brand',
      'codepen',
      'logo',
      'community',
      'internet',
      'codes',
      'programing',
      'programmer',
      'source',
      'website',
      'platform',
      'designer',
    ],
    version: '1.13',
    unicode: 'ec6f',
  },
  IconBrandCodesandbox: {
    category: 'Brand',
    tags: [
      'brand',
      'codesandbox',
      'online',
      'code',
      'editor',
      'prototyping',
      'prototype',
      'web',
      'app',
      'programming',
      'integrated',
      'development',
      'environment',
      'technology',
    ],
    version: '1.32',
    unicode: 'ed6a',
  },
  IconBrandCss3: {
    category: 'Brand',
    tags: [
      'brand',
      'css3',
      'cascading',
      'style',
      'sheet',
      'programming',
      'development',
      'web',
      'website',
      'technology',
    ],
    version: '1.32',
    unicode: 'ed6b',
  },
  IconBrandCucumber: {
    category: 'Brand',
    tags: ['brand', 'cucumber'],
    version: '1.45',
    unicode: 'ef6c',
  },
  IconBrandDebian: {
    category: 'Brand',
    tags: ['brand', 'debian'],
    version: '1.44',
    unicode: 'ef57',
  },
  IconBrandDeviantart: {
    category: 'Brand',
    tags: [
      'brand',
      'deviantart',
      'logo',
      'community',
      'internet',
      'works',
      'designer',
      'project',
      'presenting',
      'artist',
      'discussion',
      'website',
      'platform',
    ],
    version: '1.23',
    unicode: 'ecfb',
  },
  IconBrandDiscord: {
    category: 'Brand',
    tags: [
      'brand',
      'discord',
      'app',
      'application',
      'logo',
      'communication',
      'talks',
      'gamers',
      'freeware',
      'platform',
    ],
    version: '1.21',
    unicode: 'ece3',
  },
  IconBrandDisqus: {
    category: 'Brand',
    tags: [
      'brand',
      'disqus',
      'comment',
      'blog',
      'service',
      'website',
      'online',
      'platform',
      'social',
      'networking',
      'technology',
    ],
    version: '1.36',
    unicode: 'edc9',
  },
  IconBrandDocker: {
    category: 'Brand',
    tags: [
      'brand',
      'docker',
      'app',
      'development',
      'hub',
      'platform',
      'software',
      'developer',
      'programming',
      'programmer',
      'virtualization',
      'technology',
    ],
    version: '1.36',
    unicode: 'edca',
  },
  IconBrandDoctrine: {
    category: 'Brand',
    tags: ['brand', 'doctrine'],
    version: '1.45',
    unicode: 'ef6d',
  },
  IconBrandDribbble: {
    category: 'Brand',
    tags: [
      'brand',
      'dribbble',
      'logo',
      'website',
      'community',
      'project',
      'platform',
      'self-promotion',
      'designer',
      'portfolio',
    ],
    version: '1.18',
    unicode: 'ec19',
  },
  IconBrandEdge: {
    category: 'Brand',
    tags: ['brand', 'edge', 'browser', 'internet', 'web', 'logo', 'explorer'],
    version: '1.23',
    unicode: 'ecfc',
  },
  IconBrandFacebook: {
    category: 'Brand',
    tags: [
      'brand',
      'facebook',
      'logo',
      'app',
      'application',
      'community',
      'social',
      'communication',
      'website',
      'user',
      'post',
      'images',
      'photos',
      'comment',
      'feedback',
    ],
    version: '1.18',
    unicode: 'ec1a',
  },
  IconBrandFigma: {
    category: 'Brand',
    tags: [
      'brand',
      'figma',
      'logo',
      'editor',
      'graphic',
      'image',
      'implement',
      'prototyping',
    ],
    version: '1.16',
    unicode: 'ec93',
  },
  IconBrandFirebase: {
    category: 'Brand',
    tags: ['brand', 'firebase'],
    version: '1.45',
    unicode: 'ef6e',
  },
  IconBrandFirefox: {
    category: 'Brand',
    tags: ['brand', 'firefox', 'browser', 'internet', 'web', 'logo'],
    version: '1.23',
    unicode: 'ecfd',
  },
  IconBrandFlickr: {
    category: 'Brand',
    tags: [
      'brand',
      'flickr',
      'logo',
      'website',
      'house',
      'facilitate',
      'sharing',
      'digital',
      'photos',
      'images',
    ],
    version: '1.23',
    unicode: 'ecfe',
  },
  IconBrandFoursquare: {
    category: 'Brand',
    tags: [
      'brand',
      'foursquare',
      'logo',
      'website',
      'community',
      'social',
      'network',
    ],
    version: '1.23',
    unicode: 'ecff',
  },
  IconBrandFramer: {
    category: 'Brand',
    tags: [
      'brand',
      'framer',
      'logo',
      'application',
      'app',
      'prototyping',
      'prototype',
      'animations',
    ],
    version: '1.9',
    unicode: 'ec1b',
  },
  IconBrandGit: {
    category: 'Brand',
    tags: ['brand', 'git'],
    version: '1.45',
    unicode: 'ef6f',
  },
  IconBrandGithub: {
    category: 'Brand',
    tags: [
      'brand',
      'github',
      'logo',
      'website',
      'hosting',
      'project',
      'programming',
      'software',
      'development',
    ],
    version: '1.18',
    unicode: 'ec1c',
  },
  IconBrandGitlab: {
    category: 'Brand',
    tags: [
      'brand',
      'gitlab',
      'logo',
      'website',
      'software',
      'code',
      'programming',
      'programmers',
    ],
    version: '1.9',
    unicode: 'ec1d',
  },
  IconBrandGmail: {
    category: 'Brand',
    tags: ['brand', 'gmail'],
    version: '1.48',
    unicode: 'efa2',
  },
  IconBrandGoogleAnalytics: {
    category: 'Brand',
    tags: [
      'brand',
      'google',
      'analytics',
      'advertising',
      'track',
      'website',
      'traffic',
      'e-commerce',
      'online',
      'technology',
    ],
    version: '1.36',
    unicode: 'edcb',
  },
  IconBrandGoogleDrive: {
    category: 'Brand',
    tags: [
      'brand',
      'google',
      'drive',
      'logo',
      'cloud',
      'disc',
      'documents',
      'sheet',
      'presentation',
      'file',
      'edit',
    ],
    version: '1.9',
    unicode: 'ec1e',
  },
  IconBrandGooglePlay: {
    category: 'Brand',
    tags: [
      'brand',
      'google',
      'play',
      'logo',
      'application',
      'app',
      'shop',
      'store',
      'online',
    ],
    version: '1.26',
    unicode: 'ed25',
  },
  IconBrandGoogle: {
    category: 'Brand',
    tags: [
      'brand',
      'google',
      'logo',
      'enterprise',
      'browser',
      'internet',
      'web',
      'discover',
    ],
    version: '1.18',
    unicode: 'ec1f',
  },
  IconBrandGravatar: {
    category: 'Brand',
    tags: [
      'brand',
      'gravatar',
      'avatar',
      'image',
      'face',
      'blog',
      'comment',
      'represent',
      'online',
      'technology',
    ],
    version: '1.36',
    unicode: 'edcc',
  },
  IconBrandHipchat: {
    category: 'Brand',
    tags: [
      'brand',
      'hipchat',
      'chat',
      'communicate',
      'communication',
      'talk',
      'discuss',
      'app',
      'collaborate',
      'collaboration',
      'technology',
    ],
    version: '1.36',
    unicode: 'edcd',
  },
  IconBrandHtml5: {
    category: 'Brand',
    tags: [
      'brand',
      'html5',
      'programming',
      'development',
      'web',
      'website',
      'technology',
      'markup',
      'language',
      'technology',
    ],
    version: '1.32',
    unicode: 'ed6c',
  },
  IconBrandInstagram: {
    category: 'Brand',
    tags: [
      'brand',
      'instagram',
      'logo',
      'app',
      'application',
      'images',
      'photos',
      'videos',
      'post',
      'stories',
      'online',
      'community',
    ],
    version: '1.9',
    unicode: 'ec20',
  },
  IconBrandJavascript: {
    category: 'Brand',
    tags: ['brand', 'javascript', 'js', 'language', 'programming'],
    version: '1.40',
    unicode: 'ef0c',
  },
  IconBrandKickstarter: {
    category: 'Brand',
    tags: [
      'brand',
      'kickstarter',
      'crowdfunding',
      'platform',
      'project',
      'creative',
      'idea',
      'business',
      'launch',
      'technology',
    ],
    version: '1.36',
    unicode: 'edce',
  },
  IconBrandKotlin: {
    category: 'Brand',
    tags: [
      'brand',
      'kotlin',
      'programming',
      'language',
      'programmer',
      'development',
      'developer',
      'technology',
    ],
    version: '1.32',
    unicode: 'ed6d',
  },
  IconBrandLastfm: {
    category: '',
    tags: ['brand', 'lastfm'],
    version: '1.53',
    unicode: 'f001',
  },
  IconBrandLinkedin: {
    category: 'Brand',
    tags: [
      'brand',
      'linkedin',
      'logo',
      'website',
      'corporation',
      'work',
      'business',
      'internet',
    ],
    version: '1.15',
    unicode: 'ec8c',
  },
  IconBrandLoom: {
    category: 'Brand',
    tags: ['brand', 'loom'],
    version: '1.45',
    unicode: 'ef70',
  },
  IconBrandMastercard: {
    category: 'Brand',
    tags: ['brand', 'mastercard'],
    version: '1.43',
    unicode: 'ef49',
  },
  IconBrandMedium: {
    category: 'Brand',
    tags: ['brand', 'medium', 'logo', 'website', 'brand', 'wordmark', 'design'],
    version: '1.13',
    unicode: 'ec70',
  },
  IconBrandMessenger: {
    category: 'Brand',
    tags: [
      'brand',
      'messenger',
      'logo',
      'app',
      'application',
      'communication',
      'text',
      'messages',
      'communicator',
      'photos',
      'images',
      'videos',
      'giphy',
    ],
    version: '1.13',
    unicode: 'ec71',
  },
  IconBrandMeta: {
    category: 'Brand',
    tags: ['brand', 'meta'],
    version: '1.49',
    unicode: 'efb0',
  },
  IconBrandNetbeans: {
    category: 'Brand',
    tags: ['brand', 'netbeans'],
    version: '1.45',
    unicode: 'ef71',
  },
  IconBrandNetflix: {
    category: 'Brand',
    tags: [
      'brand',
      'netflix',
      'series',
      'tv',
      'episode',
      'movie',
      'film',
      'media',
      'watch',
      'app',
      'technology',
    ],
    version: '1.36',
    unicode: 'edcf',
  },
  IconBrandNotion: {
    category: 'Brand',
    tags: ['brand', 'notion'],
    version: '1.46',
    unicode: 'ef7b',
  },
  IconBrandNytimes: {
    category: 'Brand',
    tags: ['brand', 'nytimes'],
    version: '1.47',
    unicode: 'ef8d',
  },
  IconBrandOpenSource: {
    category: 'Brand',
    tags: [
      'brand',
      'open',
      'source',
      'software',
      'code',
      'developer',
      'public',
      'licence',
      'technology',
    ],
    version: '1.36',
    unicode: 'edd0',
  },
  IconBrandOpera: {
    category: 'Brand',
    tags: ['brand', 'opera', 'logo', 'browser', 'internet', 'free', 'program'],
    version: '1.9',
    unicode: 'ec21',
  },
  IconBrandPagekit: {
    category: 'Brand',
    tags: [
      'brand',
      'pagekit',
      'content',
      'management',
      'system',
      'website',
      'component',
      'modular',
      'technology',
    ],
    version: '1.36',
    unicode: 'edd1',
  },
  IconBrandPatreon: {
    category: 'Brand',
    tags: [
      'brand',
      'patreon',
      'artist',
      'software',
      'creator',
      'patron',
      'art',
      'subscription',
      'income',
      'earn',
    ],
    version: '1.36',
    unicode: 'edd2',
  },
  IconBrandPaypal: {
    category: 'Brand',
    tags: [
      'brand',
      'paypal',
      'logo',
      'enterprise',
      'service',
      'payment',
      'internet',
      'businessman',
      'consumer',
    ],
    version: '1.9',
    unicode: 'ec22',
  },
  IconBrandPhp: {
    category: 'Brand',
    tags: ['brand', 'php'],
    version: '1.45',
    unicode: 'ef72',
  },
  IconBrandPinterest: {
    category: 'Brand',
    tags: ['brand', 'pinterest', 'logo', 'website', 'images', 'materials'],
    version: '1.15',
    unicode: 'ec8d',
  },
  IconBrandPocket: {
    category: 'Brand',
    tags: [
      'brand',
      'pocket',
      'logo',
      'software',
      'application',
      'app',
      'mobile',
      'device',
      'gathering',
      'storage',
    ],
    version: '1.23',
    unicode: 'ed00',
  },
  IconBrandProducthunt: {
    category: 'Brand',
    tags: [
      'brand',
      'producthunt',
      'technology',
      'product',
      'share',
      'discover',
      'new',
      'novelty',
      'web',
      'geek',
    ],
    version: '1.36',
    unicode: 'edd3',
  },
  IconBrandPython: {
    category: 'Brand',
    tags: ['brand', 'python', 'logo', 'language', 'programming', 'source'],
    version: '1.23',
    unicode: 'ed01',
  },
  IconBrandReactNative: {
    category: 'Brand',
    tags: ['brand', 'react', 'native'],
    version: '1.45',
    unicode: 'ef73',
  },
  IconBrandReddit: {
    category: 'Brand',
    tags: [
      'brand',
      'reddit',
      'logo',
      'website',
      'information',
      'link',
      'internet',
    ],
    version: '1.15',
    unicode: 'ec8e',
  },
  IconBrandSafari: {
    category: 'Brand',
    tags: [
      'brand',
      'safari',
      'logo',
      'browser',
      'internet',
      'iPhone',
      'iPad',
      'MacBook',
      'compass',
      'apple',
      'discover',
    ],
    version: '1.9',
    unicode: 'ec23',
  },
  IconBrandSass: {
    category: 'Brand',
    tags: [
      'brand',
      'sass',
      'technology',
      'preprocessor',
      'script',
      'language',
      'programming',
      'css',
      'syntax',
      'compile',
    ],
    version: '1.36',
    unicode: 'edd4',
  },
  IconBrandSentry: {
    category: 'Brand',
    tags: [
      'brand',
      'sentry',
      'technology',
      'application',
      'monitoring',
      'error',
      'tracking',
      'software',
      'cloud',
      'development',
      'app',
    ],
    version: '1.36',
    unicode: 'edd5',
  },
  IconBrandShazam: {
    category: 'Brand',
    tags: [
      'brand',
      'shazam',
      'app',
      'technology',
      'device',
      'music',
      'sound',
      'play',
      'discover',
      'artist',
      'recognize',
    ],
    version: '1.36',
    unicode: 'edd6',
  },
  IconBrandSketch: {
    category: 'Brand',
    tags: [
      'brand',
      'sketch',
      'logo',
      'editor',
      'edit',
      'graphic',
      'apple',
      'commercial',
    ],
    version: '1.9',
    unicode: 'ec24',
  },
  IconBrandSkype: {
    category: 'Brand',
    tags: [
      'brand',
      'skype',
      'logo',
      'application',
      'app',
      'communication',
      'talks',
      'call',
      'video',
      'internet',
      'camera',
    ],
    version: '1.23',
    unicode: 'ed02',
  },
  IconBrandSlack: {
    category: 'Brand',
    tags: [
      'brand',
      'slack',
      'logo',
      'free',
      'internet',
      'service',
      'stuff',
      'Electron',
      'app',
      'application',
      'communicator',
      'textual',
      'audio',
      'multimedia',
    ],
    version: '1.13',
    unicode: 'ec72',
  },
  IconBrandSnapchat: {
    category: 'Brand',
    tags: [
      'brand',
      'snapchat',
      'logo',
      'app',
      'application',
      'photos',
      'sending',
      'images',
      'mobile',
      'video',
    ],
    version: '1.9',
    unicode: 'ec25',
  },
  IconBrandSoundcloud: {
    category: 'Brand',
    tags: [
      'brand',
      'soundcloud',
      'technology',
      'audio',
      'distribution',
      'platform',
      'music',
      'upload',
      'promote',
      'streaming',
    ],
    version: '1.32',
    unicode: 'ed6e',
  },
  IconBrandSpotify: {
    category: 'Brand',
    tags: [
      'brand',
      'spotify',
      'logo',
      'app',
      'application',
      'platform',
      'music',
      'listening',
      'streaming',
      'podcast',
    ],
    version: '1.23',
    unicode: 'ed03',
  },
  IconBrandStackoverflow: {
    category: 'Brand',
    tags: ['brand', 'stackoverflow'],
    version: '1.43',
    unicode: 'ef58',
  },
  IconBrandSteam: {
    category: 'Brand',
    tags: [
      'brand',
      'steam',
      'technology',
      'video',
      'game',
      'digital',
      'distribution',
      'software',
      'player',
      'pc',
    ],
    version: '1.32',
    unicode: 'ed6f',
  },
  IconBrandStripe: {
    category: 'Brand',
    tags: [
      'brand',
      'stripe',
      'technology',
      'payment',
      'processing',
      'money',
      'subscription',
      'finance',
      'financial',
      'software',
      'e-commerce',
    ],
    version: '1.36',
    unicode: 'edd7',
  },
  IconBrandSublimeText: {
    category: 'Brand',
    tags: ['brand', 'sublime', 'text'],
    version: '1.45',
    unicode: 'ef74',
  },
  IconBrandTabler: {
    category: 'Brand',
    tags: [
      'brand',
      'tabler',
      'logo',
      'website',
      'dashboard',
      'download',
      'open-source',
      'UI',
    ],
    version: '1.15',
    unicode: 'ec8f',
  },
  IconBrandTailwind: {
    category: 'Brand',
    tags: ['brand', 'tailwind', 'logo', 'firm', 'website'],
    version: '1.17',
    unicode: 'eca1',
  },
  IconBrandTelegram: {
    category: 'Brand',
    tags: [
      'brand',
      'telegram',
      'logo',
      'app',
      'application',
      'communicator',
      'internet',
      'cloud',
      'messages',
      'text',
      'images',
      'photos',
      'videos',
      'record',
      'file',
      'send',
    ],
    version: '1.9',
    unicode: 'ec26',
  },
  IconBrandTidal: {
    category: 'Brand',
    tags: [
      'brand',
      'tidal',
      'technology',
      'subscription',
      'music',
      'podcast',
      'video',
      'streaming',
      'playlist',
      'party',
      'track',
      'song',
    ],
    version: '1.32',
    unicode: 'ed70',
  },
  IconBrandTiktok: {
    category: 'Brand',
    tags: [
      'brand',
      'tiktok',
      'logo',
      'app',
      'application',
      'mobile',
      'video',
      'music',
    ],
    version: '1.13',
    unicode: 'ec73',
  },
  IconBrandTinder: {
    category: 'Brand',
    tags: [
      'brand',
      'tinder',
      'date',
      'dating',
      'app',
      'love',
      'affection',
      'affair',
      'couple',
      'technology',
      'networking',
      'swipe',
      'match',
    ],
    version: '1.32',
    unicode: 'ed71',
  },
  IconBrandTripadvisor: {
    category: '',
    tags: ['brand', 'tripadvisor'],
    version: '1.53',
    unicode: 'f002',
  },
  IconBrandTumblr: {
    category: 'Brand',
    tags: [
      'brand',
      'tumblr',
      'logo',
      'website',
      'platform',
      'blog',
      'community',
    ],
    version: '1.23',
    unicode: 'ed04',
  },
  IconBrandTwitch: {
    category: 'Brand',
    tags: [
      'brand',
      'twitch',
      'logo',
      'platform',
      'streaming',
      'streamers',
      'videos',
      'films',
      'chat',
      'subs',
    ],
    version: '1.23',
    unicode: 'ed05',
  },
  IconBrandTwitter: {
    category: 'Brand',
    tags: [
      'brand',
      'twitter',
      'logo',
      'app',
      'application',
      'community',
      'social',
      'communication',
      'website',
      'user',
      'post',
      'images',
      'photos',
      'comment',
      'feedback',
    ],
    version: '1.18',
    unicode: 'ec27',
  },
  IconBrandUber: {
    category: 'Brand',
    tags: ['brand', 'uber'],
    version: '1.45',
    unicode: 'ef75',
  },
  IconBrandUbuntu: {
    category: 'Brand',
    tags: ['brand', 'ubuntu'],
    version: '1.44',
    unicode: 'ef59',
  },
  IconBrandUnsplash: {
    category: 'Brand',
    tags: [
      'brand',
      'unsplash',
      'picture',
      'photo',
      'photography',
      'search',
      'image',
      'stock',
    ],
    version: '1.36',
    unicode: 'edd8',
  },
  IconBrandVercel: {
    category: 'Brand',
    tags: ['brand', 'vercel'],
    version: '1.41',
    unicode: 'ef24',
  },
  IconBrandVimeo: {
    category: 'Brand',
    tags: [
      'brand',
      'vimeo',
      'logo',
      'website',
      'sharing',
      'watching',
      'video',
      'users',
    ],
    version: '1.23',
    unicode: 'ed06',
  },
  IconBrandVisualStudio: {
    category: 'Brand',
    tags: ['brand', 'visual', 'studio'],
    version: '1.45',
    unicode: 'ef76',
  },
  IconBrandVk: {
    category: 'Brand',
    tags: [
      'brand',
      'vk',
      'technology',
      'social',
      'media',
      'networking',
      'service',
      'russian',
    ],
    version: '1.32',
    unicode: 'ed72',
  },
  IconBrandWhatsapp: {
    category: 'Brand',
    tags: [
      'brand',
      'whatsapp',
      'logo',
      'app',
      'application',
      'communication',
      'text',
      'messages',
      'communicator',
      'photos',
      'images',
      'videos',
      'giphy',
    ],
    version: '1.13',
    unicode: 'ec74',
  },
  IconBrandWindows: {
    category: 'Brand',
    tags: ['brand', 'windows', 'logo', 'system', 'OS', 'computer', 'Microsoft'],
    version: '1.20',
    unicode: 'ecd8',
  },
  IconBrandYahoo: {
    category: 'Brand',
    tags: [
      'brand',
      'yahoo',
      'web',
      'services',
      'technology',
      'inbox',
      'mail',
      'web',
      'news',
      'search',
    ],
    version: '1.32',
    unicode: 'ed73',
  },
  IconBrandYcombinator: {
    category: 'Brand',
    tags: [
      'brand',
      'ycombinator',
      'startup',
      'accelerator',
      'seed',
      'money',
      'launch',
      'company',
      'business',
      'invest',
      'funding',
    ],
    version: '1.36',
    unicode: 'edd9',
  },
  IconBrandYoutube: {
    category: 'Brand',
    tags: [
      'brand',
      'youtube',
      'logo',
      'platform',
      'channel',
      'film',
      'video',
      'youtuber',
      'maker',
      'comments',
      'stream',
      'streamer',
    ],
    version: '1.15',
    unicode: 'ec90',
  },
  IconBread: {
    category: 'Food',
    tags: ['bread'],
    version: '1.48',
    unicode: 'efa3',
  },
  IconBriefcase: {
    category: '',
    tags: ['briefcase', 'bag', 'baggage', 'folder', 'carrier', 'documents'],
    version: '1.0',
    unicode: 'ea46',
  },
  IconBrightness2: {
    category: 'Photography',
    tags: ['brightness', '2', 'light', 'screen', 'level', 'daytime', 'sun'],
    version: '1.39',
    unicode: 'ee19',
  },
  IconBrightnessDown: {
    category: 'Photography',
    tags: ['brightness', 'down', 'dark', 'darker', 'screen'],
    version: '1.3',
    unicode: 'eb7d',
  },
  IconBrightnessHalf: {
    category: 'Photography',
    tags: ['brightness', 'half', 'light', 'screen', 'level', 'daytime', 'sun'],
    version: '1.39',
    unicode: 'ee1a',
  },
  IconBrightnessUp: {
    category: 'Photography',
    tags: ['brightness', 'up', 'light', 'screen'],
    version: '1.3',
    unicode: 'eb7e',
  },
  IconBrightness: {
    category: 'Photography',
    tags: ['brightness', 'light', 'dark', 'screen'],
    version: '1.3',
    unicode: 'eb7f',
  },
  IconBrowserCheck: {
    category: 'Devices',
    tags: ['browser', 'check'],
    version: '1.51',
    unicode: 'efd6',
  },
  IconBrowserPlus: {
    category: 'Devices',
    tags: ['browser', 'plus'],
    version: '1.51',
    unicode: 'efd7',
  },
  IconBrowserX: {
    category: 'Devices',
    tags: ['browser', 'x'],
    version: '1.51',
    unicode: 'efd8',
  },
  IconBrowser: {
    category: 'Devices',
    tags: ['browser', 'internet', 'web', 'display'],
    version: '1.5',
    unicode: 'ebb7',
  },
  IconBrush: {
    category: 'Design',
    tags: ['brush', 'paint', 'art', 'picture', 'paintbrush', 'painter'],
    version: '1.5',
    unicode: 'ebb8',
  },
  IconBucket: {
    category: '',
    tags: ['bucket', 'collection', 'container', 'water', 'liquid'],
    version: '1.0',
    unicode: 'ea47',
  },
  IconBug: {
    category: 'Nature',
    tags: ['bug', 'germ', 'insect', 'error', 'nature'],
    version: '1.1',
    unicode: 'ea48',
  },
  IconBuildingArch: {
    category: 'Buildings',
    tags: [
      'building',
      'arch',
      'arc',
      'curve',
      'dome',
      'monument',
      'history',
      'architecture',
    ],
    version: '1.1',
    unicode: 'ea49',
  },
  IconBuildingBank: {
    category: 'Buildings',
    tags: [
      'building',
      'bank',
      'architecture',
      'city',
      'urban',
      'construction',
      'money',
      'credit',
      'loan',
      'workplace',
    ],
    version: '1.7',
    unicode: 'ebe2',
  },
  IconBuildingBridge2: {
    category: 'Buildings',
    tags: [
      'building',
      'bridge',
      '2',
      'architecture',
      'urban',
      'river',
      'overpass',
      'city',
      'countryside',
    ],
    version: '1.1',
    unicode: 'ea4a',
  },
  IconBuildingBridge: {
    category: 'Buildings',
    tags: [
      'building',
      'bridge',
      'architecture',
      'urban',
      'river',
      'overpass',
      'city',
      'countryside',
    ],
    version: '1.1',
    unicode: 'ea4b',
  },
  IconBuildingCarousel: {
    category: 'Buildings',
    tags: [
      'building',
      'carousel',
      'amusement',
      'park',
      'fair',
      'merry-go-round',
      'fun',
      'entertaianment',
    ],
    version: '1.34',
    unicode: 'ed87',
  },
  IconBuildingCastle: {
    category: 'Buildings',
    tags: [
      'building',
      'castle',
      'king',
      'queen',
      'royal',
      'architecture',
      'medieval',
      'middle',
      'ages',
      'nobility',
      'tower',
      'fortress',
      'fort',
      'fortification',
      'princess',
      'prince',
    ],
    version: '1.34',
    unicode: 'ed88',
  },
  IconBuildingChurch: {
    category: 'Buildings',
    tags: [
      'building',
      'church',
      'religion',
      'chapel',
      'sanctuary',
      'temple',
      'cathedral',
      'pray',
      'prayer',
    ],
    version: '1.1',
    unicode: 'ea4c',
  },
  IconBuildingCommunity: {
    category: 'Buildings',
    tags: [
      'building',
      'community',
      'place',
      'skyscraper',
      'district neighborhood',
      'area',
    ],
    version: '1.8',
    unicode: 'ebf6',
  },
  IconBuildingCottage: {
    category: 'Buildings',
    tags: [
      'building',
      'cottage',
      'small',
      'house',
      'countryside',
      'live',
      'farm',
      'rural',
      'outskirts',
    ],
    version: '1.39',
    unicode: 'ee1b',
  },
  IconBuildingFactory: {
    category: 'Buildings',
    tags: [
      'building',
      'factory',
      'goods',
      'manufature',
      'machine',
      'trade',
      'produce',
      'product',
      'worker',
      'industry',
      'industrial',
      'site',
    ],
    version: '1.39',
    unicode: 'ee1c',
  },
  IconBuildingFortress: {
    category: 'Buildings',
    tags: [
      'building',
      'fortress',
      'military',
      'town',
      'defend',
      'attack',
      'stronghold',
      'protect',
      'protection',
      'medieval',
    ],
    version: '1.34',
    unicode: 'ed89',
  },
  IconBuildingHospital: {
    category: 'Buildings',
    tags: [
      'building',
      'hospital',
      'doctor',
      'sickness',
      'illness',
      'nurse',
      'medication',
      'emergency',
      'treat',
      'surgery',
    ],
    version: '1.1',
    unicode: 'ea4d',
  },
  IconBuildingLighthouse: {
    category: 'Buildings',
    tags: [
      'building',
      'lighthouse',
      'light',
      'sea',
      'tower',
      'beacon',
      'flash',
      'ship',
      'guide',
      'lightship',
      'leading',
      'watchtower',
      'signal',
    ],
    version: '1.34',
    unicode: 'ed8a',
  },
  IconBuildingMonument: {
    category: 'Buildings',
    tags: ['building', 'monument', 'history', 'memorial', 'commemorative'],
    version: '1.26',
    unicode: 'ed26',
  },
  IconBuildingPavilon: {
    category: 'Buildings',
    tags: [
      'building',
      'pavilon',
      'place',
      'party',
      'residence',
      'ornamentation',
    ],
    version: '1.8',
    unicode: 'ebf7',
  },
  IconBuildingSkyscraper: {
    category: 'Buildings',
    tags: [
      'building',
      'skyscraper',
      'city',
      'urban',
      'office',
      'workplace',
      'corporation',
      'hotel',
      'apartments',
    ],
    version: '1.8',
    unicode: 'ec39',
  },
  IconBuildingStore: {
    category: 'Buildings',
    tags: [
      'building',
      'store',
      'shopping',
      'shop',
      'supermarket',
      'market',
      'products',
      'retail',
      'buy',
      'sell',
    ],
    version: '1.1',
    unicode: 'ea4e',
  },
  IconBuildingWarehouse: {
    category: 'Buildings',
    tags: [
      'building',
      'warehouse',
      'store',
      'inventory',
      'stuff',
      'things',
      'machinery',
    ],
    version: '1.7',
    unicode: 'ebe3',
  },
  IconBuilding: {
    category: 'Buildings',
    tags: [
      'building',
      'flat',
      'office',
      'city',
      'urban',
      'scyscraper',
      'architecture',
      'construction',
    ],
    version: '1.1',
    unicode: 'ea4f',
  },
  IconBulbOff: {
    category: '',
    tags: [
      'bulb',
      'off',
      'energy',
      'power',
      'electricity',
      'creativity',
      'light',
      'idea',
    ],
    version: '1.0',
    unicode: 'ea50',
  },
  IconBulb: {
    category: '',
    tags: [
      'bulb',
      'energy',
      'power',
      'electricity',
      'creativity',
      'light',
      'idea',
    ],
    version: '1.0',
    unicode: 'ea51',
  },
  IconBulldozer: {
    category: 'Vehicles',
    tags: [
      'bulldozer',
      'tractor',
      'construction',
      'site',
      'build',
      'rear',
      'machine',
    ],
    version: '1.34',
    unicode: 'ee1d',
  },
  IconBus: {
    category: 'Vehicles',
    tags: [
      'bus',
      'vehicle',
      'drive',
      'driver',
      'engine',
      'motor',
      'journey',
      'trip',
    ],
    version: '1.7',
    unicode: 'ebe4',
  },
  IconBusinessplan: {
    category: '',
    tags: [
      'businessplan',
      'business',
      'money',
      'corporate',
      'document',
      'goal',
      'achieve',
      'manage',
      'roadmap',
      'grow',
    ],
    version: '1.39',
    unicode: 'ee1e',
  },
  IconButterfly: {
    category: '',
    tags: ['butterfly'],
    version: '1.51',
    unicode: 'efd9',
  },
  IconCSharp: {
    category: '',
    tags: ['c', 'sharp'],
    version: '1.53',
    unicode: 'f003',
  },
  IconCake: {
    category: '',
    tags: ['cake'],
    version: '1.54',
    unicode: 'f00f',
  },
  IconCalculator: {
    category: '',
    tags: [
      'calculator',
      'math',
      'count',
      'add',
      'subtract',
      'multiply',
      'divide',
      'amount',
    ],
    version: '1.3',
    unicode: 'eb80',
  },
  IconCalendarEvent: {
    category: 'System',
    tags: ['calendar', 'event', 'date', 'day', 'plan', 'schedule', 'agenda'],
    version: '1.1',
    unicode: 'ea52',
  },
  IconCalendarMinus: {
    category: 'System',
    tags: ['calendar', 'minus', 'date', 'day', 'plan', 'schedule', 'agenda'],
    version: '1.5',
    unicode: 'ebb9',
  },
  IconCalendarOff: {
    category: 'System',
    tags: [
      'calendar',
      'off',
      'unavailable',
      'unavailability',
      'leave',
      'plan',
      'schedule',
      'busy',
      'date',
      'month',
      'year',
      'meeting',
    ],
    version: '1.39',
    unicode: 'ee1f',
  },
  IconCalendarPlus: {
    category: 'System',
    tags: [
      'calendar',
      'plus',
      'date',
      'day',
      'plan',
      'schedule',
      'agenda',
      'add',
    ],
    version: '1.5',
    unicode: 'ebba',
  },
  IconCalendarStats: {
    category: 'System',
    tags: [
      'calendar',
      'stats',
      'plan',
      'timetable',
      'schedule',
      'meeting',
      'busy',
      'month',
      'year',
      'date',
      'statistics',
    ],
    version: '1.39',
    unicode: 'ee20',
  },
  IconCalendarTime: {
    category: 'System',
    tags: [
      'calendar',
      'time',
      'plan',
      'timetable',
      'schedule',
      'meeting',
      'busy',
      'month',
      'year',
      'date',
    ],
    version: '1.39',
    unicode: 'ee21',
  },
  IconCalendar: {
    category: 'System',
    tags: ['calendar', 'date', 'day', 'plan', 'schedule', 'agenda'],
    version: '1.0',
    unicode: 'ea53',
  },
  IconCameraMinus: {
    category: 'Media',
    tags: ['camera', 'minus', 'video', 'photo', 'aperture'],
    version: '1.11',
    unicode: 'ec3a',
  },
  IconCameraOff: {
    category: 'Media',
    tags: ['camera', 'off', 'video', 'photo', 'aperture'],
    version: '1.22',
    unicode: 'ecee',
  },
  IconCameraPlus: {
    category: 'Media',
    tags: ['camera', 'plus', 'video', 'photo', 'aperture'],
    version: '1.11',
    unicode: 'ec3b',
  },
  IconCameraRotate: {
    category: 'Photography',
    tags: [
      'camera',
      'rotate',
      'photo',
      'photography',
      'picture',
      'face',
      'instagram',
      'portrait',
      'digital',
      'smartphone',
      'selfie',
    ],
    version: '1.39',
    unicode: 'ee22',
  },
  IconCameraSelfie: {
    category: 'Photography',
    tags: [
      'camera',
      'selfie',
      'photo',
      'photography',
      'picture',
      'face',
      'instagram',
      'portrait',
      'digital',
      'smartphone',
    ],
    version: '1.39',
    unicode: 'ee23',
  },
  IconCamera: {
    category: 'Media',
    tags: ['camera', 'video', 'photo', 'aperture'],
    version: '1.0',
    unicode: 'ea54',
  },
  IconCandle: {
    category: '',
    tags: ['candle'],
    version: '1.50',
    unicode: 'efc6',
  },
  IconCandy: {
    category: 'Food',
    tags: ['candy'],
    version: '1.40',
    unicode: 'ef0d',
  },
  IconCapture: {
    category: 'Media',
    tags: ['capture', 'photo', 'photographer', 'sharpen'],
    version: '1.11',
    unicode: 'ec3c',
  },
  IconCarCrane: {
    category: 'Vehicles',
    tags: ['car', 'crane'],
    version: '1.41',
    unicode: 'ef25',
  },
  IconCarCrash: {
    category: 'Vehicles',
    tags: ['car', 'crash'],
    version: '1.48',
    unicode: 'efa4',
  },
  IconCar: {
    category: 'Vehicles',
    tags: [
      'car',
      'vehicle',
      'drive',
      'driver',
      'engine',
      'motor',
      'journey',
      'trip',
    ],
    version: '1.5',
    unicode: 'ebbb',
  },
  IconCaravan: {
    category: 'Vehicles',
    tags: ['caravan', 'journey', 'trip', 'holidays', 'camping', 'trailer'],
    version: '1.14',
    unicode: 'ec7c',
  },
  IconCardboards: {
    category: '',
    tags: [
      'cardboards',
      'vr',
      'virtual reality',
      'watch',
      'viewer',
      'technology',
    ],
    version: '1.33',
    unicode: 'ed74',
  },
  IconCaretDown: {
    category: 'Arrows',
    tags: ['caret', 'down', 'next', 'bottom', 'dropdown', 'show', 'more'],
    version: '1.2',
    unicode: 'eb5d',
  },
  IconCaretLeft: {
    category: 'Arrows',
    tags: ['caret', 'left', 'back', 'previous'],
    version: '1.2',
    unicode: 'eb5e',
  },
  IconCaretRight: {
    category: 'Arrows',
    tags: ['caret', 'right', 'next', 'play', 'more'],
    version: '1.2',
    unicode: 'eb5f',
  },
  IconCaretUp: {
    category: 'Arrows',
    tags: ['caret', 'up', 'dropdown', 'less', 'up'],
    version: '1.2',
    unicode: 'eb60',
  },
  IconCashBanknoteOff: {
    category: 'E-commerce',
    tags: [
      'cash',
      'banknote',
      'off',
      'money',
      'pay',
      'bank',
      'dollar',
      'pound',
      'bank',
      'yen',
      'business',
    ],
    version: '1.39',
    unicode: 'ee24',
  },
  IconCashBanknote: {
    category: 'E-commerce',
    tags: [
      'cash',
      'banknote',
      'money',
      'pay',
      'bank',
      'dollar',
      'pound',
      'bank',
      'yen',
      'business',
    ],
    version: '1.39',
    unicode: 'ee25',
  },
  IconCash: {
    category: 'E-commerce',
    tags: ['cash', 'currency', 'payment', 'money', 'pay'],
    version: '1.0',
    unicode: 'ea55',
  },
  IconCast: {
    category: 'Media',
    tags: [
      'cast',
      'broadcast',
      'stream',
      'mirroring',
      'apple',
      'airplay',
      'chromecast',
    ],
    version: '1.0',
    unicode: 'ea56',
  },
  IconCe: {
    category: 'Symbols',
    tags: [
      'ce',
      'sign',
      'marking',
      'administration',
      'administrative',
      'conformity',
      'health',
      'safety',
      'environment',
      'protection',
      'standards',
      'product',
      'europe',
      'eea',
      'economic',
      'area',
      'manufacture',
    ],
    version: '1.33',
    unicode: 'ed75',
  },
  IconCertificate: {
    category: 'Document',
    tags: [
      'certificate',
      'document',
      'official',
      'attest',
      'signature',
      'birth',
      'death',
      'gift',
      'authenticity',
      'seal',
      'course',
      'complete',
      'qualification',
    ],
    version: '1.33',
    unicode: 'ed76',
  },
  IconChargingPile: {
    category: 'Vehicles',
    tags: [
      'charging',
      'pile',
      'electric',
      'electricity',
      'hybrid',
      'tesla',
      'station',
      'electronic',
      'point',
    ],
    version: '1.39',
    unicode: 'ee26',
  },
  IconChartArcs3: {
    category: 'Charts',
    tags: [
      'chart',
      'arcs',
      '3',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.39',
    unicode: 'ee27',
  },
  IconChartArcs: {
    category: 'Charts',
    tags: [
      'chart',
      'arcs',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.39',
    unicode: 'ee28',
  },
  IconChartAreaLine: {
    category: 'Charts',
    tags: [
      'chart',
      'area',
      'line',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.0',
    unicode: 'ea57',
  },
  IconChartArea: {
    category: 'Charts',
    tags: [
      'chart',
      'area',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.0',
    unicode: 'ea58',
  },
  IconChartArrowsVertical: {
    category: 'Charts',
    tags: [
      'chart',
      'arrows',
      'vertical',
      'statistics',
      'data',
      'value',
      'variable',
      'scale',
      'statistical',
      'level',
      'increase',
      'decrease',
    ],
    version: '1.39',
    unicode: 'ee29',
  },
  IconChartArrows: {
    category: 'Charts',
    tags: [
      'chart',
      'arrows',
      'statistics',
      'data',
      'value',
      'variable',
      'scale',
      'statistical',
      'level',
      'increase',
      'decrease',
    ],
    version: '1.39',
    unicode: 'ee2a',
  },
  IconChartBar: {
    category: 'Charts',
    tags: [
      'chart',
      'bar',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.0',
    unicode: 'ea59',
  },
  IconChartBubble: {
    category: 'Charts',
    tags: [
      'chart',
      'bubble',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.13',
    unicode: 'ec75',
  },
  IconChartCandle: {
    category: 'Charts',
    tags: [
      'chart',
      'candle',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.0',
    unicode: 'ea5a',
  },
  IconChartCircles: {
    category: 'Charts',
    tags: ['chart', 'circles', 'statistics', 'analysis', 'analyse', 'graph'],
    version: '1.39',
    unicode: 'ee2b',
  },
  IconChartDonut2: {
    category: 'Charts',
    tags: [
      'chart',
      'donut',
      '2',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.39',
    unicode: 'ee2c',
  },
  IconChartDonut3: {
    category: 'Charts',
    tags: [
      'chart',
      'donut',
      '3',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.39',
    unicode: 'ee2d',
  },
  IconChartDonut4: {
    category: 'Charts',
    tags: [
      'chart',
      'donut',
      '4',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.39',
    unicode: 'ee2e',
  },
  IconChartDonut: {
    category: 'Charts',
    tags: [
      'chart',
      'donut',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.0',
    unicode: 'ea5b',
  },
  IconChartDots: {
    category: 'Charts',
    tags: [
      'chart',
      'dots',
      'statistics',
      'data',
      'value',
      'variable',
      'scale',
      'statistical',
    ],
    version: '1.39',
    unicode: 'ee2f',
  },
  IconChartInfographic: {
    category: 'Charts',
    tags: [
      'chart',
      'infographic',
      'statistics',
      'data',
      'value',
      'variable',
      'scale',
      'statistical',
      'bar',
      'information',
      'report',
    ],
    version: '1.39',
    unicode: 'ee30',
  },
  IconChartLine: {
    category: 'Charts',
    tags: [
      'chart',
      'line',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.0',
    unicode: 'ea5c',
  },
  IconChartPie2: {
    category: 'Charts',
    tags: [
      'chart',
      'pie',
      '2',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.39',
    unicode: 'ee31',
  },
  IconChartPie3: {
    category: 'Charts',
    tags: [
      'chart',
      'pie',
      '3',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.39',
    unicode: 'ee32',
  },
  IconChartPie4: {
    category: 'Charts',
    tags: [
      'chart',
      'pie',
      '4',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.39',
    unicode: 'ee33',
  },
  IconChartPie: {
    category: 'Charts',
    tags: [
      'chart',
      'pie',
      'statistics',
      'diagram',
      'graph',
      'rhythm',
      'data',
      'analysis',
    ],
    version: '1.0',
    unicode: 'ea5d',
  },
  IconChartRadar: {
    category: 'Charts',
    tags: [
      'chart',
      'radar',
      'statistics',
      'data',
      'value',
      'two',
      'dimensions',
      'variable',
      'report',
      'points',
    ],
    version: '1.33',
    unicode: 'ed77',
  },
  IconCheck: {
    category: 'System',
    tags: ['check', 'tick', 'yes', 'confirm'],
    version: '1.0',
    unicode: 'ea5e',
  },
  IconCheckbox: {
    category: 'System',
    tags: ['checkbox', 'survey', 'confirm', 'tick', 'yes', 'to-do'],
    version: '1.4',
    unicode: 'eba6',
  },
  IconChecks: {
    category: 'System',
    tags: ['checks', 'tick', 'yes', 'confirm'],
    version: '1.4',
    unicode: 'ebaa',
  },
  IconCheckupList: {
    category: 'Health',
    tags: ['checkup', 'list'],
    version: '1.44',
    unicode: 'ef5a',
  },
  IconCheese: {
    category: 'Food',
    tags: ['cheese'],
    version: '1.41',
    unicode: 'ef26',
  },
  IconChevronDownLeft: {
    category: 'Arrows',
    tags: ['chevron', 'down', 'left', 'move', 'aside', 'bottom'],
    version: '1.24',
    unicode: 'ed09',
  },
  IconChevronDownRight: {
    category: 'Arrows',
    tags: ['chevron', 'down', 'right', 'move', 'aside', 'bottom'],
    version: '1.24',
    unicode: 'ed0a',
  },
  IconChevronDown: {
    category: 'Arrows',
    tags: ['chevron', 'down', 'move', 'next', 'swipe', 'bottom'],
    version: '1.0',
    unicode: 'ea5f',
  },
  IconChevronLeft: {
    category: 'Arrows',
    tags: ['chevron', 'left', 'move', 'previous', 'back'],
    version: '1.0',
    unicode: 'ea60',
  },
  IconChevronRight: {
    category: 'Arrows',
    tags: ['chevron', 'right', 'move', 'checklist', 'next'],
    version: '1.0',
    unicode: 'ea61',
  },
  IconChevronUpLeft: {
    category: 'Arrows',
    tags: ['chevron', 'up', 'left', 'move', 'aside', 'top'],
    version: '1.24',
    unicode: 'ed0b',
  },
  IconChevronUpRight: {
    category: 'Arrows',
    tags: ['chevron', 'up', 'right', 'move', 'aside', 'top'],
    version: '1.24',
    unicode: 'ed0c',
  },
  IconChevronUp: {
    category: 'Arrows',
    tags: ['chevron', 'up', 'move', 'top'],
    version: '1.0',
    unicode: 'ea62',
  },
  IconChevronsDownLeft: {
    category: 'Arrows',
    tags: ['chevrons', 'down', 'left', 'move', 'aside', 'bottom'],
    version: '1.24',
    unicode: 'ed0d',
  },
  IconChevronsDownRight: {
    category: 'Arrows',
    tags: ['chevrons', 'down', 'right', 'move', 'aside', 'bottom'],
    version: '1.24',
    unicode: 'ed0e',
  },
  IconChevronsDown: {
    category: 'Arrows',
    tags: ['chevrons', 'down', 'move', 'bottom'],
    version: '1.0',
    unicode: 'ea63',
  },
  IconChevronsLeft: {
    category: 'Arrows',
    tags: ['chevrons', 'left', 'move', 'back', 'roll'],
    version: '1.0',
    unicode: 'ea64',
  },
  IconChevronsRight: {
    category: 'Arrows',
    tags: ['chevrons', 'right', 'move', 'next', 'checklist'],
    version: '1.0',
    unicode: 'ea65',
  },
  IconChevronsUpLeft: {
    category: 'Arrows',
    tags: ['chevrons', 'up', 'left', 'move', 'aside', 'top'],
    version: '1.24',
    unicode: 'ed0f',
  },
  IconChevronsUpRight: {
    category: 'Arrows',
    tags: ['chevrons', 'up', 'right', 'move', 'aside', 'top'],
    version: '1.24',
    unicode: 'ed10',
  },
  IconChevronsUp: {
    category: 'Arrows',
    tags: ['chevrons', 'up', 'move', 'top'],
    version: '1.0',
    unicode: 'ea66',
  },
  IconChristmasTree: {
    category: 'Nature',
    tags: [
      'christmas',
      'tree',
      'holidays',
      'pine',
      'decorate',
      'decoration',
      'gift',
      'present',
      'carol',
      'evergreen',
      'spruce',
      'fir',
      'winter',
    ],
    version: '1.33',
    unicode: 'ed78',
  },
  IconCircle0: {
    category: 'Numbers',
    tags: [
      'circle',
      '0',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'ee34',
  },
  IconCircle1: {
    category: 'Numbers',
    tags: [
      'circle',
      '1',
      'one',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'ee35',
  },
  IconCircle2: {
    category: 'Numbers',
    tags: [
      'circle',
      '2',
      'two',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'ee36',
  },
  IconCircle3: {
    category: 'Numbers',
    tags: [
      'circle',
      '3',
      'three',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'ee37',
  },
  IconCircle4: {
    category: 'Numbers',
    tags: [
      'circle',
      '4',
      'four',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'ee38',
  },
  IconCircle5: {
    category: 'Numbers',
    tags: [
      'circle',
      '5',
      'five',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'ee39',
  },
  IconCircle6: {
    category: 'Numbers',
    tags: [
      'circle',
      '6',
      'six',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'ee3a',
  },
  IconCircle7: {
    category: 'Numbers',
    tags: [
      'circle',
      '7',
      'seven',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'ee3b',
  },
  IconCircle8: {
    category: 'Numbers',
    tags: [
      'circle',
      '8',
      'eight',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'ee3c',
  },
  IconCircle9: {
    category: 'Numbers',
    tags: [
      'circle',
      '9',
      'nine',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'ee3d',
  },
  IconCircleCheck: {
    category: '',
    tags: ['circle', 'check', 'yes', 'confirm'],
    version: '1.0',
    unicode: 'ea67',
  },
  IconCircleDashed: {
    category: '',
    tags: ['circle', 'dashed', 'shape', 'line', 'check'],
    version: '1.26',
    unicode: 'ed27',
  },
  IconCircleDot: {
    category: '',
    tags: ['circle', 'dot'],
    version: '1.49',
    unicode: 'efb1',
  },
  IconCircleDotted: {
    category: '',
    tags: ['circle', 'dotted', 'shape', 'point', 'check'],
    version: '1.26',
    unicode: 'ed28',
  },
  IconCircleHalf2: {
    category: 'Design',
    tags: ['circle', 'half', '2'],
    version: '1.52',
    unicode: 'eff3',
  },
  IconCircleHalfVertical: {
    category: '',
    tags: ['circle', 'half', 'vertical', 'shape', 'split', 'slash'],
    version: '1.39',
    unicode: 'ee3e',
  },
  IconCircleHalf: {
    category: '',
    tags: ['circle', 'half', 'shape', 'split', 'slash'],
    version: '1.39',
    unicode: 'ee3f',
  },
  IconCircleMinus: {
    category: '',
    tags: ['circle', 'minus', 'remove', 'delete'],
    version: '1.0',
    unicode: 'ea68',
  },
  IconCircleOff: {
    category: 'Shapes',
    tags: ['circle', 'off', 'shape', 'split', 'slash', 'disabled'],
    version: '1.39',
    unicode: 'ee40',
  },
  IconCirclePlus: {
    category: '',
    tags: ['circle', 'plus', 'add', 'create', 'new'],
    version: '1.0',
    unicode: 'ea69',
  },
  IconCircleRectangle: {
    category: '',
    tags: ['circle', 'rectangle'],
    version: '1.54',
    unicode: 'f010',
  },
  IconCircleSquare: {
    category: 'Shapes',
    tags: ['circle', 'square', 'shape', 'spot', 'math'],
    version: '1.21',
    unicode: 'ece4',
  },
  IconCircleTriangle: {
    category: '',
    tags: ['circle', 'triangle'],
    version: '1.54',
    unicode: 'f011',
  },
  IconCircleX: {
    category: '',
    tags: ['circle', 'x', 'cancel', 'no'],
    version: '1.0',
    unicode: 'ea6a',
  },
  IconCircle: {
    category: 'Shapes',
    tags: ['circle', 'false', 'zero'],
    version: '1.0',
    unicode: 'ea6b',
  },
  IconCircles: {
    category: 'Shapes',
    tags: ['circles', 'shape', 'marbles', 'balls', 'juggle', 'spots'],
    version: '1.21',
    unicode: 'ece5',
  },
  IconClearAll: {
    category: '',
    tags: ['clear', 'all', 'app', 'clear', 'all', 'emails', 'phone'],
    version: '1.39',
    unicode: 'ee41',
  },
  IconClearFormatting: {
    category: 'Text',
    tags: ['clear', 'formatting', 'text', 'return', 'default'],
    version: '1.7',
    unicode: 'ebe5',
  },
  IconClick: {
    category: '',
    tags: ['click', 'select', 'cursor'],
    version: '1.5',
    unicode: 'ebbc',
  },
  IconClipboardCheck: {
    category: 'Document',
    tags: ['clipboard', 'check', 'copy', 'yes'],
    version: '1.0',
    unicode: 'ea6c',
  },
  IconClipboardList: {
    category: 'Document',
    tags: ['clipboard', 'list', 'copy', 'items'],
    version: '1.0',
    unicode: 'ea6d',
  },
  IconClipboardPlus: {
    category: 'Document',
    tags: ['clipboard', 'plus'],
    version: '1.49',
    unicode: 'efb2',
  },
  IconClipboardX: {
    category: 'Document',
    tags: ['clipboard', 'x', 'copy', 'no'],
    version: '1.0',
    unicode: 'ea6e',
  },
  IconClipboard: {
    category: 'Document',
    tags: ['clipboard', 'copy'],
    version: '1.0',
    unicode: 'ea6f',
  },
  IconClock: {
    category: '',
    tags: ['clock', 'time', 'watch', 'alarm'],
    version: '1.0',
    unicode: 'ea70',
  },
  IconCloudDownload: {
    category: 'System',
    tags: ['cloud', 'download', 'files'],
    version: '1.0',
    unicode: 'ea71',
  },
  IconCloudFog: {
    category: 'Weather',
    tags: ['cloud', 'fog', 'weather', 'online'],
    version: '1.20',
    unicode: 'ecd9',
  },
  IconCloudLockOpen: {
    category: 'System',
    tags: ['cloud', 'lock', 'open'],
    version: '1.51',
    unicode: 'efda',
  },
  IconCloudLock: {
    category: 'System',
    tags: ['cloud', 'lock'],
    version: '1.51',
    unicode: 'efdb',
  },
  IconCloudOff: {
    category: 'Weather',
    tags: [
      'cloud',
      'off',
      'auto',
      'backup',
      'storage',
      'memory',
      'files',
      'pictures',
      'store',
    ],
    version: '1.28',
    unicode: 'ed3e',
  },
  IconCloudRain: {
    category: 'Weather',
    tags: ['cloud', 'rain', 'weather', 'dry'],
    version: '1.0',
    unicode: 'ea72',
  },
  IconCloudSnow: {
    category: 'Weather',
    tags: ['cloud', 'snow', 'weather', 'blizzard'],
    version: '1.0',
    unicode: 'ea73',
  },
  IconCloudStorm: {
    category: 'Weather',
    tags: ['cloud', 'storm', 'weather', 'lightning'],
    version: '1.0',
    unicode: 'ea74',
  },
  IconCloudUpload: {
    category: 'System',
    tags: ['cloud', 'upload', 'files'],
    version: '1.0',
    unicode: 'ea75',
  },
  IconCloud: {
    category: 'Weather',
    tags: ['cloud', 'weather', 'online'],
    version: '1.0',
    unicode: 'ea76',
  },
  IconClubs: {
    category: 'Shapes',
    tags: ['clubs'],
    version: '1.52',
    unicode: 'eff4',
  },
  IconCodeMinus: {
    category: 'Text',
    tags: ['code', 'minus', 'remove', 'delete', 'insert', 'braces'],
    version: '1.39',
    unicode: 'ee42',
  },
  IconCodePlus: {
    category: 'Text',
    tags: ['code', 'plus', 'add', 'insert', 'braces'],
    version: '1.39',
    unicode: 'ee43',
  },
  IconCode: {
    category: 'Text',
    tags: ['code', 'brackets', 'source', 'programming', 'command'],
    version: '1.0',
    unicode: 'ea77',
  },
  IconCoffee: {
    category: 'Food',
    tags: ['coffee'],
    version: '1.40',
    unicode: 'ef0e',
  },
  IconCoin: {
    category: '',
    tags: ['coin', 'money', 'earn', 'salary', 'change', 'dollar'],
    version: '1.3',
    unicode: 'eb82',
  },
  IconColorPicker: {
    category: 'Design',
    tags: [
      'color',
      'picker',
      'timbre',
      'saturation',
      'paint',
      'image',
      'brush',
      'choice',
      'selection',
      'sample',
    ],
    version: '1.7',
    unicode: 'ebe6',
  },
  IconColorSwatch: {
    category: 'Design',
    tags: ['color', 'swatch', 'sample', 'choice', 'selection'],
    version: '1.2',
    unicode: 'eb61',
  },
  IconColumnInsertLeft: {
    category: 'Database',
    tags: ['column', 'insert', 'left', 'database', 'table', 'cells', 'arrow'],
    version: '1.39',
    unicode: 'ee44',
  },
  IconColumnInsertRight: {
    category: 'Database',
    tags: ['column', 'insert', 'right', 'database', 'table', 'cells', 'arrow'],
    version: '1.39',
    unicode: 'ee45',
  },
  IconColumns: {
    category: 'Text',
    tags: ['columns', 'text', 'gap', 'table'],
    version: '1.3',
    unicode: 'eb83',
  },
  IconComet: {
    category: 'Weather',
    tags: ['comet', 'space', 'universe', 'star', 'orb', 'glow', 'night'],
    version: '1.13',
    unicode: 'ec76',
  },
  IconCommand: {
    category: 'Symbols',
    tags: ['command', 'apple', 'key', 'keyboard', 'cmd'],
    version: '1.0',
    unicode: 'ea78',
  },
  IconCompass: {
    category: 'Map',
    tags: [
      'compass',
      'navigation',
      'safari',
      'travel',
      'direction',
      'discover',
    ],
    version: '1.0',
    unicode: 'ea79',
  },
  IconComponents: {
    category: 'Design',
    tags: ['components'],
    version: '1.48',
    unicode: 'efa5',
  },
  IconCone2: {
    category: '',
    tags: ['cone', '2'],
    version: '1.51',
    unicode: 'efdc',
  },
  IconCone: {
    category: '',
    tags: ['cone'],
    version: '1.51',
    unicode: 'efdd',
  },
  IconConfetti: {
    category: '',
    tags: [
      'confetti',
      'party',
      'celebrate',
      'streamers',
      'paper',
      'parade',
      'wedding',
      'celebration',
    ],
    version: '1.39',
    unicode: 'ee46',
  },
  IconContainer: {
    category: 'Design',
    tags: ['container', 'element', 'html', 'block', 'store', 'inside'],
    version: '1.39',
    unicode: 'ee47',
  },
  IconContrast2: {
    category: 'Photography',
    tags: ['contrast', '2', 'edit', 'paint', 'photo'],
    version: '1.50',
    unicode: 'efc7',
  },
  IconContrast: {
    category: 'Photography',
    tags: ['contrast', 'edit', 'paint', 'photo'],
    version: '1.12',
    unicode: 'ec4e',
  },
  IconCookie: {
    category: 'Food',
    tags: ['cookie'],
    version: '1.40',
    unicode: 'ef0f',
  },
  IconCopy: {
    category: 'Text',
    tags: ['copy', 'clipboard', 'clone', 'duplicate'],
    version: '1.0',
    unicode: 'ea7a',
  },
  IconCopyleft: {
    category: 'Symbols',
    tags: ['copyleft', 'licence', 'license'],
    version: '1.11',
    unicode: 'ec3d',
  },
  IconCopyright: {
    category: 'Symbols',
    tags: ['copyright', 'licence', 'license'],
    version: '1.0',
    unicode: 'ea7b',
  },
  IconCornerDownLeftDouble: {
    category: 'Arrows',
    tags: [
      'corner',
      'down',
      'left',
      'double',
      'arrow',
      'previous',
      'back',
      'return',
      'below',
      'point',
    ],
    version: '1.39',
    unicode: 'ee48',
  },
  IconCornerDownLeft: {
    category: 'Arrows',
    tags: ['corner', 'down', 'left', 'move', 'arrow'],
    version: '1.0',
    unicode: 'ea7c',
  },
  IconCornerDownRightDouble: {
    category: 'Arrows',
    tags: [
      'corner',
      'down',
      'right',
      'double',
      'arrow',
      'next',
      'below',
      'forward',
      'point',
    ],
    version: '1.39',
    unicode: 'ee49',
  },
  IconCornerDownRight: {
    category: 'Arrows',
    tags: ['corner', 'down', 'right', 'move', 'arrow'],
    version: '1.0',
    unicode: 'ea7d',
  },
  IconCornerLeftDownDouble: {
    category: 'Arrows',
    tags: [
      'corner',
      'left',
      'down',
      'double',
      'arrow',
      'point',
      'below',
      'bottom',
    ],
    version: '1.39',
    unicode: 'ee4a',
  },
  IconCornerLeftDown: {
    category: 'Arrows',
    tags: ['corner', 'left', 'down', 'move', 'arrow'],
    version: '1.0',
    unicode: 'ea7e',
  },
  IconCornerLeftUpDouble: {
    category: 'Arrows',
    tags: ['corner', 'left', 'up', 'double', 'arrow', 'point', 'above', 'top'],
    version: '1.39',
    unicode: 'ee4b',
  },
  IconCornerLeftUp: {
    category: 'Arrows',
    tags: ['corner', 'left', 'up', 'move', 'arrow'],
    version: '1.0',
    unicode: 'ea7f',
  },
  IconCornerRightDownDouble: {
    category: 'Arrows',
    tags: [
      'corner',
      'right',
      'down',
      'double',
      'arrow',
      'point',
      'below',
      'bottom',
    ],
    version: '1.39',
    unicode: 'ee4c',
  },
  IconCornerRightDown: {
    category: 'Arrows',
    tags: ['corner', 'right', 'down', 'move', 'arrow'],
    version: '1.0',
    unicode: 'ea80',
  },
  IconCornerRightUpDouble: {
    category: 'Arrows',
    tags: ['corner', 'right', 'up', 'double', 'arrow', 'point', 'above', 'top'],
    version: '1.39',
    unicode: 'ee4d',
  },
  IconCornerRightUp: {
    category: 'Arrows',
    tags: ['corner', 'right', 'up', 'move', 'arrow'],
    version: '1.0',
    unicode: 'ea81',
  },
  IconCornerUpLeftDouble: {
    category: 'Arrows',
    tags: [
      'corner',
      'up',
      'left',
      'double',
      'arrow',
      'point',
      'side',
      'previous',
      'back',
      'return',
    ],
    version: '1.39',
    unicode: 'ee4e',
  },
  IconCornerUpLeft: {
    category: 'Arrows',
    tags: ['corner', 'up', 'left', 'move', 'arrow'],
    version: '1.0',
    unicode: 'ea82',
  },
  IconCornerUpRightDouble: {
    category: 'Arrows',
    tags: [
      'corner',
      'up',
      'right',
      'double',
      'arrow',
      'next',
      'above',
      'forward',
      'point',
    ],
    version: '1.39',
    unicode: 'ee4f',
  },
  IconCornerUpRight: {
    category: 'Arrows',
    tags: ['corner', 'up', 'right', 'move', 'arrow'],
    version: '1.0',
    unicode: 'ea83',
  },
  IconCpu: {
    category: 'Devices',
    tags: ['cpu'],
    version: '1.47',
    unicode: 'ef8e',
  },
  IconCrane: {
    category: 'Vehicles',
    tags: ['crane'],
    version: '1.41',
    unicode: 'ef27',
  },
  IconCreativeCommons: {
    category: '',
    tags: ['creative', 'commons'],
    version: '1.49',
    unicode: 'efb3',
  },
  IconCreditCardOff: {
    category: '',
    tags: ['credit', 'card', 'off', 'money', 'purchase', 'payment', 'cc'],
    version: '1.24',
    unicode: 'ed11',
  },
  IconCreditCard: {
    category: '',
    tags: ['credit', 'card', 'money', 'purchase', 'payment', 'cc'],
    version: '1.0',
    unicode: 'ea84',
  },
  IconCrop: {
    category: 'Design',
    tags: ['crop', 'photo', 'image'],
    version: '1.0',
    unicode: 'ea85',
  },
  IconCross: {
    category: '',
    tags: ['cross'],
    version: '1.47',
    unicode: 'ef8f',
  },
  IconCrosshair: {
    category: '',
    tags: ['crosshair', 'reticle', 'tag', 'tracer', 'measurement', 'target'],
    version: '1.11',
    unicode: 'ec3e',
  },
  IconCrownOff: {
    category: '',
    tags: ['crown', 'off', 'king', 'queen', 'royal', 'throne', 'power'],
    version: '1.39',
    unicode: 'ee50',
  },
  IconCrown: {
    category: '',
    tags: [
      'crown',
      'symbol',
      'queen',
      'king',
      'prince',
      'princess',
      'dynasty',
      'royalty',
    ],
    version: '1.24',
    unicode: 'ed12',
  },
  IconCrutches: {
    category: 'Health',
    tags: ['crutches'],
    version: '1.44',
    unicode: 'ef5b',
  },
  IconCup: {
    category: 'Food',
    tags: ['cup'],
    version: '1.41',
    unicode: 'ef28',
  },
  IconCurling: {
    category: 'Sport',
    tags: ['curling'],
    version: '1.50',
    unicode: 'efc8',
  },
  IconCurlyLoop: {
    category: '',
    tags: ['curly', 'loop', 'voicemail', 'power', 'infinity'],
    version: '1.20',
    unicode: 'ecda',
  },
  IconCurrencyBahraini: {
    category: 'Currencies',
    tags: [
      'currency',
      'bahraini',
      'bahraini',
      'bhd',
      'commerce',
      'dinar',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee51',
  },
  IconCurrencyBath: {
    category: 'Currencies',
    tags: [
      'currency',
      'bath',
      'thb',
      'thai',
      'baht',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee52',
  },
  IconCurrencyBitcoin: {
    category: 'Currencies',
    tags: [
      'currency',
      'bitcoin',
      'crypto',
      'bitcoin',
      'lightning network',
      'mining',
      'digital',
      'blockchain',
      'p2p',
      'peer',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.4',
    unicode: 'ebab',
  },
  IconCurrencyCent: {
    category: 'Currencies',
    tags: [
      'currency',
      'cent',
      'cent',
      'coin',
      'money',
      'centavo',
      'penny',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee53',
  },
  IconCurrencyDinar: {
    category: 'Currencies',
    tags: [
      'currency',
      'dinar',
      'kwd',
      'dinar',
      'kuwait',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee54',
  },
  IconCurrencyDirham: {
    category: 'Currencies',
    tags: [
      'currency',
      'dirham',
      'trade',
      'aed',
      'uae',
      'dirham',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee55',
  },
  IconCurrencyDogecoin: {
    category: 'Currencies',
    tags: [
      'currency',
      'dogecoin',
      'crypto',
      'bitcoin',
      'lightning network',
      'mining',
      'digital',
      'blockchain',
      'p2p',
      'peer',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.43',
    unicode: 'ef4b',
  },
  IconCurrencyDollarAustralian: {
    category: 'Currencies',
    tags: [
      'currency',
      'dollar',
      'australian',
      'dollar',
      'aud',
      'australian',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee56',
  },
  IconCurrencyDollarCanadian: {
    category: 'Currencies',
    tags: [
      'currency',
      'dollar',
      'canadian',
      'trade',
      'dollar',
      'cad',
      'canadian',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee57',
  },
  IconCurrencyDollarSingapore: {
    category: 'Currencies',
    tags: [
      'currency',
      'dollar',
      'singapore',
      'singapore',
      'dollar',
      'exchange',
      'sgd',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee58',
  },
  IconCurrencyDollar: {
    category: 'Currencies',
    tags: [
      'currency',
      'dollar',
      'american',
      'us',
      'dollar',
      'usd',
      'sign',
      'bucks',
      'usa',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.3',
    unicode: 'eb84',
  },
  IconCurrencyEthereum: {
    category: 'Currencies',
    tags: [
      'currency',
      'ethereum',
      'ethereum',
      'digital',
      'crypto',
      'ether',
      'blockchain',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee59',
  },
  IconCurrencyEuro: {
    category: 'Currencies',
    tags: [
      'currency',
      'euro',
      'euro',
      'eur',
      'trade',
      'finance',
      'europe',
      'eu',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.3',
    unicode: 'eb85',
  },
  IconCurrencyForint: {
    category: 'Currencies',
    tags: [
      'currency',
      'forint',
      'huf',
      'hungarian',
      'business',
      'forint',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee5a',
  },
  IconCurrencyFrank: {
    category: 'Currencies',
    tags: [
      'currency',
      'frank',
      'chf',
      'business',
      'swiss',
      'franc',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee5b',
  },
  IconCurrencyKroneCzech: {
    category: 'Currencies',
    tags: [
      'currency',
      'krone',
      'czech',
      'czech',
      'czk',
      'koruna',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee5c',
  },
  IconCurrencyKroneDanish: {
    category: 'Currencies',
    tags: [
      'currency',
      'krone',
      'danish',
      'krone',
      'dkk',
      'danish',
      'finance',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee5d',
  },
  IconCurrencyKroneSwedish: {
    category: 'Currencies',
    tags: [
      'currency',
      'krone',
      'swedish',
      'krone',
      'kronor',
      'krona',
      'swedish',
      'icelandic',
      'norwegian',
      'estonian',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee5e',
  },
  IconCurrencyLeu: {
    category: 'Currencies',
    tags: [
      'currency',
      'leu',
      'leu',
      'loti',
      'lempira',
      'lek',
      'lilangani',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee5f',
  },
  IconCurrencyLira: {
    category: 'Currencies',
    tags: [
      'currency',
      'lira',
      'lira',
      'trade',
      'turkish',
      'try',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee60',
  },
  IconCurrencyLitecoin: {
    category: 'Currencies',
    tags: [
      'currency',
      'litecoin',
      'litecoin',
      'crypto',
      'segwit',
      'lightning network',
      'blockchain',
      'p2p',
      'peer',
      'transaction',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee61',
  },
  IconCurrencyNaira: {
    category: 'Currencies',
    tags: [
      'currency',
      'naira',
      'naira',
      'ngn',
      'nigerian',
      'trade',
      'money',
      'banknote',
      'pay',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee62',
  },
  IconCurrencyPound: {
    category: 'Currencies',
    tags: [
      'currency',
      'pound',
      'gbp',
      'pound',
      'sterling',
      'british',
      'britain',
      'uk',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.4',
    unicode: 'ebac',
  },
  IconCurrencyReal: {
    category: 'Currencies',
    tags: [
      'currency',
      'real',
      'brl',
      'brazilian',
      'real',
      'finance',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee63',
  },
  IconCurrencyRenminbi: {
    category: 'Currencies',
    tags: [
      'currency',
      'renminbi',
      'renminbi',
      'cny',
      'chinese',
      'yuan',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee64',
  },
  IconCurrencyRipple: {
    category: 'Currencies',
    tags: [
      'currency',
      'ripple',
      'ripple',
      'xrp',
      'digital',
      'crypto',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee65',
  },
  IconCurrencyRiyal: {
    category: 'Currencies',
    tags: [
      'currency',
      'riyal',
      'riyal',
      'sar',
      'saudi',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee66',
  },
  IconCurrencyRubel: {
    category: 'Currencies',
    tags: [
      'currency',
      'rubel',
      'rub',
      'russian',
      'ruble',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee67',
  },
  IconCurrencyRupee: {
    category: 'Currencies',
    tags: [
      'currency',
      'rupee',
      'inr',
      'indian',
      'rupee',
      'exchange',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.4',
    unicode: 'ebad',
  },
  IconCurrencyShekel: {
    category: 'Currencies',
    tags: [
      'currency',
      'shekel',
      'curency',
      'ils',
      'israeli',
      'shekel',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee68',
  },
  IconCurrencyTaka: {
    category: 'Currencies',
    tags: [
      'currency',
      'taka',
      'trade',
      'bangladesh',
      'bdt',
      'taka',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee69',
  },
  IconCurrencyTugrik: {
    category: 'Currencies',
    tags: [
      'currency',
      'tugrik',
      'tgrg',
      'mnt',
      'tugrik',
      'mongolian',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee6a',
  },
  IconCurrencyWon: {
    category: 'Currencies',
    tags: [
      'currency',
      'won',
      'korean',
      'kpw',
      'north',
      'won',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee6b',
  },
  IconCurrencyYen: {
    category: 'Currencies',
    tags: [
      'currency',
      'yen',
      'japanese',
      'yen',
      'jpy',
      'chinese',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.4',
    unicode: 'ebae',
  },
  IconCurrencyZloty: {
    category: 'Currencies',
    tags: [
      'currency',
      'zloty',
      'poland',
      'pln',
      'zloty',
      'polish',
      'money',
      'banknote',
      'pay',
    ],
    version: '1.39',
    unicode: 'ee6c',
  },
  IconCurrency: {
    category: 'Currencies',
    tags: ['currency'],
    version: '1.48',
    unicode: 'efa6',
  },
  IconCurrentLocation: {
    category: 'Map',
    tags: [
      'current',
      'location',
      'localization',
      'maps',
      'navigation',
      'pin',
      'target',
    ],
    version: '1.22',
    unicode: 'ecef',
  },
  IconCursorText: {
    category: 'Text',
    tags: [
      'cursor',
      'text',
      'editor',
      'indicate',
      'position',
      'input',
      'mouse',
      'type',
    ],
    version: '1.39',
    unicode: 'ee6d',
  },
  IconCut: {
    category: 'Design',
    tags: ['cut', 'scissors', 'divide', 'hairdresser', 'sharp'],
    version: '1.0',
    unicode: 'ea86',
  },
  IconDashboard: {
    category: 'System',
    tags: ['dashboard', 'home', 'car'],
    version: '1.0',
    unicode: 'ea87',
  },
  IconDatabaseExport: {
    category: 'Database',
    tags: ['database', 'export', 'data', 'backup', 'file', 'storage', 'system'],
    version: '1.39',
    unicode: 'ee6e',
  },
  IconDatabaseImport: {
    category: 'Database',
    tags: ['database', 'import', 'data', 'file', 'storage', 'backup', 'system'],
    version: '1.39',
    unicode: 'ee6f',
  },
  IconDatabaseOff: {
    category: 'Database',
    tags: ['database', 'off', 'data', 'file', 'storage', 'table', 'system'],
    version: '1.39',
    unicode: 'ee70',
  },
  IconDatabase: {
    category: 'Database',
    tags: ['database', 'storage', 'data', 'memory'],
    version: '1.0',
    unicode: 'ea88',
  },
  IconDental: {
    category: '',
    tags: ['dental'],
    version: '1.55',
    unicode: 'f025',
  },
  IconDetails: {
    category: '',
    tags: ['details', 'geometric', 'half', 'shape', 'highlight', 'triangle'],
    version: '1.39',
    unicode: 'ee71',
  },
  IconDeviceAnalytics: {
    category: 'Devices',
    tags: [
      'device',
      'analytics',
      'analyze',
      'analyse',
      'data',
      'traffic',
      'user',
    ],
    version: '1.39',
    unicode: 'ee72',
  },
  IconDeviceAudioTape: {
    category: 'Devices',
    tags: [
      'device',
      'audio',
      'tape',
      'record',
      'music',
      'radio',
      'cassette',
      'recording',
      'play',
    ],
    version: '1.39',
    unicode: 'ee73',
  },
  IconDeviceCctv: {
    category: 'Devices',
    tags: [
      'device',
      'cctv',
      'closed',
      'circuit',
      'television',
      'video',
      'surveillance',
      'signal',
      'monitor',
      'record',
    ],
    version: '1.39',
    unicode: 'ee74',
  },
  IconDeviceComputerCameraOff: {
    category: 'Devices',
    tags: [
      'device',
      'computer',
      'camera',
      'off',
      'video',
      'meeting',
      'record',
      'recording',
      'webcam',
    ],
    version: '1.39',
    unicode: 'ee75',
  },
  IconDeviceComputerCamera: {
    category: 'Devices',
    tags: [
      'device',
      'computer',
      'camera',
      'video',
      'meeting',
      'record',
      'recording',
      'webcam',
    ],
    version: '1.39',
    unicode: 'ee76',
  },
  IconDeviceDesktopAnalytics: {
    category: 'Devices',
    tags: [
      'device',
      'desktop',
      'analytics',
      'monitor',
      'computer',
      'imac',
      'stats',
      'charts',
    ],
    version: '1.39',
    unicode: 'ee77',
  },
  IconDeviceDesktopOff: {
    category: 'Devices',
    tags: ['device', 'desktop', 'off', 'monitor', 'computer', 'imac'],
    version: '1.39',
    unicode: 'ee78',
  },
  IconDeviceDesktop: {
    category: 'Devices',
    tags: ['device', 'desktop', 'monitor', 'computer', 'imac'],
    version: '1.0',
    unicode: 'ea89',
  },
  IconDeviceFloppy: {
    category: 'Devices',
    tags: ['device', 'floppy', 'save', 'file'],
    version: '1.2',
    unicode: 'eb62',
  },
  IconDeviceGamepad: {
    category: 'Devices',
    tags: [
      'device',
      'gamepad',
      'play',
      'entertainment',
      'console',
      'joystick',
      'joypad',
      'controller',
    ],
    version: '1.2',
    unicode: 'eb63',
  },
  IconDeviceLaptop: {
    category: 'Devices',
    tags: [
      'device',
      'laptop',
      'workstation',
      'mac',
      'notebook',
      'portable',
      'screen',
      'computer',
    ],
    version: '1.2',
    unicode: 'eb64',
  },
  IconDeviceMobileMessage: {
    category: 'Devices',
    tags: [
      'device',
      'mobile',
      'message',
      'iphone',
      'phone',
      'smartphone',
      'cellphone',
      'sms',
      'texting',
      'chat',
      'text',
    ],
    version: '1.39',
    unicode: 'ee79',
  },
  IconDeviceMobileRotated: {
    category: 'Devices',
    tags: [
      'device',
      'mobile',
      'rotated',
      'iphone',
      'phone',
      'smartphone',
      'cellphone',
    ],
    version: '1.20',
    unicode: 'ecdb',
  },
  IconDeviceMobileVibration: {
    category: 'Devices',
    tags: [
      'device',
      'mobile',
      'vibration',
      'iphone',
      'phone',
      'smartphone',
      'cellphone',
    ],
    version: '1.3',
    unicode: 'eb86',
  },
  IconDeviceMobile: {
    category: 'Devices',
    tags: ['device', 'mobile', 'iphone', 'phone', 'smartphone', 'cellphone'],
    version: '1.0',
    unicode: 'ea8a',
  },
  IconDeviceNintendo: {
    category: 'Devices',
    tags: ['device', 'nintendo'],
    version: '1.55',
    unicode: 'f026',
  },
  IconDeviceSpeaker: {
    category: 'Devices',
    tags: ['device', 'speaker', 'sound', 'music', 'loud', 'audio', 'media'],
    version: '1.1',
    unicode: 'ea8b',
  },
  IconDeviceTablet: {
    category: 'Devices',
    tags: ['device', 'tablet', 'ipad', 'mobile', 'touchscreen', 'portable'],
    version: '1.0',
    unicode: 'ea8c',
  },
  IconDeviceTv: {
    category: 'Devices',
    tags: [
      'device',
      'tv',
      'screen',
      'display',
      'movie',
      'film',
      'watch',
      'audio',
      'video',
      'media',
    ],
    version: '1.0',
    unicode: 'ea8d',
  },
  IconDeviceWatchStats2: {
    category: 'Devices',
    tags: ['device', 'watch', 'stats', '2'],
    version: '1.46',
    unicode: 'ef7c',
  },
  IconDeviceWatchStats: {
    category: 'Devices',
    tags: ['device', 'watch', 'stats'],
    version: '1.46',
    unicode: 'ef7d',
  },
  IconDeviceWatch: {
    category: 'Devices',
    tags: [
      'device',
      'watch',
      'arm',
      'hour',
      'date',
      'minutes',
      'sec.',
      'timer',
    ],
    version: '1.8',
    unicode: 'ebf9',
  },
  IconDevices2: {
    category: 'Devices',
    tags: ['devices', '2', 'computer', 'monitor', 'keyboard'],
    version: '1.26',
    unicode: 'ed29',
  },
  IconDevicesPc: {
    category: 'Devices',
    tags: ['devices', 'pc', 'computer', 'monitor', 'keyboard'],
    version: '1.39',
    unicode: 'ee7a',
  },
  IconDevices: {
    category: 'Devices',
    tags: [
      'devices',
      'computer',
      'laptop',
      'notebook',
      'tablet',
      'phone',
      'mobile',
      'mac',
      'iphone',
    ],
    version: '1.3',
    unicode: 'eb87',
  },
  IconDiamond: {
    category: '',
    tags: ['diamond', 'jewellery', 'crystal', 'mineral', 'jewelry', 'ring'],
    version: '1.2',
    unicode: 'eb65',
  },
  IconDiamonds: {
    category: 'Shapes',
    tags: ['diamonds'],
    version: '1.52',
    unicode: 'eff5',
  },
  IconDice: {
    category: '',
    tags: [
      'dice',
      'game',
      'boardgame',
      'roll',
      'throw',
      'cube',
      'numbers',
      'gambling',
    ],
    version: '1.2',
    unicode: 'eb66',
  },
  IconDimensions: {
    category: 'Design',
    tags: ['dimensions', 'width', 'height', 'size', 'breadth', 'depth'],
    version: '1.39',
    unicode: 'ee7b',
  },
  IconDirectionHorizontal: {
    category: '',
    tags: ['direction', 'horizontal', 'travel', 'navigation discover', 'plane'],
    version: '1.8',
    unicode: 'ebfa',
  },
  IconDirection: {
    category: '',
    tags: ['direction', 'travel', 'navigation discover'],
    version: '1.8',
    unicode: 'ebfb',
  },
  IconDirections: {
    category: 'Map',
    tags: ['directions', 'travel', 'navigation', 'discover'],
    version: '1.0',
    unicode: 'ea8e',
  },
  IconDisabled2: {
    category: 'Health',
    tags: ['disabled', '2', 'wheelchair', 'accessible', 'handicapped'],
    version: '1.4',
    unicode: 'ebaf',
  },
  IconDisabled: {
    category: 'Health',
    tags: ['disabled', 'wheelchair', 'handicapped'],
    version: '1.0',
    unicode: 'ea8f',
  },
  IconDisc: {
    category: 'Devices',
    tags: ['disc', 'cd', 'music', 'album'],
    version: '1.0',
    unicode: 'ea90',
  },
  IconDiscount2: {
    category: 'E-commerce',
    tags: [
      'discount',
      '2',
      'sale',
      'reduction',
      'price',
      'cost',
      'money',
      'shopping',
      'bargain',
    ],
    version: '1.5',
    unicode: 'ee7c',
  },
  IconDiscount: {
    category: 'E-commerce',
    tags: [
      'discount',
      'sale',
      'reduction',
      'price',
      'cost',
      'money',
      'shopping',
      'bargain',
    ],
    version: '1.35',
    unicode: 'ebbd',
  },
  IconDivide: {
    category: 'Math',
    tags: [
      'divide',
      'separate',
      'element',
      'multiple',
      'apart',
      'separator',
      'parts',
    ],
    version: '1.31',
    unicode: 'ed5c',
  },
  IconDna2: {
    category: 'Health',
    tags: ['dna', '2'],
    version: '1.44',
    unicode: 'ef5c',
  },
  IconDna: {
    category: 'Health',
    tags: [
      'dna',
      'genetics',
      'biology',
      'chain',
      'genetic',
      'code',
      'virus',
      'organism',
    ],
    version: '1.39',
    unicode: 'ee7d',
  },
  IconDogBowl: {
    category: 'Food',
    tags: ['dog', 'bowl'],
    version: '1.41',
    unicode: 'ef29',
  },
  IconDoorEnter: {
    category: '',
    tags: ['door', 'enter'],
    version: '1.43',
    unicode: 'ef4c',
  },
  IconDoorExit: {
    category: '',
    tags: ['door', 'exit'],
    version: '1.43',
    unicode: 'ef4d',
  },
  IconDoor: {
    category: '',
    tags: ['door'],
    version: '1.43',
    unicode: 'ef4e',
  },
  IconDotsCircleHorizontal: {
    category: 'System',
    tags: ['dots', 'circle', 'horizontal', 'more', 'options'],
    version: '1.0',
    unicode: 'ea91',
  },
  IconDotsDiagonal2: {
    category: 'System',
    tags: ['dots', 'diagonal', '2', 'hellip', 'more', 'ellipsis'],
    version: '1.0',
    unicode: 'ea92',
  },
  IconDotsDiagonal: {
    category: 'System',
    tags: ['dots', 'diagonal', 'hellip', 'more', 'ellipsis'],
    version: '1.0',
    unicode: 'ea93',
  },
  IconDotsVertical: {
    category: 'System',
    tags: ['dots', 'vertical', 'hellip', 'more', 'ellipsis'],
    version: '1.0',
    unicode: 'ea94',
  },
  IconDots: {
    category: 'System',
    tags: ['dots', 'hellip', 'more', 'ellipsis'],
    version: '1.0',
    unicode: 'ea95',
  },
  IconDownload: {
    category: 'Arrows',
    tags: ['download', 'save', 'arrow'],
    version: '1.0',
    unicode: 'ea96',
  },
  IconDragDrop2: {
    category: 'Design',
    tags: ['drag', 'drop', '2', 'location', 'gesture', 'move'],
    version: '1.3',
    unicode: 'eb88',
  },
  IconDragDrop: {
    category: 'Design',
    tags: ['drag', 'drop', 'location', 'gesture', 'move'],
    version: '1.3',
    unicode: 'eb89',
  },
  IconDroneOff: {
    category: 'Vehicles',
    tags: [
      'drone',
      'off',
      'device',
      'fly',
      'aircraft',
      'surveillance',
      'control',
      'autonomous',
      'forbidden',
      'banned',
      'unlicensed',
    ],
    version: '1.39',
    unicode: 'ee7e',
  },
  IconDrone: {
    category: 'Vehicles',
    tags: [
      'drone',
      'device',
      'fly',
      'aircraft',
      'surveillance',
      'control',
      'autonomous',
    ],
    version: '1.33',
    unicode: 'ed79',
  },
  IconDropCircle: {
    category: '',
    tags: ['drop', 'circle'],
    version: '1.51',
    unicode: 'efde',
  },
  IconDropletFilled2: {
    category: 'Design',
    tags: ['droplet', 'filled', '2', 'water', 'rain', 'liquid', 'fill'],
    version: '1.39',
    unicode: 'ee7f',
  },
  IconDropletFilled: {
    category: 'Design',
    tags: ['droplet', 'filled', 'water', 'rain', 'liquid', 'fill'],
    version: '1.39',
    unicode: 'ee80',
  },
  IconDropletHalf2: {
    category: 'Design',
    tags: ['droplet', 'half', '2', 'water', 'rain', 'liquid', 'fill'],
    version: '1.39',
    unicode: 'ee81',
  },
  IconDropletHalf: {
    category: 'Design',
    tags: ['droplet', 'half', 'water', 'rain', 'liquid', 'fill'],
    version: '1.39',
    unicode: 'ee82',
  },
  IconDropletOff: {
    category: 'Design',
    tags: ['droplet', 'off', 'water', 'rain', 'liquid'],
    version: '1.39',
    unicode: 'ee83',
  },
  IconDroplet: {
    category: 'Design',
    tags: ['droplet', 'water', 'rain', 'liquid'],
    version: '1.0',
    unicode: 'ea97',
  },
  IconEarOff: {
    category: 'Health',
    tags: [
      'ear',
      'off',
      'sound',
      'deaf',
      'hearing',
      'impaired',
      'handicapped',
      'hard-of-hearing',
      'mute',
    ],
    version: '1.39',
    unicode: 'ee84',
  },
  IconEar: {
    category: 'Health',
    tags: ['ear', 'sound', 'listen', 'music', 'hear', 'loud', 'speakers'],
    version: '1.6',
    unicode: 'ebce',
  },
  IconEditCircle: {
    category: 'Design',
    tags: ['edit', 'circle', 'pencil', 'change', 'update'],
    version: '1.39',
    unicode: 'ee85',
  },
  IconEdit: {
    category: 'Design',
    tags: ['edit', 'pencil', 'change', 'update'],
    version: '1.0',
    unicode: 'ea98',
  },
  IconEgg: {
    category: 'Food',
    tags: ['egg', 'food', 'easter', 'chicken'],
    version: '1.3',
    unicode: 'eb8a',
  },
  IconElevator: {
    category: '',
    tags: ['elevator'],
    version: '1.51',
    unicode: 'efdf',
  },
  IconEmergencyBed: {
    category: 'Health',
    tags: ['emergency', 'bed'],
    version: '1.44',
    unicode: 'ef5d',
  },
  IconEmphasis: {
    category: 'Text',
    tags: ['emphasis', 'highlight', 'priority', 'stress'],
    version: '1.6',
    unicode: 'ebcf',
  },
  IconEngine: {
    category: 'Vehicles',
    tags: ['engine'],
    version: '1.46',
    unicode: 'ef7e',
  },
  IconEqualNot: {
    category: 'Math',
    tags: [
      'equal',
      'not',
      'maths',
      'mathematics',
      'equation',
      'different',
      'value',
    ],
    version: '1.39',
    unicode: 'ee86',
  },
  IconEqual: {
    category: 'Math',
    tags: ['equal', 'maths', 'mathematics', 'equation', 'same', 'value'],
    version: '1.39',
    unicode: 'ee87',
  },
  IconEraser: {
    category: 'Text',
    tags: ['eraser', 'delete', 'remove', 'eliminate', 'wipe-out'],
    version: '1.3',
    unicode: 'eb8b',
  },
  IconError404: {
    category: '',
    tags: ['error', '404'],
    version: '1.55',
    unicode: 'f027',
  },
  IconExchange: {
    category: '',
    tags: ['exchange', 'cantor', 'money', 'product', 'product', 'student'],
    version: '1.7',
    unicode: 'ebe7',
  },
  IconExclamationMark: {
    category: '',
    tags: ['exclamation', 'mark'],
    version: '1.49',
    unicode: 'efb4',
  },
  IconExposure: {
    category: 'Photography',
    tags: ['exposure', 'light', 'bright', 'dark', 'camera'],
    version: '1.3',
    unicode: 'eb8c',
  },
  IconExternalLink: {
    category: 'System',
    tags: ['external', 'link', 'connection', 'outbound', 'redirect'],
    version: '1.0',
    unicode: 'ea99',
  },
  IconEyeCheck: {
    category: 'Health',
    tags: ['eye', 'check', 'sight', 'visual', 'view', 'public', 'approve'],
    version: '1.39',
    unicode: 'ee88',
  },
  IconEyeOff: {
    category: 'Health',
    tags: ['eye', 'off', 'view', 'watch'],
    version: '1.22',
    unicode: 'ecf0',
  },
  IconEyeTable: {
    category: 'Health',
    tags: ['eye', 'table'],
    version: '1.44',
    unicode: 'ef5e',
  },
  IconEye: {
    category: 'Health',
    tags: ['eye', 'view', 'watch'],
    version: '1.0',
    unicode: 'ea9a',
  },
  IconEyeglass2: {
    category: 'Health',
    tags: [
      'eyeglass',
      '2',
      'sight',
      'defect',
      'see',
      'vision',
      'frames',
      'lenses',
      'visually',
      'impaired',
      'myopia',
      'far-sighted',
    ],
    version: '1.39',
    unicode: 'ee89',
  },
  IconEyeglass: {
    category: 'Health',
    tags: [
      'eyeglass',
      'sight',
      'defect',
      'see',
      'vision',
      'frames',
      'lenses',
      'visually',
      'impaired',
      'myopia',
      'far-sighted',
    ],
    version: '1.39',
    unicode: 'ee8a',
  },
  IconFaceIdError: {
    category: '',
    tags: ['face', 'id', 'error'],
    version: '1.48',
    unicode: 'efa7',
  },
  IconFaceId: {
    category: '',
    tags: ['face', 'id', 'apple', 'iphone', 'ipad'],
    version: '1.0',
    unicode: 'ea9b',
  },
  IconFaceMask: {
    category: '',
    tags: ['face', 'mask'],
    version: '1.49',
    unicode: 'efb5',
  },
  IconFall: {
    category: 'Health',
    tags: ['fall', 'collapse', 'damage', 'cliff', 'height'],
    version: '1.18',
    unicode: 'ecb9',
  },
  IconFeather: {
    category: '',
    tags: ['feather', 'bird', 'animal', 'nature'],
    version: '1.39',
    unicode: 'ee8b',
  },
  IconFence: {
    category: 'Buildings',
    tags: ['fence'],
    version: '1.41',
    unicode: 'ef2a',
  },
  IconFile3d: {
    category: 'Document',
    tags: ['file', '3d'],
    version: '1.56',
  },
  IconFileAlert: {
    category: 'Document',
    tags: [
      'file',
      'alert',
      'danger',
      'risk',
      'warning',
      'check',
      'caution',
      'document',
      'error',
    ],
    version: '1.37',
    unicode: 'ede6',
  },
  IconFileAnalytics: {
    category: 'Document',
    tags: [
      'file',
      'analytics',
      'data',
      'statistics',
      'report',
      'chart',
      'document',
      'paper',
    ],
    version: '1.37',
    unicode: 'ede7',
  },
  IconFileArrowLeft: {
    category: 'Document',
    tags: ['file', 'arrow', 'left'],
    version: '1.56',
  },
  IconFileArrowRight: {
    category: 'Document',
    tags: ['file', 'arrow', 'right'],
    version: '1.56',
  },
  IconFileBarcode: {
    category: 'Document',
    tags: ['file', 'barcode'],
    version: '1.56',
  },
  IconFileCertificate: {
    category: 'Document',
    tags: [
      'file',
      'certificate',
      'certificate',
      'license',
      'diploma',
      'document',
      'format',
      'data',
      'paper',
    ],
    version: '1.29',
    unicode: 'ed4d',
  },
  IconFileChart: {
    category: 'Document',
    tags: ['file', 'chart'],
    version: '1.56',
  },
  IconFileCheck: {
    category: 'Document',
    tags: ['file', 'check', 'yes'],
    version: '1.0',
    unicode: 'ea9c',
  },
  IconFileCode2: {
    category: 'Document',
    tags: [
      'file',
      'code',
      '2',
      'programming',
      'document',
      'developer',
      'technology',
    ],
    version: '1.37',
    unicode: 'ede8',
  },
  IconFileCode: {
    category: 'Document',
    tags: ['file', 'code', 'paper', 'new'],
    version: '1.6',
    unicode: 'ebd0',
  },
  IconFileDatabase: {
    category: 'Document',
    tags: ['file', 'database'],
    version: '1.56',
  },
  IconFileDescription: {
    category: 'Document',
    tags: ['file', 'description'],
    version: '1.55',
    unicode: 'f028',
  },
  IconFileDiff: {
    category: 'Document',
    tags: ['file', 'diff', 'add', 'create', 'new'],
    version: '1.22',
    unicode: 'ecf1',
  },
  IconFileDigit: {
    category: 'Document',
    tags: ['file', 'digit'],
    version: '1.48',
    unicode: 'efa8',
  },
  IconFileDislike: {
    category: 'Document',
    tags: ['file', 'dislike', 'rejected', 'document'],
    version: '1.26',
    unicode: 'ed2a',
  },
  IconFileDollar: {
    category: 'Document',
    tags: ['file', 'dollar'],
    version: '1.51',
    unicode: 'efe0',
  },
  IconFileDots: {
    category: 'Document',
    tags: ['file', 'dots'],
    version: '1.56',
  },
  IconFileDownload: {
    category: 'Document',
    tags: ['file', 'download', 'save', 'transfer', 'input'],
    version: '1.0',
    unicode: 'ea9d',
  },
  IconFileEuro: {
    category: 'Document',
    tags: ['file', 'euro'],
    version: '1.51',
    unicode: 'efe1',
  },
  IconFileExport: {
    category: 'Document',
    tags: ['file', 'export', 'arrow', 'data', 'paper', 'document', 'format'],
    version: '1.37',
    unicode: 'ede9',
  },
  IconFileHorizontal: {
    category: 'Document',
    tags: ['file', 'horizontal', 'paper', 'new'],
    version: '1.4',
    unicode: 'ebb0',
  },
  IconFileImport: {
    category: 'Document',
    tags: ['file', 'import', 'arrow', 'data', 'paper', 'document', 'format'],
    version: '1.37',
    unicode: 'edea',
  },
  IconFileInfo: {
    category: 'Document',
    tags: [
      'file',
      'info',
      'info',
      'information',
      'informations',
      'paper',
      'file',
      'document',
      'page',
    ],
    version: '1.38',
    unicode: 'edec',
  },
  IconFileInvoice: {
    category: 'Document',
    tags: [
      'file',
      'invoice',
      'accounting',
      'bill',
      'statement',
      'settlement',
      'finance',
    ],
    version: '1.2',
    unicode: 'eb67',
  },
  IconFileLike: {
    category: 'Document',
    tags: ['file', 'like', 'approved', 'document'],
    version: '1.26',
    unicode: 'ed2b',
  },
  IconFileMinus: {
    category: 'Document',
    tags: ['file', 'minus', 'remove', 'delete'],
    version: '1.0',
    unicode: 'ea9e',
  },
  IconFileMusic: {
    category: 'Document',
    tags: ['file', 'music', 'mp3', 'wma', 'wav'],
    version: '1.0',
    unicode: 'ea9f',
  },
  IconFileOff: {
    category: 'Document',
    tags: ['file', 'off', 'new', 'close', 'gap', 'paper'],
    version: '1.22',
    unicode: 'ecf2',
  },
  IconFilePencil: {
    category: 'Document',
    tags: ['file', 'pencil'],
    version: '1.56',
  },
  IconFilePhone: {
    category: 'Document',
    tags: ['file', 'phone', 'save', 'transfer', 'input'],
    version: '1.20',
    unicode: 'ecdc',
  },
  IconFilePlus: {
    category: 'Document',
    tags: ['file', 'plus', 'add', 'create', 'new'],
    version: '1.0',
    unicode: 'eaa0',
  },
  IconFilePower: {
    category: 'Document',
    tags: ['file', 'power'],
    version: '1.56',
  },
  IconFileReport: {
    category: 'Document',
    tags: [
      'file',
      'report',
      'stats',
      'data',
      'paper',
      'document',
      'chart',
      'format',
    ],
    version: '1.38',
    unicode: 'eded',
  },
  IconFileRss: {
    category: 'Document',
    tags: ['file', 'rss'],
    version: '1.56',
  },
  IconFileScissors: {
    category: 'Document',
    tags: ['file', 'scissors'],
    version: '1.56',
  },
  IconFileSearch: {
    category: 'Document',
    tags: ['file', 'search', 'search', 'data', 'paper', 'document', 'format'],
    version: '1.31',
    unicode: 'ed5d',
  },
  IconFileSettings: {
    category: 'Document',
    tags: ['file', 'settings'],
    version: '1.55',
    unicode: 'f029',
  },
  IconFileShredder: {
    category: 'Document',
    tags: ['file', 'shredder', 'shred', 'destroy', 'cut'],
    version: '1.0',
    unicode: 'eaa1',
  },
  IconFileSignal: {
    category: 'Document',
    tags: ['file', 'signal'],
    version: '1.56',
  },
  IconFileSpreadsheet: {
    category: 'Document',
    tags: ['file', 'spreadsheet'],
    version: '1.56',
  },
  IconFileStar: {
    category: 'Document',
    tags: ['file', 'star'],
    version: '1.56',
  },
  IconFileSymlink: {
    category: 'Document',
    tags: [
      'file',
      'symlink',
      'text',
      'format',
      'extension',
      'document',
      'format',
    ],
    version: '1.30',
    unicode: 'ed53',
  },
  IconFileText: {
    category: 'Document',
    tags: ['file', 'text', 'data', 'pdf', 'txt'],
    version: '1.0',
    unicode: 'eaa2',
  },
  IconFileTime: {
    category: 'Document',
    tags: ['file', 'time'],
    version: '1.56',
  },
  IconFileTypography: {
    category: 'Document',
    tags: ['file', 'typography'],
    version: '1.56',
  },
  IconFileUnknown: {
    category: 'Document',
    tags: ['file', 'unknown'],
    version: '1.56',
  },
  IconFileUpload: {
    category: 'Document',
    tags: ['file', 'upload', 'save', 'transfer', 'input'],
    version: '1.15',
    unicode: 'ec91',
  },
  IconFileVector: {
    category: 'Document',
    tags: ['file', 'vector'],
    version: '1.56',
  },
  IconFileX: {
    category: 'Document',
    tags: ['file', 'x', 'remove', 'delete', 'erase'],
    version: '1.0',
    unicode: 'eaa3',
  },
  IconFileZip: {
    category: 'Document',
    tags: [
      'file',
      'zip',
      'forms',
      'documents',
      'stack',
      'letter',
      'archive',
      'rar',
      'zipped',
      'extention',
      'bundle',
      'format',
    ],
    version: '1.29',
    unicode: 'ed4e',
  },
  IconFile: {
    category: 'Document',
    tags: ['file', 'paper', 'new'],
    version: '1.0',
    unicode: 'eaa4',
  },
  IconFilesOff: {
    category: 'Document',
    tags: ['files', 'off', 'forms', 'documents', 'stack', 'letter'],
    version: '1.38',
    unicode: 'edee',
  },
  IconFiles: {
    category: 'Document',
    tags: ['files', 'forms', 'documents', 'stack', 'letter'],
    version: '1.38',
    unicode: 'edef',
  },
  IconFilterOff: {
    category: 'System',
    tags: ['filter', 'off', 'funnel', 'hopper', 'filtration'],
    version: '1.26',
    unicode: 'ed2c',
  },
  IconFilter: {
    category: 'System',
    tags: ['filter', 'funnel', 'hopper', 'filtration'],
    version: '1.0',
    unicode: 'eaa5',
  },
  IconFingerprint: {
    category: '',
    tags: ['fingerprint', 'indentify', 'mark', 'surface', 'security', 'access'],
    version: '1.6',
    unicode: 'ebd1',
  },
  IconFiretruck: {
    category: 'Vehicles',
    tags: [
      'firetruck',
      'help',
      'rescuer',
      'vehicle',
      'fireman',
      'extinguishing',
    ],
    version: '1.7',
    unicode: 'ebe8',
  },
  IconFirstAidKit: {
    category: 'Health',
    tags: ['first', 'aid', 'kit'],
    version: '1.44',
    unicode: 'ef5f',
  },
  IconFish: {
    category: 'Food',
    tags: ['fish'],
    version: '1.41',
    unicode: 'ef2b',
  },
  IconFlag2: {
    category: 'Map',
    tags: ['flag', '2', 'banner', 'pin', 'report', 'map', 'warning', 'alert'],
    version: '1.39',
    unicode: 'ee8c',
  },
  IconFlag3: {
    category: 'Map',
    tags: ['flag', '3', 'banner', 'pin', 'report', 'map', 'warning', 'alert'],
    version: '1.39',
    unicode: 'ee8d',
  },
  IconFlag: {
    category: 'Map',
    tags: ['flag', 'banner', 'pin', 'report', 'map', 'warning', 'alert'],
    version: '1.0',
    unicode: 'eaa6',
  },
  IconFlame: {
    category: '',
    tags: [
      'flame',
      'fire',
      'fireplace',
      'light',
      'burn',
      'bonfire',
      'smoke',
      'barbecue',
    ],
    version: '1.35',
    unicode: 'ec2c',
  },
  IconFlare: {
    category: 'Weather',
    tags: ['flare', 'shine', 'flare', 'heat', 'sunlight', 'hot', 'sun', 'heat'],
    version: '1.39',
    unicode: 'ee8e',
  },
  IconFlask2: {
    category: 'Health',
    tags: ['flask', '2'],
    version: '1.44',
    unicode: 'ef60',
  },
  IconFlask: {
    category: 'Health',
    tags: ['flask', 'liquid', 'container', 'glass', 'chemistry'],
    version: '1.6',
    unicode: 'ebd2',
  },
  IconFlipHorizontal: {
    category: 'Design',
    tags: ['flip', 'horizontal', 'mirror', 'rotate'],
    version: '1.0',
    unicode: 'eaa7',
  },
  IconFlipVertical: {
    category: 'Design',
    tags: ['flip', 'vertical', 'mirror', 'rotate'],
    version: '1.0',
    unicode: 'eaa8',
  },
  IconFloatCenter: {
    category: 'Text',
    tags: ['float', 'center', 'position'],
    version: '1.4',
    unicode: 'ebb1',
  },
  IconFloatLeft: {
    category: 'Text',
    tags: ['float', 'left', 'position'],
    version: '1.4',
    unicode: 'ebb2',
  },
  IconFloatNone: {
    category: 'Text',
    tags: ['float', 'none', 'position'],
    version: '1.24',
    unicode: 'ed13',
  },
  IconFloatRight: {
    category: 'Text',
    tags: ['float', 'right', 'position'],
    version: '1.4',
    unicode: 'ebb3',
  },
  IconFlower: {
    category: 'Nature',
    tags: ['flower'],
    version: '1.52',
    unicode: 'eff6',
  },
  IconFocus2: {
    category: 'Photography',
    tags: ['focus', '2', 'spotlight', 'attention', 'center', 'aim', 'target'],
    version: '1.6',
    unicode: 'ebd3',
  },
  IconFocusCentered: {
    category: '',
    tags: ['focus', 'centered'],
    version: '1.55',
    unicode: 'f02a',
  },
  IconFocus: {
    category: 'Photography',
    tags: ['focus', 'target', 'bullseye', 'aim'],
    version: '1.3',
    unicode: 'eb8d',
  },
  IconFoldDown: {
    category: 'Arrows',
    tags: ['fold', 'down', 'arrow', 'move', 'toggle'],
    version: '1.30',
    unicode: 'ed54',
  },
  IconFoldUp: {
    category: 'Arrows',
    tags: ['fold', 'up', 'arrow', 'move', 'toggle'],
    version: '1.30',
    unicode: 'ed55',
  },
  IconFold: {
    category: 'Arrows',
    tags: ['fold', 'arrow', 'move', 'merge'],
    version: '1.30',
    unicode: 'ed56',
  },
  IconFolderMinus: {
    category: 'Document',
    tags: ['folder', 'minus', 'directory', 'dir'],
    version: '1.0',
    unicode: 'eaaa',
  },
  IconFolderOff: {
    category: 'Document',
    tags: ['folder', 'off', 'cancel', 'no', 'directory', 'dir'],
    version: '1.24',
    unicode: 'ed14',
  },
  IconFolderPlus: {
    category: 'Document',
    tags: ['folder', 'plus', 'add', 'create', 'new', 'directory', 'dir'],
    version: '1.0',
    unicode: 'eaab',
  },
  IconFolderX: {
    category: 'Document',
    tags: ['folder', 'x', 'directory', 'dir'],
    version: '1.0',
    unicode: 'eaac',
  },
  IconFolder: {
    category: 'Document',
    tags: ['folder', 'cancel', 'no', 'directory', 'dir'],
    version: '1.0',
    unicode: 'eaad',
  },
  IconFolders: {
    category: 'Document',
    tags: ['folders', 'directory', 'dir', 'clone', 'copy'],
    version: '1.0',
    unicode: 'eaae',
  },
  IconForbid2: {
    category: '',
    tags: ['forbid', '2', 'access', 'restriction', 'ban', 'limit'],
    version: '1.6',
    unicode: 'ebd4',
  },
  IconForbid: {
    category: '',
    tags: ['forbid', 'access', 'restriction', 'ban', 'limit'],
    version: '1.6',
    unicode: 'ebd5',
  },
  IconForklift: {
    category: 'Vehicles',
    tags: ['forklift', 'store', 'warehouse', 'inventory', 'exporting'],
    version: '1.7',
    unicode: 'ebe9',
  },
  IconForms: {
    category: 'Text',
    tags: ['forms', 'formbuilder', 'input', 'url', 'textarea'],
    version: '1.39',
    unicode: 'ee8f',
  },
  IconFrame: {
    category: 'Design',
    tags: ['frame', 'crop'],
    version: '1.0',
    unicode: 'eaaf',
  },
  IconFreeRights: {
    category: '',
    tags: ['free', 'rights'],
    version: '1.49',
    unicode: 'efb6',
  },
  IconFriends: {
    category: '',
    tags: ['friends', 'people', 'boy', 'girl', 'man', 'woman'],
    version: '1.0',
    unicode: 'eab0',
  },
  IconGasStation: {
    category: 'Vehicles',
    tags: [
      'gas',
      'station',
      'fuel',
      'oil',
      'cars',
      'vehicles',
      'shop',
      'distributor',
    ],
    version: '1.14',
    unicode: 'ec7d',
  },
  IconGauge: {
    category: 'System',
    tags: ['gauge', 'car', 'dashboard'],
    version: '1.0',
    unicode: 'eab1',
  },
  IconGavel: {
    category: '',
    tags: ['gavel'],
    version: '1.47',
    unicode: 'ef90',
  },
  IconGeometry: {
    category: 'Map',
    tags: ['geometry', 'build', 'architecture', 'create', 'compass'],
    version: '1.39',
    unicode: 'ee90',
  },
  IconGhost: {
    category: '',
    tags: [
      'ghost',
      'spirit',
      'transparent',
      'fairytale',
      'horror',
      'movie',
      'shadow',
      'haunt',
    ],
    version: '1.3',
    unicode: 'eb8e',
  },
  IconGift: {
    category: '',
    tags: [
      'gift',
      'present',
      'birthday',
      'celebration',
      'wish',
      'bonus',
      'souvenire',
      'surprise',
    ],
    version: '1.2',
    unicode: 'eb68',
  },
  IconGitBranch: {
    category: '',
    tags: ['git', 'branch', 'code', 'version control', 'command'],
    version: '1.0',
    unicode: 'eab2',
  },
  IconGitCommit: {
    category: '',
    tags: ['git', 'commit', 'code', 'version control', 'command'],
    version: '1.0',
    unicode: 'eab3',
  },
  IconGitCompare: {
    category: '',
    tags: ['git', 'compare', 'code', 'version control', 'command'],
    version: '1.0',
    unicode: 'eab4',
  },
  IconGitFork: {
    category: '',
    tags: ['git', 'fork', 'code', 'version control', 'command'],
    version: '1.3',
    unicode: 'eb8f',
  },
  IconGitMerge: {
    category: '',
    tags: ['git', 'merge', 'code', 'version control', 'command'],
    version: '1.0',
    unicode: 'eab5',
  },
  IconGitPullRequestClosed: {
    category: '',
    tags: [
      'git',
      'pull',
      'request',
      'closed',
      'code',
      'version control',
      'command',
    ],
    version: '1.46',
    unicode: 'ef7f',
  },
  IconGitPullRequestDraft: {
    category: '',
    tags: ['git', 'pull', 'request', 'draft'],
    version: '1.49',
    unicode: 'efb7',
  },
  IconGitPullRequest: {
    category: '',
    tags: ['git', 'pull', 'request', 'code', 'version control', 'command'],
    version: '1.0',
    unicode: 'eab6',
  },
  IconGizmo: {
    category: '',
    tags: ['gizmo'],
    version: '1.55',
    unicode: 'f02b',
  },
  IconGlassFull: {
    category: 'Food',
    tags: ['glass', 'full', 'wine', 'cup', 'goblet'],
    version: '1.0',
    unicode: 'eab7',
  },
  IconGlassOff: {
    category: 'Food',
    tags: [
      'glass',
      'off',
      'drink',
      'alcohol',
      'not-allowed',
      'sober',
      'non-alcoholic',
      'wine',
    ],
    version: '1.39',
    unicode: 'ee91',
  },
  IconGlass: {
    category: 'Food',
    tags: ['glass', 'wine', 'cup', 'goblet'],
    version: '1.0',
    unicode: 'eab8',
  },
  IconGlobe: {
    category: 'Map',
    tags: ['globe', 'world', 'travel', 'journey', 'trip', 'planet', 'earth'],
    version: '1.1',
    unicode: 'eab9',
  },
  IconGolf: {
    category: 'Sport',
    tags: [
      'golf',
      'game',
      'ball',
      'play',
      'hole',
      'club-and-ball',
      'stroke',
      'luxury',
      'pitch',
    ],
    version: '1.34',
    unicode: 'ed8c',
  },
  IconGps: {
    category: 'Map',
    tags: [
      'gps',
      'navigation',
      'directions',
      'global',
      'positioning',
      'system',
      'satnav',
      'radionavigation',
      'system',
      'travel',
      'car',
    ],
    version: '1.33',
    unicode: 'ed7a',
  },
  IconGrain: {
    category: '',
    tags: ['grain', 'dots', 'pattern', 'random', 'round', 'circle', 'nodes'],
    version: '1.39',
    unicode: 'ee92',
  },
  IconGridDots: {
    category: 'System',
    tags: ['grid', 'dots', 'network', 'pattern', 'layout'],
    version: '1.0',
    unicode: 'eaba',
  },
  IconGridPattern: {
    category: '',
    tags: ['grid', 'pattern'],
    version: '1.50',
    unicode: 'efc9',
  },
  IconGrill: {
    category: 'Food',
    tags: ['grill'],
    version: '1.48',
    unicode: 'efa9',
  },
  IconGripHorizontal: {
    category: 'System',
    tags: [
      'grip',
      'horizontal',
      'picture',
      'abstract',
      'symbol',
      'design',
      'across',
    ],
    version: '1.8',
    unicode: 'ec00',
  },
  IconGripVertical: {
    category: 'System',
    tags: [
      'grip',
      'vertical',
      'picture',
      'abstract',
      'symbol',
      'design',
      'upright',
    ],
    version: '1.8',
    unicode: 'ec01',
  },
  IconGrowth: {
    category: 'Nature',
    tags: [
      'growth',
      'seed',
      'harvest',
      'plant',
      'tree',
      'flower',
      'grain',
      'greenery',
      'garden',
    ],
    version: '1.39',
    unicode: 'ee93',
  },
  IconH1: {
    category: 'Text',
    tags: ['h', '1', 'header', 'text', 'editor'],
    version: '1.16',
    unicode: 'ec94',
  },
  IconH2: {
    category: 'Text',
    tags: ['h', '2', 'header', 'text', 'editor'],
    version: '1.16',
    unicode: 'ec95',
  },
  IconH3: {
    category: 'Text',
    tags: ['h', '3', 'header', 'text', 'editor'],
    version: '1.16',
    unicode: 'ec96',
  },
  IconH4: {
    category: 'Text',
    tags: ['h', '4', 'header', 'text', 'editor'],
    version: '1.16',
    unicode: 'ec97',
  },
  IconH5: {
    category: 'Text',
    tags: ['h', '5', 'header', 'text', 'editor'],
    version: '1.16',
    unicode: 'ec98',
  },
  IconH6: {
    category: 'Text',
    tags: ['h', '6', 'header', 'text', 'editor'],
    version: '1.16',
    unicode: 'ec99',
  },
  IconHammer: {
    category: '',
    tags: ['hammer'],
    version: '1.47',
    unicode: 'ef91',
  },
  IconHandClick: {
    category: 'Gestures',
    tags: ['hand', 'click'],
    version: '1.43',
    unicode: 'ef4f',
  },
  IconHandFinger: {
    category: 'Gestures',
    tags: [
      'hand',
      'finger',
      'point',
      'show',
      'index',
      'forefinger',
      'body',
      'human',
      'palm',
    ],
    version: '1.39',
    unicode: 'ee94',
  },
  IconHandLittleFinger: {
    category: 'Gestures',
    tags: ['hand', 'little', 'finger', 'small', 'body', 'human', 'palm'],
    version: '1.39',
    unicode: 'ee95',
  },
  IconHandMiddleFinger: {
    category: 'Gestures',
    tags: [
      'hand',
      'middle',
      'finger',
      'signal',
      'gesture',
      'curse',
      'vulgarism',
      'abuse',
    ],
    version: '1.10',
    unicode: 'ec2d',
  },
  IconHandMove: {
    category: 'Gestures',
    tags: ['hand', 'move'],
    version: '1.43',
    unicode: 'ef50',
  },
  IconHandOff: {
    category: 'Gestures',
    tags: ['hand', 'off', 'disclaimer', 'body'],
    version: '1.24',
    unicode: 'ed15',
  },
  IconHandRingFinger: {
    category: 'Gestures',
    tags: ['hand', 'ring', 'finger', 'body', 'human', 'palm'],
    version: '1.39',
    unicode: 'ee96',
  },
  IconHandRock: {
    category: 'Gestures',
    tags: ['hand', 'rock', 'heavy', 'metal', 'party', 'concert', 'rebel'],
    version: '1.39',
    unicode: 'ee97',
  },
  IconHandStop: {
    category: 'Gestures',
    tags: ['hand', 'stop', 'forbiddance', 'nixing', 'ban', 'interdicting'],
    version: '1.10',
    unicode: 'ec2e',
  },
  IconHandThreeFingers: {
    category: 'Gestures',
    tags: ['hand', 'three', 'fingers', 'body', 'human', 'palm'],
    version: '1.39',
    unicode: 'ee98',
  },
  IconHandTwoFingers: {
    category: 'Gestures',
    tags: ['hand', 'two', 'fingers', 'body', 'human', 'palm', 'gesture'],
    version: '1.39',
    unicode: 'ee99',
  },
  IconHanger: {
    category: '',
    tags: [
      'hanger',
      'clothes',
      'wardrobe',
      'hook',
      'hang',
      'wooden',
      'plastic',
      'wire',
      'shop',
      'store',
      'clothing',
      'fashion',
    ],
    version: '1.39',
    unicode: 'ee9a',
  },
  IconHash: {
    category: '',
    tags: ['hash', 'hashtag', '#', 'instagram'],
    version: '1.0',
    unicode: 'eabc',
  },
  IconHaze: {
    category: 'Weather',
    tags: ['haze'],
    version: '1.48',
    unicode: 'efaa',
  },
  IconHeading: {
    category: 'Text',
    tags: ['heading', 'main', 'text', 'headline', 'style', 'styling', 'html'],
    version: '1.39',
    unicode: 'ee9b',
  },
  IconHeadphonesOff: {
    category: 'Media',
    tags: [
      'headphones',
      'off',
      'sound',
      'silence',
      'mute',
      'earphones',
      'electronics',
      'wireless',
      'listen',
    ],
    version: '1.25',
    unicode: 'ed1d',
  },
  IconHeadphones: {
    category: 'Media',
    tags: ['headphones', 'music', 'headset', 'audio', 'sound'],
    version: '1.0',
    unicode: 'eabd',
  },
  IconHeadset: {
    category: 'Media',
    tags: ['headset', 'music', 'headphones', 'audio', 'sound'],
    version: '1.3',
    unicode: 'eb90',
  },
  IconHeartBroken: {
    category: 'Health',
    tags: [
      'heart',
      'broken',
      'love',
      'emotion',
      'like',
      'favorite',
      'relationship',
    ],
    version: '1.18',
    unicode: 'ecba',
  },
  IconHeartRateMonitor: {
    category: 'Health',
    tags: ['heart', 'rate', 'monitor'],
    version: '1.44',
    unicode: 'ef61',
  },
  IconHeart: {
    category: 'Shapes',
    tags: ['heart', 'love', 'emotion', 'like', 'favorite', 'relationship'],
    version: '1.0',
    unicode: 'eabe',
  },
  IconHeartbeat: {
    category: 'Health',
    tags: ['heartbeat'],
    version: '1.47',
    unicode: 'ef92',
  },
  IconHelicopterLanding: {
    category: 'Vehicles',
    tags: [
      'helicopter',
      'landing',
      'pad',
      'helipad',
      'land',
      'takeoff',
      'navy',
      'travel',
      'aircraft',
      'platform',
      'fly',
    ],
    version: '1.34',
    unicode: 'ed8d',
  },
  IconHelicopter: {
    category: 'Vehicles',
    tags: [
      'helicopter',
      'land',
      'takeoff',
      'navy',
      'travel',
      'aircraft',
      'platform',
      'fly',
      'pilot',
      'journey',
      'rotorcraft',
      'hover',
    ],
    version: '1.34',
    unicode: 'ed8e',
  },
  IconHelmet: {
    category: 'Sport',
    tags: ['helmet'],
    version: '1.50',
    unicode: 'efca',
  },
  IconHelp: {
    category: '',
    tags: ['help', 'tooltip', 'assistance', 'advice', 'support'],
    version: '1.1',
    unicode: 'eabf',
  },
  IconHexagonOff: {
    category: 'Shapes',
    tags: ['hexagon', 'off', 'six', 'angle', 'shape', 'crossed'],
    version: '1.39',
    unicode: 'ee9c',
  },
  IconHexagon: {
    category: 'Shapes',
    tags: ['hexagon', 'shape', 'geometric', 'math', '2D'],
    version: '1.8',
    unicode: 'ec02',
  },
  IconHierarchy2: {
    category: 'Design',
    tags: [
      'hierarchy',
      '2',
      'relation',
      'above',
      'below',
      'status',
      'society',
      'important',
    ],
    version: '1.39',
    unicode: 'ee9d',
  },
  IconHierarchy: {
    category: 'Design',
    tags: [
      'hierarchy',
      'relation',
      'above',
      'below',
      'status',
      'society',
      'important',
    ],
    version: '1.39',
    unicode: 'ee9e',
  },
  IconHighlight: {
    category: 'Text',
    tags: ['highlight'],
    version: '1.42',
    unicode: 'ef3f',
  },
  IconHistory: {
    category: 'System',
    tags: ['history', 'search', 'see', 'past', 'card', 'website'],
    version: '1.7',
    unicode: 'ebea',
  },
  IconHome2: {
    category: 'Buildings',
    tags: ['home', '2', 'house', 'dashboard', 'living', 'building'],
    version: '1.1',
    unicode: 'eac0',
  },
  IconHome: {
    category: 'Buildings',
    tags: ['home', 'house', 'dashboard', 'living', 'building'],
    version: '1.0',
    unicode: 'eac1',
  },
  IconHotelService: {
    category: '',
    tags: ['hotel', 'service'],
    version: '1.46',
    unicode: 'ef80',
  },
  IconHourglass: {
    category: '',
    tags: ['hourglass'],
    version: '1.47',
    unicode: 'ef93',
  },
  IconIceCream2: {
    category: 'Food',
    tags: [
      'ice',
      'cream',
      '2',
      'sweet',
      'cold',
      'dessert',
      'food',
      'taste',
      'frozen',
      'snack',
      'flavour',
      'flavor',
      'cone',
    ],
    version: '1.39',
    unicode: 'ee9f',
  },
  IconIceCream: {
    category: 'Food',
    tags: ['ice', 'cream', 'candy', 'dessert', 'frozen', 'sweet'],
    version: '1.0',
    unicode: 'eac2',
  },
  IconIceSkating: {
    category: 'Sport',
    tags: ['ice', 'skating'],
    version: '1.50',
    unicode: 'efcb',
  },
  IconIdBadge: {
    category: '',
    tags: ['id', 'badge'],
    version: '1.52',
    unicode: 'eff7',
  },
  IconId: {
    category: '',
    tags: ['id', 'identification', 'card', 'personal details'],
    version: '1.1',
    unicode: 'eac3',
  },
  IconInbox: {
    category: '',
    tags: ['inbox', 'mail', 'gmail', 'email', 'envelope', 'post'],
    version: '1.0',
    unicode: 'eac4',
  },
  IconIndentDecrease: {
    category: 'Text',
    tags: [
      'indent',
      'decrease',
      'line',
      'position',
      'block',
      'margin',
      'paragraph',
    ],
    version: '1.3',
    unicode: 'eb91',
  },
  IconIndentIncrease: {
    category: 'Text',
    tags: [
      'indent',
      'increase',
      'line',
      'position',
      'block',
      'margin',
      'paragraph',
    ],
    version: '1.3',
    unicode: 'eb92',
  },
  IconInfinity: {
    category: 'Math',
    tags: ['infinity', 'endless', 'eternity', 'continuum', 'time'],
    version: '1.2',
    unicode: 'eb69',
  },
  IconInfoCircle: {
    category: '',
    tags: ['info', 'circle', 'information', 'advice', 'news', 'tip', 'sign'],
    version: '1.0',
    unicode: 'eac5',
  },
  IconInfoSquare: {
    category: '',
    tags: ['info', 'square', 'information', 'advice', 'news', 'tip', 'sign'],
    version: '1.0',
    unicode: 'eac6',
  },
  IconItalic: {
    category: 'Text',
    tags: ['italic', 'typography', 'font', 'typeface', 'emphasise'],
    version: '1.3',
    unicode: 'eb93',
  },
  IconJumpRope: {
    category: 'Sport',
    tags: [
      'jump',
      'rope',
      'sport',
      'fitness',
      'workout',
      'gym',
      'skipping',
      'cardio',
      'fit',
      'shape',
    ],
    version: '1.34',
    unicode: 'ed8f',
  },
  IconKarate: {
    category: 'Sport',
    tags: [
      'karate',
      'martial',
      'art',
      'self',
      'defence',
      'defend',
      'strike',
      'compat',
      'oriental',
      'fight',
      'kick',
    ],
    version: '1.27',
    unicode: 'ed32',
  },
  IconKering: {
    category: 'Text',
    tags: ['kering'],
    version: '1.49',
    unicode: 'efb8',
  },
  IconKey: {
    category: '',
    tags: ['key', 'password', 'login', 'authentication', 'secure'],
    version: '1.0',
    unicode: 'eac7',
  },
  IconKeyboardHide: {
    category: 'Devices',
    tags: ['keyboard', 'hide', 'computer', 'laptop', 'device', 'type'],
    version: '1.14',
    unicode: 'ec7e',
  },
  IconKeyboardOff: {
    category: 'Devices',
    tags: ['keyboard', 'off', 'device', 'type', 'disconnect', 'unplug'],
    version: '1.39',
    unicode: 'eea0',
  },
  IconKeyboardShow: {
    category: 'Devices',
    tags: ['keyboard', 'show', 'computer', 'laptop', 'device', 'type'],
    version: '1.14',
    unicode: 'ec7f',
  },
  IconKeyboard: {
    category: 'Devices',
    tags: ['keyboard', 'computer', 'laptop', 'device', 'type'],
    version: '1.6',
    unicode: 'ebd6',
  },
  IconLadder: {
    category: '',
    tags: ['ladder'],
    version: '1.51',
    unicode: 'efe2',
  },
  IconLamp: {
    category: '',
    tags: ['lamp'],
    version: '1.48',
    unicode: 'efab',
  },
  IconLanguageHiragana: {
    category: 'Text',
    tags: [
      'language',
      'hiragana',
      'tongue',
      'country',
      'speech',
      'speak',
      'translate',
      'communication',
      'communicate',
      'english',
      'dialect',
      'dictionary',
      'word',
    ],
    version: '1.45',
    unicode: 'ef77',
  },
  IconLanguageKatakana: {
    category: 'Text',
    tags: [
      'language',
      'katakana',
      'tongue',
      'country',
      'speech',
      'speak',
      'translate',
      'communication',
      'communicate',
      'english',
      'dialect',
      'dictionary',
      'word',
    ],
    version: '1.45',
    unicode: 'ef78',
  },
  IconLanguage: {
    category: 'Text',
    tags: [
      'language',
      'tongue',
      'country',
      'speech',
      'speak',
      'translate',
      'communication',
      'communicate',
      'english',
      'dialect',
      'dictionary',
      'word',
    ],
    version: '1.5',
    unicode: 'ebbe',
  },
  IconLasso: {
    category: 'Design',
    tags: ['lasso'],
    version: '1.48',
    unicode: 'efac',
  },
  IconLayersDifference: {
    category: 'Design',
    tags: ['layers', 'difference', 'stack'],
    version: '1.0',
    unicode: 'eac8',
  },
  IconLayersIntersect2: {
    category: 'Design',
    tags: ['layers', 'intersect', '2'],
    version: '1.52',
    unicode: 'eff8',
  },
  IconLayersIntersect: {
    category: 'Design',
    tags: ['layers', 'intersect', 'stack'],
    version: '1.0',
    unicode: 'eac9',
  },
  IconLayersLinked: {
    category: 'Design',
    tags: ['layers', 'linked', 'data', 'network'],
    version: '1.39',
    unicode: 'eea1',
  },
  IconLayersSubtract: {
    category: 'Design',
    tags: ['layers', 'subtract', 'stack'],
    version: '1.0',
    unicode: 'eaca',
  },
  IconLayersUnion: {
    category: 'Design',
    tags: ['layers', 'union', 'stack', 'join'],
    version: '1.0',
    unicode: 'eacb',
  },
  IconLayout2: {
    category: 'Design',
    tags: ['layout', '2', 'grid', 'columns', 'masonry', 'collage'],
    version: '1.0',
    unicode: 'eacc',
  },
  IconLayoutAlignBottom: {
    category: 'Design',
    tags: ['layout', 'align', 'bottom', 'position', 'element', 'design'],
    version: '1.0',
    unicode: 'eacd',
  },
  IconLayoutAlignCenter: {
    category: 'Design',
    tags: ['layout', 'align', 'center', 'position', 'element', 'design'],
    version: '1.0',
    unicode: 'eace',
  },
  IconLayoutAlignLeft: {
    category: 'Design',
    tags: ['layout', 'align', 'left', 'position', 'element', 'design'],
    version: '1.0',
    unicode: 'eacf',
  },
  IconLayoutAlignMiddle: {
    category: 'Design',
    tags: ['layout', 'align', 'middle', 'position', 'element', 'design'],
    version: '1.0',
    unicode: 'ead0',
  },
  IconLayoutAlignRight: {
    category: 'Design',
    tags: ['layout', 'align', 'right', 'position', 'element', 'design'],
    version: '1.0',
    unicode: 'ead1',
  },
  IconLayoutAlignTop: {
    category: 'Design',
    tags: ['layout', 'align', 'top', 'position', 'element', 'design'],
    version: '1.0',
    unicode: 'ead2',
  },
  IconLayoutBoardSplit: {
    category: 'Design',
    tags: ['layout', 'board', 'split', 'grid', 'columns', 'masonry', 'collage'],
    version: '1.47',
    unicode: 'ef94',
  },
  IconLayoutBoard: {
    category: 'Design',
    tags: ['layout', 'board', 'grid', 'columns', 'masonry', 'collage'],
    version: '1.47',
    unicode: 'ef95',
  },
  IconLayoutBottombar: {
    category: 'Design',
    tags: [
      'layout',
      'bottombar',
      'position',
      'element',
      'design',
      'grid',
      'footer',
    ],
    version: '1.1',
    unicode: 'ead3',
  },
  IconLayoutCards: {
    category: 'Design',
    tags: ['layout', 'cards', 'position', 'element', 'design', 'arrangement'],
    version: '1.8',
    unicode: 'ec13',
  },
  IconLayoutColumns: {
    category: 'Design',
    tags: ['layout', 'columns', 'grid', 'column', 'rows'],
    version: '1.0',
    unicode: 'ead4',
  },
  IconLayoutDashboard: {
    category: 'Design',
    tags: ['layout', 'dashboard'],
    version: '1.55',
    unicode: 'f02c',
  },
  IconLayoutDistributeHorizontal: {
    category: 'Design',
    tags: [
      'layout',
      'distribute',
      'horizontal',
      'position',
      'element',
      'design',
      'around',
    ],
    version: '1.0',
    unicode: 'ead5',
  },
  IconLayoutDistributeVertical: {
    category: 'Design',
    tags: [
      'layout',
      'distribute',
      'vertical',
      'position',
      'element',
      'design',
      'around',
    ],
    version: '1.0',
    unicode: 'ead6',
  },
  IconLayoutGridAdd: {
    category: 'Design',
    tags: ['layout', 'grid', 'add', 'layout', 'table', 'network', 'pattern'],
    version: '1.0',
    unicode: 'edb9',
  },
  IconLayoutGrid: {
    category: 'Design',
    tags: ['layout', 'grid', 'layout', 'table', 'network', 'pattern'],
    version: '1.0',
    unicode: 'edba',
  },
  IconLayoutKanban: {
    category: 'Design',
    tags: [
      'layout',
      'kanban',
      'position',
      'element',
      'design',
      'board',
      'processing',
      'task',
    ],
    version: '1.11',
    unicode: 'ec3f',
  },
  IconLayoutList: {
    category: 'Design',
    tags: ['layout', 'list', 'position', 'design', 'element', 'doable'],
    version: '1.8',
    unicode: 'ec14',
  },
  IconLayoutNavbar: {
    category: 'Design',
    tags: ['layout', 'navbar', 'grid', 'row', 'header'],
    version: '1.0',
    unicode: 'ead7',
  },
  IconLayoutRows: {
    category: 'Design',
    tags: ['layout', 'rows', 'grid', 'masonry', 'collage'],
    version: '1.0',
    unicode: 'ead8',
  },
  IconLayoutSidebarLeftCollapse: {
    category: '',
    tags: ['layout', 'sidebar', 'left', 'collapse'],
    version: '1.53',
    unicode: 'f004',
  },
  IconLayoutSidebarLeftExpand: {
    category: '',
    tags: ['layout', 'sidebar', 'left', 'expand'],
    version: '1.53',
    unicode: 'f005',
  },
  IconLayoutSidebarRightCollapse: {
    category: '',
    tags: ['layout', 'sidebar', 'right', 'collapse'],
    version: '1.53',
    unicode: 'f006',
  },
  IconLayoutSidebarRightExpand: {
    category: '',
    tags: ['layout', 'sidebar', 'right', 'expand'],
    version: '1.53',
    unicode: 'f007',
  },
  IconLayoutSidebarRight: {
    category: 'Design',
    tags: ['layout', 'sidebar', 'right', 'grid', 'aside', 'column', 'columns'],
    version: '1.0',
    unicode: 'ead9',
  },
  IconLayoutSidebar: {
    category: 'Design',
    tags: ['layout', 'sidebar', 'grid', 'aside', 'column', 'columns'],
    version: '1.0',
    unicode: 'eada',
  },
  IconLayout: {
    category: 'Design',
    tags: ['layout', 'grid', 'columns', 'masonry', 'collage'],
    version: '1.0',
    unicode: 'eadb',
  },
  IconLeaf: {
    category: 'Nature',
    tags: [
      'leaf',
      'nature',
      'plant',
      'tree',
      'autumn',
      'fall',
      'greenery',
      'flower',
      'forest',
      'garden',
    ],
    version: '1.29',
    unicode: 'ed4f',
  },
  IconLego: {
    category: '',
    tags: ['lego', 'toy', 'boy', 'face', 'play'],
    version: '1.0',
    unicode: 'eadc',
  },
  IconLemon2: {
    category: 'Food',
    tags: ['lemon', '2'],
    version: '1.46',
    unicode: 'ef81',
  },
  IconLemon: {
    category: 'Food',
    tags: ['lemon'],
    version: '1.40',
    unicode: 'ef10',
  },
  IconLetterA: {
    category: 'Letters',
    tags: ['letter', 'a', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec50',
  },
  IconLetterB: {
    category: 'Letters',
    tags: ['letter', 'b', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec51',
  },
  IconLetterC: {
    category: 'Letters',
    tags: ['letter', 'c', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec52',
  },
  IconLetterCaseLower: {
    category: 'Text',
    tags: [
      'letter',
      'case',
      'lower',
      'typography',
      'font',
      'text',
      'style',
      'content',
    ],
    version: '1.39',
    unicode: 'eea2',
  },
  IconLetterCaseToggle: {
    category: 'Text',
    tags: [
      'letter',
      'case',
      'toggle',
      'typography',
      'font',
      'text',
      'style',
      'content',
    ],
    version: '1.39',
    unicode: 'eea3',
  },
  IconLetterCaseUpper: {
    category: 'Text',
    tags: [
      'letter',
      'case',
      'upper',
      'typography',
      'font',
      'text',
      'style',
      'content',
    ],
    version: '1.39',
    unicode: 'eea4',
  },
  IconLetterCase: {
    category: 'Text',
    tags: ['letter', 'case', 'typography', 'font', 'text', 'style', 'content'],
    version: '1.39',
    unicode: 'eea5',
  },
  IconLetterD: {
    category: 'Letters',
    tags: ['letter', 'd', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec53',
  },
  IconLetterE: {
    category: 'Letters',
    tags: ['letter', 'e', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec54',
  },
  IconLetterF: {
    category: 'Letters',
    tags: ['letter', 'f', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec55',
  },
  IconLetterG: {
    category: 'Letters',
    tags: ['letter', 'g', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec56',
  },
  IconLetterH: {
    category: 'Letters',
    tags: ['letter', 'h', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec57',
  },
  IconLetterI: {
    category: 'Letters',
    tags: ['letter', 'i', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec58',
  },
  IconLetterJ: {
    category: 'Letters',
    tags: ['letter', 'j', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec59',
  },
  IconLetterK: {
    category: 'Letters',
    tags: ['letter', 'k', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec5a',
  },
  IconLetterL: {
    category: 'Letters',
    tags: ['letter', 'l', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec5b',
  },
  IconLetterM: {
    category: 'Letters',
    tags: ['letter', 'm', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec5c',
  },
  IconLetterN: {
    category: 'Letters',
    tags: ['letter', 'n', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec5d',
  },
  IconLetterO: {
    category: 'Letters',
    tags: ['letter', 'o', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec5e',
  },
  IconLetterP: {
    category: 'Letters',
    tags: ['letter', 'p', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec5f',
  },
  IconLetterQ: {
    category: 'Letters',
    tags: ['letter', 'q', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec60',
  },
  IconLetterR: {
    category: 'Letters',
    tags: ['letter', 'r', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec61',
  },
  IconLetterS: {
    category: 'Letters',
    tags: ['letter', 's', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec62',
  },
  IconLetterSpacing: {
    category: 'Text',
    tags: [
      'letter',
      'spacing',
      'typography',
      'font',
      'space',
      'character',
      'word',
    ],
    version: '1.39',
    unicode: 'eea6',
  },
  IconLetterT: {
    category: 'Letters',
    tags: ['letter', 't', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec63',
  },
  IconLetterU: {
    category: 'Letters',
    tags: ['letter', 'u', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec64',
  },
  IconLetterV: {
    category: 'Letters',
    tags: ['letter', 'v', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec65',
  },
  IconLetterW: {
    category: 'Letters',
    tags: ['letter', 'w', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec66',
  },
  IconLetterX: {
    category: 'Letters',
    tags: ['letter', 'x', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec67',
  },
  IconLetterY: {
    category: 'Letters',
    tags: ['letter', 'y', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec68',
  },
  IconLetterZ: {
    category: 'Letters',
    tags: ['letter', 'z', 'alphabet', 'symbol', 'text', 'code'],
    version: '1.12',
    unicode: 'ec69',
  },
  IconLicense: {
    category: '',
    tags: [
      'license',
      'authorisation',
      'permission',
      'consent',
      'permit',
      'document',
      'agree',
    ],
    version: '1.5',
    unicode: 'ebc0',
  },
  IconLifebuoy: {
    category: '',
    tags: ['lifebuoy', 'life ring', 'help', 'support'],
    version: '1.0',
    unicode: 'eadd',
  },
  IconLineDashed: {
    category: '',
    tags: ['line', 'dashed', 'geometric', 'segment', 'link', 'connection'],
    version: '1.39',
    unicode: 'eea7',
  },
  IconLineDotted: {
    category: '',
    tags: ['line', 'dotted', 'geometric', 'segment', 'link', 'connection'],
    version: '1.39',
    unicode: 'eea8',
  },
  IconLineHeight: {
    category: 'Text',
    tags: ['line', 'height', 'space', 'inline', 'display'],
    version: '1.3',
    unicode: 'eb94',
  },
  IconLine: {
    category: 'Design',
    tags: ['line', 'geometric', 'segment', 'link', 'connection'],
    version: '1.11',
    unicode: 'ec40',
  },
  IconLink: {
    category: 'Text',
    tags: ['link', 'chain', 'url', 'connection', 'address'],
    version: '1.0',
    unicode: 'eade',
  },
  IconListCheck: {
    category: 'Text',
    tags: [
      'list',
      'check',
      'to-do',
      'checklist',
      'form',
      'template',
      'task',
      'reminder',
      'schedule',
      'agenda',
    ],
    version: '1.2',
    unicode: 'eb6a',
  },
  IconListDetails: {
    category: 'Text',
    tags: [
      'list',
      'details',
      'to-do',
      'checklist',
      'form',
      'template',
      'task',
      'reminder',
      'schedule',
      'agenda',
    ],
    version: '1.42',
    unicode: 'ef40',
  },
  IconListNumbers: {
    category: 'Text',
    tags: [
      'list',
      'numbers',
      'to-do',
      'checklist',
      'form',
      'template',
      'task',
      'reminder',
      'schedule',
      'agenda',
    ],
    version: '1.40',
    unicode: 'ef11',
  },
  IconListSearch: {
    category: 'Text',
    tags: ['list', 'search', 'find', 'agenda', 'shopping'],
    version: '1.39',
    unicode: 'eea9',
  },
  IconList: {
    category: 'Text',
    tags: ['list', 'task', 'unordered', 'bullets', 'agenda', 'shopping'],
    version: '1.2',
    unicode: 'eb6b',
  },
  IconLivePhoto: {
    category: 'Photography',
    tags: [
      'live',
      'photo',
      'capture',
      'photo',
      'movement',
      'sound',
      'memory',
      'image',
      'camera',
    ],
    version: '1.1',
    unicode: 'eadf',
  },
  IconLiveView: {
    category: 'Map',
    tags: ['live', 'view', 'camera', 'preview', 'image', 'photo'],
    version: '1.12',
    unicode: 'ec6b',
  },
  IconLoaderQuarter: {
    category: 'System',
    tags: ['loader', 'quarter', 'process', 'download', 'upload'],
    version: '1.17',
    unicode: 'eca2',
  },
  IconLoader: {
    category: 'System',
    tags: ['loader', 'process', 'download', 'upload'],
    version: '1.17',
    unicode: 'eca3',
  },
  IconLocation: {
    category: 'Map',
    tags: ['location', 'navigation', 'map', 'direction', 'discover', 'travel'],
    version: '1.0',
    unicode: 'eae0',
  },
  IconLockAccess: {
    category: '',
    tags: [
      'lock',
      'access',
      'block',
      'limited',
      'restricted',
      'unavailable',
      'confidential',
    ],
    version: '1.39',
    unicode: 'eeaa',
  },
  IconLockOff: {
    category: 'System',
    tags: ['lock', 'off', 'security', 'password', 'secure'],
    version: '1.25',
    unicode: 'ed1e',
  },
  IconLockOpen: {
    category: 'System',
    tags: ['lock', 'open', 'security', 'password', 'secure', 'unprotected'],
    version: '1.0',
    unicode: 'eae1',
  },
  IconLockSquare: {
    category: '',
    tags: ['lock', 'square'],
    version: '1.43',
    unicode: 'ef51',
  },
  IconLock: {
    category: 'System',
    tags: ['lock', 'security', 'password', 'secure'],
    version: '1.0',
    unicode: 'eae2',
  },
  IconLogin: {
    category: 'Arrows',
    tags: ['login', 'initialize', 'password', 'enter', 'account', 'permission'],
    version: '1.4',
    unicode: 'eba7',
  },
  IconLogout: {
    category: 'Arrows',
    tags: ['logout', 'exit', 'shut', 'unplug', 'close'],
    version: '1.4',
    unicode: 'eba8',
  },
  IconLollipop: {
    category: '',
    tags: ['lollipop'],
    version: '1.50',
    unicode: 'efcc',
  },
  IconLuggage: {
    category: '',
    tags: ['luggage'],
    version: '1.48',
    unicode: 'efad',
  },
  IconLungs: {
    category: 'Health',
    tags: ['lungs'],
    version: '1.44',
    unicode: 'ef62',
  },
  IconMacro: {
    category: 'Photography',
    tags: ['macro', 'video', 'photography', 'photo', 'camera'],
    version: '1.39',
    unicode: 'eeab',
  },
  IconMagnet: {
    category: '',
    tags: ['magnet', 'magnetic field', 'pole', 'iron', 'attract'],
    version: '1.0',
    unicode: 'eae3',
  },
  IconMailForward: {
    category: 'Communication',
    tags: ['mail', 'forward', 'send', 'recipient', 'email', 'inbox', 'message'],
    version: '1.39',
    unicode: 'eeac',
  },
  IconMailOpened: {
    category: 'Communication',
    tags: [
      'mail',
      'opened',
      'inbox',
      'gmail',
      'email',
      'envelope',
      'message',
      'read',
    ],
    version: '1.0',
    unicode: 'eae4',
  },
  IconMail: {
    category: 'Communication',
    tags: ['mail', 'inbox', 'gmail', 'email', 'envelope', 'message'],
    version: '1.0',
    unicode: 'eae5',
  },
  IconMailbox: {
    category: '',
    tags: [
      'mailbox',
      'send',
      'recipient',
      'email',
      'inbox',
      'message',
      'reply',
      'post',
    ],
    version: '1.39',
    unicode: 'eead',
  },
  IconMan: {
    category: '',
    tags: ['man', 'guy', 'boy', 'male', 'gender'],
    version: '1.0',
    unicode: 'eae6',
  },
  IconManualGearbox: {
    category: '',
    tags: [
      'manual',
      'gearbox',
      'car',
      'vehicle',
      'torque',
      'transmission',
      'mechanics',
      'motor',
      'engine',
    ],
    version: '1.33',
    unicode: 'ed7b',
  },
  IconMap2: {
    category: 'Map',
    tags: [
      'map',
      '2',
      'navigation',
      'location',
      'travel',
      'pin',
      'position',
      'marker',
    ],
    version: '1.0',
    unicode: 'eae7',
  },
  IconMapPinOff: {
    category: 'Map',
    tags: [
      'map',
      'pin',
      'off',
      'navigation',
      'location',
      'travel',
      'pin',
      'position',
      'marker',
    ],
    version: '1.22',
    unicode: 'ecf3',
  },
  IconMapPin: {
    category: 'Map',
    tags: [
      'map',
      'pin',
      'navigation',
      'location',
      'travel',
      'pin',
      'position',
      'marker',
    ],
    version: '1.0',
    unicode: 'eae8',
  },
  IconMapPins: {
    category: 'Map',
    tags: [
      'map',
      'pins',
      'place',
      'direction',
      'travel',
      'destination',
      'mark',
      'location',
      'address',
    ],
    version: '1.31',
    unicode: 'ed5e',
  },
  IconMapSearch: {
    category: '',
    tags: ['map', 'search'],
    version: '1.46',
    unicode: 'ef82',
  },
  IconMap: {
    category: 'Map',
    tags: ['map', 'navigation', 'location', 'travel'],
    version: '1.0',
    unicode: 'eae9',
  },
  IconMarkdown: {
    category: 'Text',
    tags: ['markdown', 'price', 'valuation', 'cost', 'exchange'],
    version: '1.11',
    unicode: 'ec41',
  },
  IconMarquee2: {
    category: '',
    tags: [
      'marquee',
      '2',
      'tag',
      'tracer',
      'html',
      'animation',
      'text',
      'graphic',
    ],
    version: '1.39',
    unicode: 'eeae',
  },
  IconMarquee: {
    category: '',
    tags: ['marquee', 'tag', 'tracer', 'html', 'animation', 'text', 'graphic'],
    version: '1.13',
    unicode: 'ec77',
  },
  IconMars: {
    category: 'Symbols',
    tags: ['mars', 'male'],
    version: '1.14',
    unicode: 'ec80',
  },
  IconMaskOff: {
    category: 'Design',
    tags: ['mask', 'off', 'edit', 'layer', 'mask', 'tool', 'design'],
    version: '1.39',
    unicode: 'eeaf',
  },
  IconMask: {
    category: 'Design',
    tags: ['mask', 'edit', 'layer', 'mask', 'tool', 'design'],
    version: '1.39',
    unicode: 'eeb0',
  },
  IconMassage: {
    category: 'Health',
    tags: [
      'massage',
      'physiotherapy',
      'spa',
      'relax',
      'sports',
      'therapy',
      'treatment',
      'spine',
    ],
    version: '1.39',
    unicode: 'eeb1',
  },
  IconMathFunction: {
    category: 'Math',
    tags: ['math', 'function', 'sdfsfg'],
    version: '1.39',
    unicode: 'eeb2',
  },
  IconMathSymbols: {
    category: 'Math',
    tags: [
      'math',
      'symbols',
      'calculator',
      'equal',
      'plus',
      'multiplication',
      'minus',
      'math',
    ],
    version: '1.39',
    unicode: 'eeb3',
  },
  IconMath: {
    category: 'Math',
    tags: ['math', 'subject', 'count', 'plus', 'minus', 'times'],
    version: '1.7',
    unicode: 'ebeb',
  },
  IconMaximize: {
    category: '',
    tags: ['maximize', 'fullscreen'],
    version: '1.0',
    unicode: 'eaea',
  },
  IconMeat: {
    category: 'Food',
    tags: ['meat'],
    version: '1.40',
    unicode: 'ef12',
  },
  IconMedal2: {
    category: '',
    tags: ['medal', '2', 'decorate', 'uniform'],
    version: '1.50',
    unicode: 'efcd',
  },
  IconMedal: {
    category: '',
    tags: ['medal', 'decorate', 'uniform'],
    version: '1.13',
    unicode: 'ec78',
  },
  IconMedicalCross: {
    category: 'Map',
    tags: ['medical', 'cross', 'sign', 'hospital', 'help', 'indication'],
    version: '1.10',
    unicode: 'ec2f',
  },
  IconMedicineSyrup: {
    category: 'Health',
    tags: ['medicine', 'syrup'],
    version: '1.44',
    unicode: 'ef63',
  },
  IconMenu2: {
    category: 'System',
    tags: ['menu', '2', 'bars', 'hamburger', 'navigation', 'burger'],
    version: '1.11',
    unicode: 'ec42',
  },
  IconMenu: {
    category: 'System',
    tags: ['menu', 'bars', 'hamburger', 'navigation', 'burger'],
    version: '1.0',
    unicode: 'eaeb',
  },
  IconMessage2Code: {
    category: '',
    tags: ['message', '2', 'code'],
    version: '1.54',
    unicode: 'f012',
  },
  IconMessage2: {
    category: 'Communication',
    tags: ['message', '2', 'comment', 'chat', 'reply'],
    version: '1.0',
    unicode: 'eaec',
  },
  IconMessageCircle2: {
    category: 'Communication',
    tags: ['message', 'circle', '2', 'comment', 'chat', 'reply'],
    version: '1.28',
    unicode: 'ed3f',
  },
  IconMessageCircleOff: {
    category: 'Communication',
    tags: ['message', 'circle', 'off', 'comment', 'chat', 'reply'],
    version: '1.28',
    unicode: 'ed40',
  },
  IconMessageCircle: {
    category: 'Communication',
    tags: ['message', 'circle', 'comment', 'chat', 'reply'],
    version: '1.0',
    unicode: 'eaed',
  },
  IconMessageCode: {
    category: '',
    tags: ['message', 'code'],
    version: '1.54',
    unicode: 'f013',
  },
  IconMessageDots: {
    category: 'Communication',
    tags: ['message', 'dots', 'comment', 'chat', 'reply'],
    version: '1.0',
    unicode: 'eaee',
  },
  IconMessageLanguage: {
    category: '',
    tags: ['message', 'language'],
    version: '1.48',
    unicode: 'efae',
  },
  IconMessageOff: {
    category: 'Communication',
    tags: ['message', 'off', 'comment', 'chat', 'reply'],
    version: '1.28',
    unicode: 'ed41',
  },
  IconMessagePlus: {
    category: 'Communication',
    tags: [
      'message',
      'plus',
      'comment',
      'chat',
      'reply',
      'communication',
      'conversation',
    ],
    version: '1.16',
    unicode: 'ec9a',
  },
  IconMessageReport: {
    category: 'Communication',
    tags: [
      'message',
      'report',
      'comment',
      'chat',
      'reply',
      'communication',
      'conversation',
    ],
    version: '1.16',
    unicode: 'ec9b',
  },
  IconMessage: {
    category: 'Communication',
    tags: [
      'message',
      'comment',
      'chat',
      'reply',
      'communication',
      'conversation',
    ],
    version: '1.0',
    unicode: 'eaef',
  },
  IconMessagesOff: {
    category: 'Communication',
    tags: [
      'messages',
      'off',
      'chat',
      'reply',
      'comment',
      'conversation',
      'communication',
    ],
    version: '1.28',
    unicode: 'ed42',
  },
  IconMessages: {
    category: 'Communication',
    tags: [
      'messages',
      'chat',
      'reply',
      'comment',
      'conversation',
      'communication',
    ],
    version: '1.2',
    unicode: 'eb6c',
  },
  IconMicrophone2: {
    category: 'Media',
    tags: ['microphone', '2'],
    version: '1.41',
    unicode: 'ef2c',
  },
  IconMicrophoneOff: {
    category: 'Media',
    tags: ['microphone', 'off', 'record', 'sound', 'listen', 'blocked', 'mute'],
    version: '1.24',
    unicode: 'ed16',
  },
  IconMicrophone: {
    category: 'Media',
    tags: ['microphone', 'record', 'sound', 'listen'],
    version: '1.0',
    unicode: 'eaf0',
  },
  IconMicroscope: {
    category: 'Health',
    tags: ['microscope'],
    version: '1.44',
    unicode: 'ef64',
  },
  IconMiliratyAward: {
    category: '',
    tags: ['miliraty', 'award'],
    version: '1.50',
    unicode: 'efce',
  },
  IconMilitaryRank: {
    category: '',
    tags: ['military', 'rank'],
    version: '1.50',
    unicode: 'efcf',
  },
  IconMilk: {
    category: 'Food',
    tags: ['milk'],
    version: '1.40',
    unicode: 'ef13',
  },
  IconMinimize: {
    category: '',
    tags: ['minimize', 'exit', 'close'],
    version: '1.0',
    unicode: 'eaf1',
  },
  IconMinusVertical: {
    category: '',
    tags: ['minus', 'vertical', 'subtract', 'less', 'divide'],
    version: '1.39',
    unicode: 'eeb4',
  },
  IconMinus: {
    category: 'Math',
    tags: ['minus', 'subtract', 'less'],
    version: '1.0',
    unicode: 'eaf2',
  },
  IconMist: {
    category: 'Weather',
    tags: ['mist', 'weather', 'visibility'],
    version: '1.10',
    unicode: 'ec30',
  },
  IconMoodBoy: {
    category: 'Mood',
    tags: ['mood', 'boy', 'face', 'emoji', 'emotion'],
    version: '1.26',
    unicode: 'ed2d',
  },
  IconMoodConfuzed: {
    category: 'Mood',
    tags: ['mood', 'confuzed', 'face', 'emoji', 'emotion', 'frown'],
    version: '1.0',
    unicode: 'eaf3',
  },
  IconMoodCrazyHappy: {
    category: 'Mood',
    tags: [
      'mood',
      'crazy',
      'happy',
      'good',
      'excited',
      'cheerful',
      'jolly',
      'delighted',
      'lucky',
    ],
    version: '1.34',
    unicode: 'ed90',
  },
  IconMoodCry: {
    category: 'Mood',
    tags: ['mood', 'cry', 'face', 'emoji', 'emotion', 'mad'],
    version: '1.18',
    unicode: 'ecbb',
  },
  IconMoodEmpty: {
    category: 'Mood',
    tags: ['mood', 'empty', 'face', 'emoji', 'emotion'],
    version: '1.0',
    unicode: 'eeb5',
  },
  IconMoodHappy: {
    category: 'Mood',
    tags: ['mood', 'happy', 'face', 'emoji', 'emotion'],
    version: '1.0',
    unicode: 'eaf4',
  },
  IconMoodKid: {
    category: 'Mood',
    tags: ['mood', 'kid', 'face', 'emoji', 'emotion'],
    version: '1.8',
    unicode: 'ec03',
  },
  IconMoodNervous: {
    category: 'Mood',
    tags: ['mood', 'nervous'],
    version: '1.47',
    unicode: 'ef96',
  },
  IconMoodNeutral: {
    category: 'Mood',
    tags: ['mood', 'neutral', 'face', 'emoji', 'emotion'],
    version: '1.0',
    unicode: 'eaf5',
  },
  IconMoodSad: {
    category: 'Mood',
    tags: ['mood', 'sad', 'face', 'emoji', 'emotion', 'mad'],
    version: '1.0',
    unicode: 'eaf6',
  },
  IconMoodSmile: {
    category: 'Mood',
    tags: ['mood', 'smile', 'face', 'emoji', 'emotion'],
    version: '1.0',
    unicode: 'eaf7',
  },
  IconMoodSuprised: {
    category: 'Mood',
    tags: ['mood', 'suprised', 'face', 'emoji', 'emotion'],
    version: '1.8',
    unicode: 'ec04',
  },
  IconMoodTongue: {
    category: 'Mood',
    tags: ['mood', 'tongue', 'face', 'emoji', 'emotion'],
    version: '1.3',
    unicode: 'eb95',
  },
  IconMoon2: {
    category: 'Weather',
    tags: ['moon', '2', 'night', 'dark mode'],
    version: '1.21',
    unicode: 'ece6',
  },
  IconMoonStars: {
    category: 'Weather',
    tags: ['moon', 'stars', 'night', 'dark mode'],
    version: '1.21',
    unicode: 'ece7',
  },
  IconMoon: {
    category: 'Weather',
    tags: ['moon', 'night', 'dark mode'],
    version: '1.0',
    unicode: 'eaf8',
  },
  IconMoped: {
    category: 'Vehicles',
    tags: [
      'moped',
      'vehicle',
      'drive',
      'driver',
      'engine',
      'motor',
      'journey',
      'trip',
    ],
    version: '1.18',
    unicode: 'ecbc',
  },
  IconMotorbike: {
    category: 'Vehicles',
    tags: [
      'motorbike',
      'engine',
      'ride',
      'trip',
      'journey',
      'road',
      'street',
      'vehicle',
      'motorcycle',
    ],
    version: '1.39',
    unicode: 'eeb6',
  },
  IconMountain: {
    category: '',
    tags: ['mountain'],
    version: '1.47',
    unicode: 'ef97',
  },
  IconMouse: {
    category: 'Devices',
    tags: ['mouse', 'pointer', 'cursor', 'device'],
    version: '1.0',
    unicode: 'eaf9',
  },
  IconMovie: {
    category: 'Media',
    tags: ['movie', 'film', 'video', 'cinema'],
    version: '1.0',
    unicode: 'eafa',
  },
  IconMug: {
    category: 'Food',
    tags: ['mug', 'tea', 'coffee', 'drink', 'container', 'jug'],
    version: '1.1',
    unicode: 'eafb',
  },
  IconMultiplier05x: {
    category: 'Math',
    tags: ['multiplier', '0', '5x'],
    version: '1.42',
    unicode: 'ef41',
  },
  IconMultiplier15x: {
    category: 'Math',
    tags: ['multiplier', '1', '5x'],
    version: '1.42',
    unicode: 'ef42',
  },
  IconMultiplier1x: {
    category: 'Math',
    tags: ['multiplier', '1x'],
    version: '1.42',
    unicode: 'ef43',
  },
  IconMultiplier2x: {
    category: 'Math',
    tags: ['multiplier', '2x'],
    version: '1.42',
    unicode: 'ef44',
  },
  IconMushroom: {
    category: 'Food',
    tags: ['mushroom'],
    version: '1.40',
    unicode: 'ef14',
  },
  IconMusic: {
    category: 'Media',
    tags: ['music', 'sound', 'mp3', 'album', 'sound', 'speakers', 'melody'],
    version: '1.0',
    unicode: 'eafc',
  },
  IconNewSection: {
    category: '',
    tags: ['new', 'section', 'add', 'element', 'component', 'layout', 'page'],
    version: '1.5',
    unicode: 'ebc1',
  },
  IconNews: {
    category: 'Document',
    tags: ['news', 'newspaper', 'article'],
    version: '1.0',
    unicode: 'eafd',
  },
  IconNfc: {
    category: 'Devices',
    tags: [
      'nfc',
      'payment',
      'nfc',
      'cash',
      'chip',
      'shopping',
      'cashless',
      'pass',
      'contactless',
    ],
    version: '1.39',
    unicode: 'eeb7',
  },
  IconNoCopyright: {
    category: '',
    tags: ['no', 'copyright'],
    version: '1.49',
    unicode: 'efb9',
  },
  IconNoCreativeCommons: {
    category: '',
    tags: ['no', 'creative', 'commons'],
    version: '1.49',
    unicode: 'efba',
  },
  IconNoDerivatives: {
    category: '',
    tags: ['no', 'derivatives'],
    version: '1.49',
    unicode: 'efbb',
  },
  IconNorthStar: {
    category: '',
    tags: ['north', 'star'],
    version: '1.54',
    unicode: 'f014',
  },
  IconNote: {
    category: 'Document',
    tags: ['note', 'checkbox', 'brief', 'record', 'write', 'message'],
    version: '1.2',
    unicode: 'eb6d',
  },
  IconNotebook: {
    category: 'Document',
    tags: [
      'notebook',
      'study',
      'learn',
      'diary',
      'write',
      'journal',
      'page',
      'paper',
      'jot down',
    ],
    version: '1.3',
    unicode: 'eb96',
  },
  IconNotes: {
    category: 'Document',
    tags: ['notes', 'notetaking', 'journal', 'draft', 'idea', 'to-do list'],
    version: '1.2',
    unicode: 'eb6e',
  },
  IconNotification: {
    category: '',
    tags: ['notification', 'bell', 'alarm', 'reminder', 'important'],
    version: '1.0',
    unicode: 'eafe',
  },
  IconNumber0: {
    category: 'Numbers',
    tags: [
      'number',
      '0',
      'zero',
      'maths',
      'value',
      'quantity',
      'calculate',
      'calculation',
      'total',
      'amount',
      'sum',
      'order',
      'digit',
    ],
    version: '1.38',
    unicode: 'edf0',
  },
  IconNumber1: {
    category: 'Numbers',
    tags: [
      'number',
      '1',
      'one',
      'maths',
      'value',
      'quantity',
      'calculate',
      'calculation',
      'total',
      'amount',
      'sum',
      'order',
      'digit',
    ],
    version: '1.38',
    unicode: 'edf1',
  },
  IconNumber2: {
    category: 'Numbers',
    tags: [
      'number',
      '2',
      'two',
      'maths',
      'value',
      'quantity',
      'calculate',
      'calculation',
      'total',
      'amount',
      'sum',
      'order',
      'digit',
    ],
    version: '1.38',
    unicode: 'edf2',
  },
  IconNumber3: {
    category: 'Numbers',
    tags: [
      'number',
      '3',
      'three',
      'maths',
      'value',
      'quantity',
      'calculate',
      'calculation',
      'total',
      'amount',
      'sum',
      'order',
      'digit',
    ],
    version: '1.38',
    unicode: 'edf3',
  },
  IconNumber4: {
    category: 'Numbers',
    tags: [
      'number',
      '4',
      'four',
      'maths',
      'value',
      'quantity',
      'calculate',
      'calculation',
      'total',
      'amount',
      'sum',
      'order',
      'digit',
    ],
    version: '1.38',
    unicode: 'edf4',
  },
  IconNumber5: {
    category: 'Numbers',
    tags: [
      'number',
      '5',
      'five',
      'maths',
      'value',
      'quantity',
      'calculate',
      'calculation',
      'total',
      'amount',
      'sum',
      'order',
      'digit',
    ],
    version: '1.38',
    unicode: 'edf5',
  },
  IconNumber6: {
    category: 'Numbers',
    tags: [
      'number',
      '6',
      'six',
      'maths',
      'value',
      'quantity',
      'calculate',
      'calculation',
      'total',
      'amount',
      'sum',
      'order',
      'digit',
    ],
    version: '1.38',
    unicode: 'edf6',
  },
  IconNumber7: {
    category: 'Numbers',
    tags: [
      'number',
      '7',
      'seven',
      'maths',
      'value',
      'quantity',
      'calculate',
      'calculation',
      'total',
      'amount',
      'sum',
      'order',
      'digit',
    ],
    version: '1.38',
    unicode: 'edf7',
  },
  IconNumber8: {
    category: 'Numbers',
    tags: [
      'number',
      '8',
      'eight',
      'maths',
      'value',
      'quantity',
      'calculate',
      'calculation',
      'total',
      'amount',
      'sum',
      'order',
      'digit',
    ],
    version: '1.38',
    unicode: 'edf8',
  },
  IconNumber9: {
    category: 'Numbers',
    tags: [
      'number',
      '9',
      'nine',
      'maths',
      'value',
      'quantity',
      'calculate',
      'calculation',
      'total',
      'amount',
      'sum',
      'order',
      'digit',
    ],
    version: '1.38',
    unicode: 'edf9',
  },
  IconNumbers: {
    category: '',
    tags: ['numbers'],
    version: '1.54',
    unicode: 'f015',
  },
  IconNurse: {
    category: 'Health',
    tags: ['nurse'],
    version: '1.44',
    unicode: 'ef65',
  },
  IconOctagonOff: {
    category: 'Shapes',
    tags: ['octagon', 'off', 'shape', 'eight', 'angle', 'crossed'],
    version: '1.39',
    unicode: 'eeb8',
  },
  IconOctagon: {
    category: 'Shapes',
    tags: [
      'octagon',
      'shape',
      'geometric',
      'math',
      '2D',
      'building',
      'government',
    ],
    version: '1.18',
    unicode: 'ecbd',
  },
  IconOld: {
    category: 'Health',
    tags: ['old', 'senior', 'person', 'elderly', 'age'],
    version: '1.39',
    unicode: 'eeb9',
  },
  IconOlympics: {
    category: 'Sport',
    tags: [
      'olympics',
      'game',
      'play',
      'sport',
      'sportsman',
      'champion',
      'win',
      'medal',
      'sporting',
      'event',
      'competition',
      'athlete',
    ],
    version: '1.39',
    unicode: 'eeba',
  },
  IconOmega: {
    category: 'Math',
    tags: ['omega', 'alphabet', 'greek', 'symbol', 'final', 'last'],
    version: '1.3',
    unicode: 'eb97',
  },
  IconOutlet: {
    category: '',
    tags: [
      'outlet',
      'socket',
      'electricity',
      'electrical',
      'plug in',
      'device',
    ],
    version: '1.6',
    unicode: 'ebd7',
  },
  IconOvalVertical: {
    category: 'Shapes',
    tags: ['oval', 'vertical'],
    version: '1.55',
    unicode: 'f02d',
  },
  IconOval: {
    category: 'Shapes',
    tags: ['oval'],
    version: '1.55',
    unicode: 'f02e',
  },
  IconOverline: {
    category: 'Text',
    tags: ['overline', 'above', 'overbar', 'overscore', 'horizontal'],
    version: '1.39',
    unicode: 'eebb',
  },
  IconPackage: {
    category: 'E-commerce',
    tags: ['package', 'npm', 'box', 'container'],
    version: '1.0',
    unicode: 'eaff',
  },
  IconPacman: {
    category: '',
    tags: ['pacman', 'game', 'play', 'online', 'maze', 'eat', 'dot', 'ghost'],
    version: '1.39',
    unicode: 'eebc',
  },
  IconPageBreak: {
    category: '',
    tags: ['page', 'break', 'summary', 'feature', 'element', 'css'],
    version: '1.14',
    unicode: 'ec81',
  },
  IconPaint: {
    category: 'Design',
    tags: ['paint', 'brush', 'renovation', 'refurbishment', 'color', 'wall'],
    version: '1.0',
    unicode: 'eb00',
  },
  IconPalette: {
    category: 'Design',
    tags: [
      'palette',
      'color',
      'paint',
      'painter',
      'picture',
      'board',
      'artist',
    ],
    version: '1.1',
    unicode: 'eb01',
  },
  IconPanoramaHorizontal: {
    category: 'Photography',
    tags: ['panorama', 'horizontal', 'photo', 'picture', 'panoramic'],
    version: '1.27',
    unicode: 'ed33',
  },
  IconPanoramaVertical: {
    category: 'Photography',
    tags: ['panorama', 'vertical', 'photo', 'picture', 'panoramic'],
    version: '1.27',
    unicode: 'ed34',
  },
  IconPaperBag: {
    category: 'food',
    tags: ['paper', 'bag'],
    version: '1.55',
    unicode: 'f02f',
  },
  IconPaperclip: {
    category: '',
    tags: ['paperclip', 'attachment', 'annex', 'hold'],
    version: '1.0',
    unicode: 'eb02',
  },
  IconParachute: {
    category: 'Vehicles',
    tags: ['parachute', 'plane', 'aircraft', 'land', 'float', 'pilot'],
    version: '1.33',
    unicode: 'ed7c',
  },
  IconParentheses: {
    category: 'Math',
    tags: ['parentheses', 'brackets', 'aside', 'punctuation', 'mark', 'insert'],
    version: '1.6',
    unicode: 'ebd8',
  },
  IconParking: {
    category: 'Map',
    tags: ['parking', 'sign', 'car', 'vehicle', 'space'],
    version: '1.0',
    unicode: 'eb03',
  },
  IconPaw: {
    category: '',
    tags: ['paw'],
    version: '1.52',
    unicode: 'eff9',
  },
  IconPeace: {
    category: 'Symbols',
    tags: ['peace', 'love', 'hippy', 'minority', 'opinion', 'equilibrium'],
    version: '1.18',
    unicode: 'ecbe',
  },
  IconPencil: {
    category: 'Design',
    tags: ['pencil', 'write', 'draft', 'edit', 'note'],
    version: '1.1',
    unicode: 'eb04',
  },
  IconPennant: {
    category: 'Map',
    tags: [
      'pennant',
      'flag',
      'ship',
      'sports',
      'championship',
      'mark',
      'spot',
      'winner',
    ],
    version: '1.33',
    unicode: 'ed7d',
  },
  IconPentagon: {
    category: 'Shapes',
    tags: ['pentagon'],
    version: '1.51',
    unicode: 'efe3',
  },
  IconPepper: {
    category: 'Food',
    tags: ['pepper'],
    version: '1.40',
    unicode: 'ef15',
  },
  IconPercentage: {
    category: 'Math',
    tags: [
      'percentage',
      'sign',
      'symbol',
      'math',
      'economics',
      'cash',
      'bank account',
      'chart',
      'graph',
      'diagram',
      'statistic',
    ],
    version: '1.22',
    unicode: 'ecf4',
  },
  IconPerspective: {
    category: 'Shapes',
    tags: ['perspective', '3d', 'perspective', 'transform', 'reshape', 'scale'],
    version: '1.39',
    unicode: 'eebd',
  },
  IconPhoneCall: {
    category: 'Devices',
    tags: [
      'phone',
      'call',
      'ring',
      'mobile',
      'conversation',
      'answer',
      'dial',
      'landline',
    ],
    version: '1.0',
    unicode: 'eb05',
  },
  IconPhoneCalling: {
    category: 'Devices',
    tags: [
      'phone',
      'calling',
      'ring',
      'mobile',
      'conversation',
      'answer',
      'dial',
      'landline',
    ],
    version: '1.11',
    unicode: 'ec43',
  },
  IconPhoneCheck: {
    category: 'Devices',
    tags: [
      'phone',
      'check',
      'ring',
      'mobile',
      'conversation',
      'answer',
      'dial',
      'landline',
    ],
    version: '1.8',
    unicode: 'ec05',
  },
  IconPhoneIncoming: {
    category: 'Devices',
    tags: [
      'phone',
      'incoming',
      'call',
      'answer',
      'mobile',
      'landline',
      'conversation',
    ],
    version: '1.0',
    unicode: 'eb06',
  },
  IconPhoneOff: {
    category: 'Devices',
    tags: [
      'phone',
      'off',
      'call',
      'mobile',
      'conversation',
      'landline',
      'answer',
      'number',
    ],
    version: '1.22',
    unicode: 'ecf5',
  },
  IconPhoneOutgoing: {
    category: 'Devices',
    tags: [
      'phone',
      'outgoing',
      'call',
      'signal',
      'mobile',
      'landline',
      'conversation',
    ],
    version: '1.0',
    unicode: 'eb07',
  },
  IconPhonePause: {
    category: 'Devices',
    tags: [
      'phone',
      'pause',
      'call',
      'mute',
      'mobile',
      'landline',
      'conversation',
    ],
    version: '1.0',
    unicode: 'eb08',
  },
  IconPhonePlus: {
    category: 'Devices',
    tags: [
      'phone',
      'plus',
      'call',
      'signal',
      'mobile',
      'landline',
      'conversation',
    ],
    version: '1.8',
    unicode: 'ec06',
  },
  IconPhoneX: {
    category: 'Devices',
    tags: [
      'phone',
      'x',
      'ring',
      'mobile',
      'conversation',
      'answer',
      'dial',
      'landline',
    ],
    version: '1.8',
    unicode: 'ec07',
  },
  IconPhone: {
    category: 'Devices',
    tags: [
      'phone',
      'call',
      'mobile',
      'conversation',
      'landline',
      'answer',
      'number',
    ],
    version: '1.0',
    unicode: 'eb09',
  },
  IconPhotoOff: {
    category: 'Media',
    tags: ['photo', 'off', 'image', 'picture', 'landscape', 'camera'],
    version: '1.22',
    unicode: 'ecf6',
  },
  IconPhoto: {
    category: 'Media',
    tags: ['photo', 'image', 'picture', 'landscape', 'camera'],
    version: '1.0',
    unicode: 'eb0a',
  },
  IconPhysotherapist: {
    category: 'Health',
    tags: [
      'physotherapist',
      'physiotherapy',
      'spa',
      'therapy',
      'treatment',
      'pain',
      'exercise',
    ],
    version: '1.39',
    unicode: 'eebe',
  },
  IconPictureInPictureOff: {
    category: 'Media',
    tags: [
      'picture',
      'in',
      'picture',
      'off',
      'size',
      'photo',
      'elements',
      'adjust',
      'image',
    ],
    version: '1.28',
    unicode: 'ed43',
  },
  IconPictureInPictureOn: {
    category: 'Media',
    tags: [
      'picture',
      'in',
      'picture',
      'on',
      'size',
      'photo',
      'elements',
      'adjust',
      'image',
    ],
    version: '1.28',
    unicode: 'ed44',
  },
  IconPictureInPictureTop: {
    category: '',
    tags: ['picture', 'in', 'picture', 'top'],
    version: '1.51',
    unicode: 'efe4',
  },
  IconPictureInPicture: {
    category: 'Media',
    tags: [
      'picture',
      'in',
      'picture',
      'size',
      'photo',
      'elements',
      'adjust',
      'image',
    ],
    version: '1.27',
    unicode: 'ed35',
  },
  IconPig: {
    category: '',
    tags: ['pig'],
    version: '1.43',
    unicode: 'ef52',
  },
  IconPill: {
    category: 'Health',
    tags: [
      'pill',
      'drug',
      'medication',
      'illness',
      'sickness',
      'doctor',
      'prescription',
    ],
    version: '1.1',
    unicode: 'ec44',
  },
  IconPills: {
    category: 'Health',
    tags: ['pills'],
    version: '1.44',
    unicode: 'ef66',
  },
  IconPin: {
    category: 'Map',
    tags: ['pin', 'thing', 'localization', 'maps', 'clip', 'place', 'location'],
    version: '1.16',
    unicode: 'ec9c',
  },
  IconPinnedOff: {
    category: 'Map',
    tags: [
      'pinned',
      'off',
      'removed',
      'attach',
      'corkboard',
      'unfasten',
      'noticeboard',
    ],
    version: '1.31',
    unicode: 'ed5f',
  },
  IconPinned: {
    category: 'Map',
    tags: [
      'pinned',
      'board',
      'attach',
      'nail',
      'pointed',
      'corkboard',
      'favourite',
      'noticeboard',
    ],
    version: '1.31',
    unicode: 'ed60',
  },
  IconPizza: {
    category: 'Food',
    tags: ['pizza'],
    version: '1.35',
    unicode: 'edbb',
  },
  IconPlaneArrival: {
    category: 'Vehicles',
    tags: [
      'plane',
      'arrival',
      'travel',
      'land',
      'journey',
      'trip',
      'airport',
      'baggage',
      'luggage',
    ],
    version: '1.3',
    unicode: 'eb99',
  },
  IconPlaneDeparture: {
    category: 'Vehicles',
    tags: [
      'plane',
      'departure',
      'travel',
      'take off',
      'journey',
      'trip',
      'airport',
      'baggage',
      'luggage',
    ],
    version: '1.3',
    unicode: 'eb9a',
  },
  IconPlaneInflight: {
    category: 'Vehicles',
    tags: ['plane', 'inflight'],
    version: '1.47',
    unicode: 'ef98',
  },
  IconPlane: {
    category: 'Vehicles',
    tags: [
      'plane',
      'travel',
      'journey',
      'trip',
      'airport',
      'baggage',
      'luggage',
    ],
    version: '1.2',
    unicode: 'eb6f',
  },
  IconPlanet: {
    category: 'Map',
    tags: [
      'planet',
      'earth',
      'uranus',
      'universe',
      'space',
      'galaxy',
      'orbit',
      'atmosphere',
    ],
    version: '1.8',
    unicode: 'ec08',
  },
  IconPlant2: {
    category: 'Nature',
    tags: [
      'plant',
      '2',
      'nature',
      'green',
      'flower',
      'pot',
      'tree',
      'leaf',
      'greenery',
      'root',
      'stem',
      'seed',
    ],
    version: '1.33',
    unicode: 'ed7e',
  },
  IconPlant: {
    category: 'Nature',
    tags: [
      'plant',
      'nature',
      'green',
      'flower',
      'pot',
      'tree',
      'leaf',
      'greenery',
      'root',
      'stem',
      'seed',
    ],
    version: '1.29',
    unicode: 'ed50',
  },
  IconPlayCard: {
    category: '',
    tags: [
      'play',
      'card',
      'game',
      'magic',
      'trick',
      'casino',
      'entertainment',
      'spade',
      'heart',
      'diamond',
      'club',
      'playing',
    ],
    version: '1.39',
    unicode: 'eebf',
  },
  IconPlayerEject: {
    category: 'Media',
    tags: ['player', 'eject'],
    version: '1.49',
    unicode: 'efbc',
  },
  IconPlayerPause: {
    category: 'Media',
    tags: ['player', 'pause', 'video', 'film', 'music', 'player', 'stop'],
    version: '1.28',
    unicode: 'ed45',
  },
  IconPlayerPlay: {
    category: 'Media',
    tags: ['player', 'play', 'start', 'video', 'film', 'music', 'player'],
    version: '1.28',
    unicode: 'ed46',
  },
  IconPlayerRecord: {
    category: 'Media',
    tags: [
      'player',
      'record',
      'music',
      'song',
      'playlist',
      'melody',
      'device',
      'voice',
      'recorder',
      'dictation',
      'machine',
    ],
    version: '1.28',
    unicode: 'ed47',
  },
  IconPlayerSkipBack: {
    category: 'Media',
    tags: [
      'player',
      'skip',
      'back',
      'button',
      'player',
      'video',
      'film',
      'music',
      'cancel',
      'rewind',
      'reverse',
    ],
    version: '1.28',
    unicode: 'ed48',
  },
  IconPlayerSkipForward: {
    category: 'Media',
    tags: [
      'player',
      'skip',
      'forward',
      'button',
      'player',
      'video',
      'film',
      'music',
      'omit',
    ],
    version: '1.28',
    unicode: 'ed49',
  },
  IconPlayerStop: {
    category: 'Media',
    tags: [
      'player',
      'stop',
      'music',
      'song',
      'playlist',
      'melody',
      'device',
      'voice',
      'silence',
      'break',
    ],
    version: '1.28',
    unicode: 'ed4a',
  },
  IconPlayerTrackNext: {
    category: 'Media',
    tags: [
      'player',
      'track',
      'next',
      'music',
      'forward',
      'play',
      'song',
      'playlist',
    ],
    version: '1.28',
    unicode: 'ed4b',
  },
  IconPlayerTrackPrev: {
    category: 'Media',
    tags: [
      'player',
      'track',
      'prev',
      'music',
      'forward',
      'play',
      'song',
      'playlist',
    ],
    version: '1.28',
    unicode: 'ed4c',
  },
  IconPlaylistAdd: {
    category: '',
    tags: ['playlist', 'add'],
    version: '1.53',
    unicode: 'f008',
  },
  IconPlaylistX: {
    category: '',
    tags: ['playlist', 'x'],
    version: '1.53',
    unicode: 'f009',
  },
  IconPlaylist: {
    category: 'Media',
    tags: [
      'playlist',
      'music',
      'song',
      'artist',
      'spotify',
      'track',
      'play',
      'record',
    ],
    version: '1.39',
    unicode: 'eec0',
  },
  IconPlugConnected: {
    category: '',
    tags: ['plug', 'connected'],
    version: '1.53',
    unicode: 'f00a',
  },
  IconPlug: {
    category: '',
    tags: ['plug', 'electricity', 'charger', 'socket', 'connection'],
    version: '1.6',
    unicode: 'ebd9',
  },
  IconPlus: {
    category: 'Math',
    tags: ['plus', 'add', 'create', 'new'],
    version: '1.0',
    unicode: 'eb0b',
  },
  IconPoint: {
    category: '',
    tags: ['point', 'dot', 'label'],
    version: '1.0',
    unicode: 'eb0c',
  },
  IconPokeball: {
    category: 'Map',
    tags: ['pokeball', 'pokemon', 'go', 'catch', 'game', 'play'],
    version: '1.39',
    unicode: 'eec1',
  },
  IconPolaroid: {
    category: 'Photography',
    tags: [
      'polaroid',
      'picture',
      'photo',
      'camera',
      'polarization',
      'develop',
      'film',
      'lens',
    ],
    version: '1.39',
    unicode: 'eec2',
  },
  IconPolygon: {
    category: '',
    tags: ['polygon'],
    version: '1.50',
    unicode: 'efd0',
  },
  IconPool: {
    category: 'Sport',
    tags: [
      'pool',
      'swim',
      'water',
      'swimmer',
      'holiday',
      'swimming',
      'vacation',
      'relax',
      'sport',
    ],
    version: '1.34',
    unicode: 'ed91',
  },
  IconPower: {
    category: 'Devices',
    tags: ['power', 'on', 'off', 'turn on', 'turn off', 'electricity'],
    version: '1.0',
    unicode: 'eb0d',
  },
  IconPray: {
    category: '',
    tags: [
      'pray',
      'religion',
      'faith',
      'christianity',
      'islam',
      'buddhism',
      'judaism',
    ],
    version: '1.18',
    unicode: 'ecbf',
  },
  IconPremiumRights: {
    category: '',
    tags: ['premium', 'rights'],
    version: '1.49',
    unicode: 'efbd',
  },
  IconPrescription: {
    category: 'Health',
    tags: ['prescription'],
    version: '1.47',
    unicode: 'ef99',
  },
  IconPresentationAnalytics: {
    category: 'Document',
    tags: [
      'presentation',
      'analytics',
      'slideshow',
      'display',
      'exhibition',
      'speech',
      'topic',
      'conference',
    ],
    version: '1.39',
    unicode: 'eec3',
  },
  IconPresentation: {
    category: 'Document',
    tags: [
      'presentation',
      'slideshow',
      'display',
      'exhibition',
      'speech',
      'topic',
      'conference',
    ],
    version: '1.2',
    unicode: 'eb70',
  },
  IconPrinter: {
    category: 'Devices',
    tags: ['printer', 'fax', 'office', 'device'],
    version: '1.0',
    unicode: 'eb0e',
  },
  IconPrison: {
    category: '',
    tags: ['prison'],
    version: '1.45',
    unicode: 'ef79',
  },
  IconPrompt: {
    category: '',
    tags: ['prompt', 'command line', 'terminal', 'code'],
    version: '1.0',
    unicode: 'eb0f',
  },
  IconPropeller: {
    category: '',
    tags: [
      'propeller',
      'rotate',
      'blade',
      'spiral',
      'air',
      'ship',
      'fan',
      'power',
    ],
    version: '1.39',
    unicode: 'eec4',
  },
  IconPuzzle2: {
    category: '',
    tags: ['puzzle', '2'],
    version: '1.46',
    unicode: 'ef83',
  },
  IconPuzzle: {
    category: '',
    tags: ['puzzle', 'jigsaw', 'extension', 'add-on'],
    version: '1.0',
    unicode: 'eb10',
  },
  IconPyramid: {
    category: '',
    tags: ['pyramid', 'pattern', 'abstract', 'geometric', 'shape'],
    version: '1.39',
    unicode: 'eec5',
  },
  IconQrcode: {
    category: 'Devices',
    tags: ['qrcode', 'scan', 'data'],
    version: '1.0',
    unicode: 'eb11',
  },
  IconQuestionMark: {
    category: '',
    tags: [
      'question',
      'mark',
      'sign',
      'symbol',
      'ask',
      'sentence',
      'word',
      'letters',
    ],
    version: '1.16',
    unicode: 'ec9d',
  },
  IconQuote: {
    category: 'Text',
    tags: ['quote'],
    version: '1.49',
    unicode: 'efbe',
  },
  IconRadar2: {
    category: '',
    tags: ['radar', '2'],
    version: '1.54',
    unicode: 'f016',
  },
  IconRadar: {
    category: '',
    tags: ['radar'],
    version: '1.54',
    unicode: 'f017',
  },
  IconRadio: {
    category: 'Media',
    tags: ['radio'],
    version: '1.41',
    unicode: 'ef2d',
  },
  IconRadioactive: {
    category: 'Symbols',
    tags: [
      'radioactive',
      'dangerous',
      'precarious',
      'danger',
      'sign',
      'symbol',
      'warning',
      'caution',
      'chernobyl',
      'reactor',
      'atomic',
      'powerhouses',
      'generator',
    ],
    version: '1.18',
    unicode: 'ecc0',
  },
  IconRadiusBottomLeft: {
    category: '',
    tags: [
      'radius',
      'bottom',
      'left',
      'round',
      'corner',
      'rounded',
      'border',
      'css',
      'style',
      'bottom',
    ],
    version: '1.39',
    unicode: 'eec6',
  },
  IconRadiusBottomRight: {
    category: '',
    tags: [
      'radius',
      'bottom',
      'right',
      'round',
      'corner',
      'rounded',
      'border',
      'css',
      'style',
      'top',
    ],
    version: '1.39',
    unicode: 'eec7',
  },
  IconRadiusTopLeft: {
    category: '',
    tags: [
      'radius',
      'top',
      'left',
      'round',
      'corner',
      'rounded',
      'border',
      'css',
      'style',
      'bottom',
    ],
    version: '1.39',
    unicode: 'eec8',
  },
  IconRadiusTopRight: {
    category: '',
    tags: [
      'radius',
      'top',
      'right',
      'round',
      'corner',
      'rounded',
      'border',
      'css',
      'style',
      'top',
    ],
    version: '1.39',
    unicode: 'eec9',
  },
  IconRainbow: {
    category: '',
    tags: ['rainbow'],
    version: '1.35',
    unicode: 'edbc',
  },
  IconReceipt2: {
    category: 'Document',
    tags: [
      'receipt',
      '2',
      'bill',
      'restaurant',
      'shop',
      'price',
      'pay',
      'money',
      'total',
      'tax',
    ],
    version: '1.38',
    unicode: 'edfa',
  },
  IconReceiptOff: {
    category: 'Document',
    tags: [
      'receipt',
      'off',
      'bill',
      'restaurant',
      'shop',
      'price',
      'pay',
      'money',
      'total',
      'tax',
      'free',
    ],
    version: '1.38',
    unicode: 'edfb',
  },
  IconReceiptRefund: {
    category: 'Document',
    tags: [
      'receipt',
      'refund',
      'bill',
      'restaurant',
      'shop',
      'price',
      'pay',
      'money',
      'total',
      'give',
      'back',
      'return',
    ],
    version: '1.38',
    unicode: 'edfc',
  },
  IconReceiptTax: {
    category: 'Document',
    tags: [
      'receipt',
      'tax',
      'income',
      'percentage',
      'money',
      'finance',
      'charge',
      'obligation',
      'taxpayer',
      'vat',
    ],
    version: '1.5',
    unicode: 'edbd',
  },
  IconReceipt: {
    category: 'Document',
    tags: [
      'receipt',
      'bill',
      'restaurant',
      'shop',
      'price',
      'pay',
      'money',
      'total',
      'tax',
    ],
    version: '1.38',
    unicode: 'edfd',
  },
  IconRecharging: {
    category: '',
    tags: [
      'recharging',
      'battery',
      'power',
      'charge',
      'socket',
      'electricity',
      'device',
      'phone',
      'laptop',
      'low',
    ],
    version: '1.39',
    unicode: 'eeca',
  },
  IconRecordMail: {
    category: '',
    tags: ['record', 'mail', 'voice', 'voicemail', 'message'],
    version: '1.1',
    unicode: 'eb12',
  },
  IconRectangleVertical: {
    category: 'Shapes',
    tags: ['rectangle', 'vertical', 'shape', 'geometric', 'math', 'upright'],
    version: '1.27',
    unicode: 'ed36',
  },
  IconRectangle: {
    category: 'Shapes',
    tags: ['rectangle', 'shape', 'geometric', 'math'],
    version: '1.27',
    unicode: 'ed37',
  },
  IconRecycle: {
    category: 'Symbols',
    tags: ['recycle', 'trash', 'rubbish', 'recyclable', 'reuse', 'waste'],
    version: '1.3',
    unicode: 'eb9b',
  },
  IconRefreshAlert: {
    category: 'Arrows',
    tags: [
      'refresh',
      'alert',
      'synchronization',
      'reload',
      'restart',
      'spinner',
      'loader',
      'ajax',
      'update',
      'arrows',
    ],
    version: '1.0',
    unicode: 'ed57',
  },
  IconRefreshDot: {
    category: '',
    tags: ['refresh', 'dot'],
    version: '1.49',
    unicode: 'efbf',
  },
  IconRefresh: {
    category: 'Arrows',
    tags: [
      'refresh',
      'synchronization',
      'reload',
      'restart',
      'spinner',
      'loader',
      'ajax',
      'update',
      'arrows',
    ],
    version: '1.0',
    unicode: 'eb13',
  },
  IconRegistered: {
    category: '',
    tags: ['registered', 'copyright', 'trademark', 'rights'],
    version: '1.0',
    unicode: 'eb14',
  },
  IconRelationManyToMany: {
    category: 'Database',
    tags: [
      'relation',
      'many',
      'to',
      'many',
      'data',
      'model',
      'analysis',
      'multiple',
      'connection',
      'database',
      'link',
    ],
    version: '1.33',
    unicode: 'ed7f',
  },
  IconRelationOneToMany: {
    category: 'Database',
    tags: [
      'relation',
      'one',
      'to',
      'many',
      'data',
      'model',
      'analysis',
      'multiple',
      'connection',
      'database',
      'link',
    ],
    version: '1.33',
    unicode: 'ed80',
  },
  IconRelationOneToOne: {
    category: 'Database',
    tags: [
      'relation',
      'one',
      'to',
      'one',
      'data',
      'model',
      'analysis',
      'connection',
      'database',
      'link',
    ],
    version: '1.33',
    unicode: 'ed81',
  },
  IconRepeatOnce: {
    category: 'Media',
    tags: ['repeat', 'once', 'reuse', 'redo', 'action', 'replay', 'loop'],
    version: '1.2',
    unicode: 'eb71',
  },
  IconRepeat: {
    category: 'Media',
    tags: ['repeat', 'reuse', 'redo', 'action', 'replay', 'loop'],
    version: '1.2',
    unicode: 'eb72',
  },
  IconReplace: {
    category: '',
    tags: ['replace', 'change', 'place', 'position', 'move', 'exchange'],
    version: '1.5',
    unicode: 'ebc7',
  },
  IconReportAnalytics: {
    category: 'Document',
    tags: [
      'report',
      'analytics',
      'statistics',
      'results',
      'business',
      'sales',
      'analysis',
      'analyse',
      'bar',
      'chart',
    ],
    version: '1.39',
    unicode: 'eecb',
  },
  IconReportMedical: {
    category: 'Document',
    tags: [
      'report',
      'medical',
      'hospital',
      'doctor',
      'health',
      'sickness',
      'illness',
      'test',
      'results',
    ],
    version: '1.39',
    unicode: 'eecc',
  },
  IconReportMoney: {
    category: 'Document',
    tags: [
      'report',
      'money',
      'results',
      'business',
      'sales',
      'analysis',
      'analyse',
      'finance',
      'financial',
      'total',
    ],
    version: '1.39',
    unicode: 'eecd',
  },
  IconReportSearch: {
    category: 'Document',
    tags: ['report', 'search'],
    version: '1.46',
    unicode: 'ef84',
  },
  IconReport: {
    category: 'Document',
    tags: [
      'report',
      'time',
      'timesheet',
      'analysis',
      'analyse',
      'results',
      'business',
      'company',
    ],
    version: '1.39',
    unicode: 'eece',
  },
  IconResize: {
    category: 'Design',
    tags: ['resize', 'picture', 'photo', 'alter', 'change', 'height', 'width'],
    version: '1.39',
    unicode: 'eecf',
  },
  IconRipple: {
    category: '',
    tags: ['ripple', 'wave', 'water', 'breeze', 'ocean', 'sea'],
    version: '1.33',
    unicode: 'ed82',
  },
  IconRoadSign: {
    category: 'Map',
    tags: [
      'road',
      'sign',
      'telltale',
      'prohibitive',
      'indicative',
      'cautionary',
      'codex',
      'restrictions',
    ],
    version: '1.20',
    unicode: 'ecdd',
  },
  IconRoad: {
    category: '',
    tags: ['road'],
    version: '1.54',
    unicode: 'f018',
  },
  IconRobot: {
    category: '',
    tags: ['robot'],
    version: '1.53',
    unicode: 'f00b',
  },
  IconRocket: {
    category: 'Map',
    tags: [
      'rocket',
      'universe',
      'galaxy',
      'space',
      'journey',
      'discover',
      'extraterrestrial',
      'spaceship',
    ],
    version: '1.11',
    unicode: 'ec45',
  },
  IconRollerSkating: {
    category: 'Sport',
    tags: ['roller', 'skating'],
    version: '1.50',
    unicode: 'efd1',
  },
  IconRotate2: {
    category: 'Arrows',
    tags: [
      'rotate',
      '2',
      'refresh',
      'synchronization',
      'reload',
      'restart',
      'spinner',
      'loader',
      'ajax',
      'update',
      'arrows',
    ],
    version: '1.4',
    unicode: 'ebb4',
  },
  IconRotate360: {
    category: 'Arrows',
    tags: ['rotate', '360'],
    version: '1.46',
    unicode: 'ef85',
  },
  IconRotateClockwise2: {
    category: 'Arrows',
    tags: [
      'rotate',
      'clockwise',
      '2',
      'refresh',
      'synchronization',
      'reload',
      'restart',
      'spinner',
      'loader',
      'ajax',
      'update',
      'arrows',
    ],
    version: '1.4',
    unicode: 'ebb5',
  },
  IconRotateClockwise: {
    category: 'Arrows',
    tags: [
      'rotate',
      'clockwise',
      'refresh',
      'synchronization',
      'reload',
      'restart',
      'spinner',
      'loader',
      'ajax',
      'update',
      'arrows',
    ],
    version: '1.0',
    unicode: 'eb15',
  },
  IconRotateDot: {
    category: 'Arrows',
    tags: ['rotate', 'dot'],
    version: '1.51',
    unicode: 'efe5',
  },
  IconRotateRectangle: {
    category: 'Arrows',
    tags: [
      'rotate',
      'rectangle',
      'refresh',
      'synchronization',
      'reload',
      'restart',
      'spinner',
      'loader',
      'ajax',
      'update',
      'arrows',
    ],
    version: '1.8',
    unicode: 'ec15',
  },
  IconRotate: {
    category: 'Arrows',
    tags: [
      'rotate',
      'refresh',
      'synchronization',
      'reload',
      'restart',
      'spinner',
      'loader',
      'ajax',
      'update',
      'arrows',
    ],
    version: '1.0',
    unicode: 'eb16',
  },
  IconRoute: {
    category: 'Map',
    tags: ['route', 'path', 'navigation', 'map'],
    version: '1.0',
    unicode: 'eb17',
  },
  IconRouter: {
    category: 'Devices',
    tags: ['router', 'wifi', 'device', 'wireless', 'signal', 'station', 'cast'],
    version: '1.0',
    unicode: 'eb18',
  },
  IconRowInsertBottom: {
    category: 'Database',
    tags: [
      'row',
      'insert',
      'bottom',
      'table',
      'layout',
      'add',
      'below',
      'macro',
      'excel',
    ],
    version: '1.39',
    unicode: 'eed0',
  },
  IconRowInsertTop: {
    category: 'Database',
    tags: [
      'row',
      'insert',
      'top',
      'table',
      'layout',
      'add',
      'below',
      'macro',
      'excel',
    ],
    version: '1.39',
    unicode: 'eed1',
  },
  IconRss: {
    category: '',
    tags: ['rss', 'feed', 'subscribe'],
    version: '1.0',
    unicode: 'eb19',
  },
  IconRuler2: {
    category: '',
    tags: [
      'ruler',
      '2',
      'maths',
      'dimensions',
      'size',
      'width',
      'length',
      'geometry',
      'measure',
      'technical',
    ],
    version: '1.39',
    unicode: 'eed2',
  },
  IconRuler: {
    category: '',
    tags: [
      'ruler',
      'maths',
      'dimensions',
      'size',
      'width',
      'length',
      'geometry',
      'measure',
      'technical',
    ],
    version: '1.1',
    unicode: 'eb1a',
  },
  IconRun: {
    category: 'Sport',
    tags: ['run', 'jog', 'dislocating', 'movement', 'motion', 'sprint'],
    version: '1.14',
    unicode: 'ec82',
  },
  IconSailboat: {
    category: 'Vehicles',
    tags: ['sailboat', 'sailor', 'journey', 'sea', 'lake', 'ocean', 'river'],
    version: '1.14',
    unicode: 'ec83',
  },
  IconSalt: {
    category: 'Food',
    tags: ['salt'],
    version: '1.40',
    unicode: 'ef16',
  },
  IconSatellite: {
    category: 'Map',
    tags: [
      'satellite',
      'orbit',
      'space',
      'moon',
      'earth',
      'planet',
      'communication',
      'information',
      'celestial',
    ],
    version: '1.39',
    unicode: 'eed3',
  },
  IconSausage: {
    category: 'Food',
    tags: ['sausage'],
    version: '1.40',
    unicode: 'ef17',
  },
  IconScaleOutline: {
    category: '',
    tags: ['scale', 'outline'],
    version: '1.43',
    unicode: 'ef53',
  },
  IconScale: {
    category: '',
    tags: ['scale', 'weigh', 'balance', 'amount', 'heavy', 'light', 'libra'],
    version: '1.1',
    unicode: 'ebc2',
  },
  IconScan: {
    category: '',
    tags: ['scan', 'code', 'barcode', 'qr code', 'app', 'scanner', 'document'],
    version: '1.5',
    unicode: 'ebc8',
  },
  IconSchool: {
    category: 'Map',
    tags: [
      'school',
      'students',
      'class',
      'teachers',
      'professors',
      'doctors',
      'hall',
      'classroom',
      'subject',
      'science',
      'break',
      'lesson',
    ],
    version: '1.22',
    unicode: 'ecf7',
  },
  IconScissors: {
    category: 'Design',
    tags: [
      'scissors',
      'cut',
      'paper',
      'file',
      'document',
      'hairdresser',
      'blade',
      'sharp',
    ],
    version: '1.1',
    unicode: 'eb1b',
  },
  IconScooterElectric: {
    category: 'Vehicles',
    tags: [
      'scooter',
      'electric',
      'vehicle',
      'drive',
      'driver',
      'engine',
      'motor',
      'journey',
      'trip',
    ],
    version: '1.12',
    unicode: 'ecc1',
  },
  IconScooter: {
    category: 'Vehicles',
    tags: [
      'scooter',
      'vehicle',
      'drive',
      'driver',
      'engine',
      'motor',
      'journey',
      'trip',
    ],
    version: '1.12',
    unicode: 'ec6c',
  },
  IconScreenShareOff: {
    category: 'Devices',
    tags: [
      'screen',
      'share',
      'off',
      'monitor',
      'stream',
      'tv',
      'mirroring',
      'cast',
      'online',
    ],
    version: '1.24',
    unicode: 'ed17',
  },
  IconScreenShare: {
    category: 'Devices',
    tags: [
      'screen',
      'share',
      'monitor',
      'stream',
      'tv',
      'mirroring',
      'cast',
      'online',
    ],
    version: '1.24',
    unicode: 'ed18',
  },
  IconScubaMask: {
    category: 'Sport',
    tags: [
      'scuba',
      'mask',
      'dive',
      'diving',
      'water',
      'holiday',
      'underwater',
      'snorkeling',
      'equipment',
    ],
    version: '1.39',
    unicode: 'eed4',
  },
  IconSearch: {
    category: '',
    tags: ['search', 'find', 'magnifier', 'magnifying glass'],
    version: '1.0',
    unicode: 'eb1c',
  },
  IconSectionSign: {
    category: '',
    tags: ['section', 'sign'],
    version: '1.54',
    unicode: 'f019',
  },
  IconSection: {
    category: 'Design',
    tags: [
      'section',
      'html',
      'element',
      'layout',
      'divide',
      'position',
      'website',
    ],
    version: '1.39',
    unicode: 'eed5',
  },
  IconSeeding: {
    category: 'Nature',
    tags: [
      'seeding',
      'nature',
      'greenery',
      'grow',
      'soil',
      'harvest',
      'plant',
      'flower',
      'tree',
      'leaf',
    ],
    version: '1.29',
    unicode: 'ed51',
  },
  IconSelect: {
    category: '',
    tags: ['select', 'input'],
    version: '1.16',
    unicode: 'ec9e',
  },
  IconSelector: {
    category: '',
    tags: ['selector', 'arrows'],
    version: '1.0',
    unicode: 'eb1d',
  },
  IconSend: {
    category: 'Communication',
    tags: [
      'send',
      'message',
      'mail',
      'email',
      'gmail',
      'paper',
      'airplane',
      'aeroplane',
    ],
    version: '1.0',
    unicode: 'eb1e',
  },
  IconSeparatorHorizontal: {
    category: 'Text',
    tags: [
      'separator',
      'horizontal',
      'divider',
      'space',
      'separate',
      'set apart',
      'flat-lying',
    ],
    version: '1.13',
    unicode: 'ec79',
  },
  IconSeparatorVertical: {
    category: 'Text',
    tags: [
      'separator',
      'vertical',
      'divider',
      'space',
      'separate',
      'set apart',
      'upright',
    ],
    version: '1.13',
    unicode: 'ec7a',
  },
  IconSeparator: {
    category: 'Text',
    tags: ['separator', 'divider', 'space', 'separate', 'set apart'],
    version: '1.6',
    unicode: 'ebda',
  },
  IconServer: {
    category: 'Devices',
    tags: ['server', 'storage', 'hosting', 'www'],
    version: '1.0',
    unicode: 'eb1f',
  },
  IconServicemark: {
    category: 'Symbols',
    tags: ['servicemark', 'trademark', 'sign', 'symbol', 'registration'],
    version: '1.8',
    unicode: 'ec09',
  },
  IconSettingsAutomation: {
    category: 'System',
    tags: [
      'settings',
      'automation',
      'system',
      'technology',
      'automate',
      'configure',
      'device',
      'program',
    ],
    version: '1.39',
    unicode: 'eed6',
  },
  IconSettings: {
    category: 'System',
    tags: ['settings', 'cog', 'edit', 'gear', 'preferences', 'tools'],
    version: '1.0',
    unicode: 'eb20',
  },
  IconShadowOff: {
    category: 'Photography',
    tags: [
      'shadow',
      'off',
      'dark',
      'sun',
      'area',
      'light',
      'css',
      'effect',
      'clear',
    ],
    version: '1.39',
    unicode: 'eed7',
  },
  IconShadow: {
    category: 'Photography',
    tags: [
      'shadow',
      'dark',
      'sun',
      'area',
      'covered',
      'dim',
      'light',
      'css',
      'effect',
    ],
    version: '1.39',
    unicode: 'eed8',
  },
  IconShape2: {
    category: 'Design',
    tags: ['shape', '2', 'draw', 'square', 'form', 'create', 'outline'],
    version: '1.39',
    unicode: 'eed9',
  },
  IconShape3: {
    category: 'Design',
    tags: ['shape', '3', 'draw', 'square', 'form', 'create', 'outline'],
    version: '1.39',
    unicode: 'eeda',
  },
  IconShape: {
    category: 'Design',
    tags: ['shape', 'draw', 'square', 'form', 'create', 'outline'],
    version: '1.3',
    unicode: 'eb9c',
  },
  IconShare: {
    category: '',
    tags: ['share', 'network', 'link', 'connection'],
    version: '1.0',
    unicode: 'eb21',
  },
  IconShieldCheck: {
    category: 'System',
    tags: ['shield', 'check', 'safety', 'protect', 'protection', 'yes', 'add'],
    version: '1.0',
    unicode: 'eb22',
  },
  IconShieldCheckered: {
    category: 'System',
    tags: ['shield', 'checkered'],
    version: '1.47',
    unicode: 'ef9a',
  },
  IconShieldChevron: {
    category: 'System',
    tags: ['shield', 'chevron'],
    version: '1.47',
    unicode: 'ef9b',
  },
  IconShieldLock: {
    category: 'System',
    tags: ['shield', 'lock', 'secure', 'security', 'closed', 'key', 'safety'],
    version: '1.30',
    unicode: 'ed58',
  },
  IconShieldOff: {
    category: 'System',
    tags: ['shield', 'off', 'unprotected', 'protection', 'cancel', 'false'],
    version: '1.22',
    unicode: 'ecf8',
  },
  IconShieldX: {
    category: 'System',
    tags: ['shield', 'x', 'unprotected', 'protection', 'cancel', 'no'],
    version: '1.0',
    unicode: 'eb23',
  },
  IconShield: {
    category: 'System',
    tags: ['shield', 'safety', 'protect', 'protection'],
    version: '1.0',
    unicode: 'eb24',
  },
  IconShip: {
    category: 'Vehicles',
    tags: [
      'ship',
      'sail',
      'sail across',
      'ocean',
      'river',
      'lake',
      'sea',
      'sailor',
      'journey',
      'transit',
      'manufactures',
      'containers',
    ],
    version: '1.14',
    unicode: 'ec84',
  },
  IconShirt: {
    category: '',
    tags: ['shirt', 'gear', 'outfit', 'mocker'],
    version: '1.8',
    unicode: 'ec0a',
  },
  IconShoe: {
    category: '',
    tags: ['shoe'],
    version: '1.50',
    unicode: 'efd2',
  },
  IconShoppingCartDiscount: {
    category: 'E-commerce',
    tags: [
      'shopping',
      'cart',
      'discount',
      'shop',
      'store',
      'buy',
      'purchase',
      'product',
      'bag',
      'trolley',
      'supermarket',
      'grocery',
    ],
    version: '1.39',
    unicode: 'eedb',
  },
  IconShoppingCartOff: {
    category: 'E-commerce',
    tags: [
      'shopping',
      'cart',
      'off',
      'shop',
      'store',
      'buy',
      'purchase',
      'product',
      'bag',
      'trolley',
      'supermarket',
      'grocery',
    ],
    version: '1.39',
    unicode: 'eedc',
  },
  IconShoppingCartPlus: {
    category: 'E-commerce',
    tags: [
      'shopping',
      'cart',
      'plus',
      'shop',
      'store',
      'buy',
      'purchase',
      'product',
      'bag',
      'trolley',
      'supermarket',
      'grocery',
    ],
    version: '1.39',
    unicode: 'eedd',
  },
  IconShoppingCartX: {
    category: 'E-commerce',
    tags: [
      'shopping',
      'cart',
      'x',
      'shop',
      'store',
      'buy',
      'purchase',
      'product',
      'bag',
      'trolley',
      'supermarket',
      'grocery',
    ],
    version: '1.39',
    unicode: 'eede',
  },
  IconShoppingCart: {
    category: 'E-commerce',
    tags: [
      'shopping',
      'cart',
      'shop',
      'store',
      'buy',
      'purchase',
      'product',
      'bag',
      'trolley',
      'supermarket',
      'grocery',
    ],
    version: '1.1',
    unicode: 'eb25',
  },
  IconShredder: {
    category: 'Devices',
    tags: [
      'shredder',
      'paper',
      'document',
      'destroy',
      'device',
      'office',
      'confidential',
    ],
    version: '1.39',
    unicode: 'eedf',
  },
  IconSignature: {
    category: 'Text',
    tags: [
      'signature',
      'name',
      'certficate',
      'sign',
      'edit',
      'write',
      'document',
      'writing',
    ],
    version: '1.39',
    unicode: 'eee0',
  },
  IconSitemap: {
    category: '',
    tags: ['sitemap', 'page', 'webpage', 'website', 'list', 'roadmap', 'index'],
    version: '1.3',
    unicode: 'eb9d',
  },
  IconSkateboard: {
    category: 'Vehicles',
    tags: ['skateboard', 'toy', 'vehicle', 'electrical'],
    version: '1.18',
    unicode: 'ecc2',
  },
  IconSleigh: {
    category: 'Vehicles',
    tags: ['sleigh'],
    version: '1.47',
    unicode: 'ef9c',
  },
  IconSlice: {
    category: 'Design',
    tags: ['slice', 'knife', 'cut', 'chop', 'portion', 'kitchen', 'tool'],
    version: '1.6',
    unicode: 'ebdb',
  },
  IconSlideshow: {
    category: 'Text',
    tags: [
      'slideshow',
      'photo',
      'picture',
      'video',
      'presentation',
      'camera',
      'display',
      'ad',
    ],
    version: '1.5',
    unicode: 'ebc9',
  },
  IconSmartHome: {
    category: 'Buildings',
    tags: [
      'smart',
      'home',
      'apple',
      'devices',
      'connection',
      'link',
      'WiFi',
      'bluetooth',
    ],
    version: '1.20',
    unicode: 'ecde',
  },
  IconSmokingNo: {
    category: 'Health',
    tags: ['smoking', 'no', 'ban', 'prohibition', 'cigarette', 'public place'],
    version: '1.18',
    unicode: 'ecc3',
  },
  IconSmoking: {
    category: 'Health',
    tags: ['smoking', 'cigarette', 'public place'],
    version: '1.18',
    unicode: 'ecc4',
  },
  IconSnowflake: {
    category: 'Weather',
    tags: ['snowflake', 'winter', 'weather', 'cold', 'frost'],
    version: '1.8',
    unicode: 'ec0b',
  },
  IconSoccerField: {
    category: 'Sport',
    tags: [
      'soccer',
      'field',
      'football',
      'pitch',
      'player',
      'vall',
      'goal',
      'goalkeeper',
      'kick',
      'ball',
      'score',
      'sport',
      'sportsman',
    ],
    version: '1.34',
    unicode: 'ed92',
  },
  IconSocial: {
    category: '',
    tags: ['social', 'society', 'community', 'collectivity'],
    version: '1.7',
    unicode: 'ebec',
  },
  IconSock: {
    category: '',
    tags: [
      'sock',
      'clothing',
      'clothes',
      'foot',
      'feet',
      'leg',
      'knit',
      'wool',
      'cotton',
      'ankle',
    ],
    version: '1.39',
    unicode: 'eee1',
  },
  IconSofa: {
    category: '',
    tags: ['sofa'],
    version: '1.48',
    unicode: 'efaf',
  },
  IconSortAscending2: {
    category: 'Text',
    tags: ['sort', 'ascending', '2', 'filter', 'classify', 'arrange', 'order'],
    version: '1.39',
    unicode: 'eee2',
  },
  IconSortAscendingLetters: {
    category: 'Text',
    tags: [
      'sort',
      'ascending',
      'letters',
      'filter',
      'classify',
      'arrange',
      'order',
    ],
    version: '1.40',
    unicode: 'ef18',
  },
  IconSortAscendingNumbers: {
    category: 'Text',
    tags: [
      'sort',
      'ascending',
      'numbers',
      'filter',
      'classify',
      'arrange',
      'order',
    ],
    version: '1.40',
    unicode: 'ef19',
  },
  IconSortAscending: {
    category: 'Text',
    tags: ['sort', 'ascending', 'filter', 'classify', 'arrange', 'order'],
    version: '1.0',
    unicode: 'eb26',
  },
  IconSortDescending2: {
    category: 'Text',
    tags: ['sort', 'descending', '2', 'filter', 'classify', 'arrange', 'order'],
    version: '1.39',
    unicode: 'eee3',
  },
  IconSortDescendingLetters: {
    category: 'Text',
    tags: [
      'sort',
      'descending',
      'letters',
      'filter',
      'classify',
      'arrange',
      'order',
    ],
    version: '1.40',
    unicode: 'ef1a',
  },
  IconSortDescendingNumbers: {
    category: 'Text',
    tags: [
      'sort',
      'descending',
      'numbers',
      'filter',
      'classify',
      'arrange',
      'order',
    ],
    version: '1.40',
    unicode: 'ef1b',
  },
  IconSortDescending: {
    category: 'Text',
    tags: ['sort', 'descending', 'filter', 'classify', 'arrange', 'order'],
    version: '1.0',
    unicode: 'eb27',
  },
  IconSoup: {
    category: 'Food',
    tags: ['soup'],
    version: '1.41',
    unicode: 'ef2e',
  },
  IconSpace: {
    category: 'Text',
    tags: ['space', 'keyboard', 'type', 'gap'],
    version: '1.8',
    unicode: 'ec0c',
  },
  IconSpacingHorizontal: {
    category: '',
    tags: ['spacing', 'horizontal'],
    version: '1.43',
    unicode: 'ef54',
  },
  IconSpacingVertical: {
    category: '',
    tags: ['spacing', 'vertical'],
    version: '1.43',
    unicode: 'ef55',
  },
  IconSpade: {
    category: 'Shapes',
    tags: ['spade'],
    version: '1.52',
    unicode: 'effa',
  },
  IconSpeakerphone: {
    category: 'Media',
    tags: [
      'speakerphone',
      'voice',
      'loud',
      'microphone',
      'loudspeaker',
      'event',
      'protest',
      'speaker',
      'shout',
      'listen',
    ],
    version: '1.31',
    unicode: 'ed61',
  },
  IconSpeedboat: {
    category: 'Vehicles',
    tags: [
      'speedboat',
      'motorboat',
      'holiday',
      'sea',
      'ocean',
      'engine',
      'travel',
      'lake',
      'summer',
    ],
    version: '1.34',
    unicode: 'ed93',
  },
  IconSportBillard: {
    category: 'Sport',
    tags: ['sport', 'billard', 'pool', 'game', 'ball', 'pub', 'entertainment'],
    version: '1.39',
    unicode: 'eee4',
  },
  IconSquare0: {
    category: 'Numbers',
    tags: [
      'square',
      '0',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'eee5',
  },
  IconSquare1: {
    category: 'Numbers',
    tags: [
      'square',
      '1',
      'one',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'eee6',
  },
  IconSquare2: {
    category: 'Numbers',
    tags: [
      'square',
      '2',
      'two',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'eee7',
  },
  IconSquare3: {
    category: 'Numbers',
    tags: [
      'square',
      '3',
      'three',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'eee8',
  },
  IconSquare4: {
    category: 'Numbers',
    tags: [
      'square',
      '4',
      'four',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'eee9',
  },
  IconSquare5: {
    category: 'Numbers',
    tags: [
      'square',
      '5',
      'five',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'eeea',
  },
  IconSquare6: {
    category: 'Numbers',
    tags: [
      'square',
      '6',
      'six',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'eeeb',
  },
  IconSquare7: {
    category: 'Numbers',
    tags: [
      'square',
      '7',
      'seven',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'eeec',
  },
  IconSquare8: {
    category: 'Numbers',
    tags: [
      'square',
      '8',
      'eight',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'eeed',
  },
  IconSquare9: {
    category: 'Numbers',
    tags: [
      'square',
      '9',
      'nine',
      'zero',
      'number',
      'digit',
      'quantity',
      'amount',
      'order',
      'maths',
      'sum',
      'total',
    ],
    version: '1.39',
    unicode: 'eeee',
  },
  IconSquareAsterisk: {
    category: '',
    tags: ['square', 'asterisk'],
    version: '1.54',
    unicode: 'f01a',
  },
  IconSquareCheck: {
    category: '',
    tags: ['square', 'check', 'checkbox', 'yes'],
    version: '1.0',
    unicode: 'eb28',
  },
  IconSquareDot: {
    category: '',
    tags: ['square', 'dot', 'corner', 'shape', 'element', 'point'],
    version: '1.30',
    unicode: 'ed59',
  },
  IconSquareForbid2: {
    category: '',
    tags: ['square', 'forbid', '2', 'box', 'disabled', 'false', 'block'],
    version: '1.30',
    unicode: 'ed5a',
  },
  IconSquareForbid: {
    category: '',
    tags: ['square', 'forbid', 'box', 'disabled', 'false', 'block'],
    version: '1.30',
    unicode: 'ed5b',
  },
  IconSquareHalf: {
    category: 'Design',
    tags: ['square', 'half'],
    version: '1.52',
    unicode: 'effb',
  },
  IconSquareMinus: {
    category: '',
    tags: ['square', 'minus', 'remove', 'indeterminate'],
    version: '1.0',
    unicode: 'eb29',
  },
  IconSquareOff: {
    category: 'Shapes',
    tags: ['square', 'off', 'box', 'disabled', 'block'],
    version: '1.39',
    unicode: 'eeef',
  },
  IconSquarePlus: {
    category: '',
    tags: ['square', 'plus', 'add', 'create', 'new'],
    version: '1.0',
    unicode: 'eb2a',
  },
  IconSquareRoot2: {
    category: 'Math',
    tags: [
      'square',
      'root',
      '2',
      'mathematics',
      'maths',
      'science',
      'calculate',
      'calculator',
      'algebra',
    ],
    version: '1.39',
    unicode: 'eef0',
  },
  IconSquareRoot: {
    category: 'Math',
    tags: [
      'square',
      'root',
      'mathematics',
      'maths',
      'science',
      'calculate',
      'calculator',
      'algebra',
    ],
    version: '1.39',
    unicode: 'eef1',
  },
  IconSquareRotatedForbid2: {
    category: '',
    tags: ['square', 'rotated', 'forbid', '2'],
    version: '1.54',
    unicode: 'f01b',
  },
  IconSquareRotatedForbid: {
    category: '',
    tags: ['square', 'rotated', 'forbid'],
    version: '1.54',
    unicode: 'f01c',
  },
  IconSquareRotatedOff: {
    category: 'Shapes',
    tags: ['square', 'rotated', 'off', 'box', 'disabled', 'block'],
    version: '1.39',
    unicode: 'eef2',
  },
  IconSquareRotated: {
    category: 'Shapes',
    tags: ['square', 'rotated'],
    version: '1.20',
    unicode: 'ecdf',
  },
  IconSquareToggleHorizontal: {
    category: 'Design',
    tags: ['square', 'toggle', 'horizontal', 'box', 'clone', 'move'],
    version: '1.39',
    unicode: 'eef3',
  },
  IconSquareToggle: {
    category: 'Design',
    tags: ['square', 'toggle', 'box', 'clone', 'move'],
    version: '1.39',
    unicode: 'eef4',
  },
  IconSquareX: {
    category: '',
    tags: [
      'square',
      'x',
      'cancel',
      'close',
      'delete',
      'remove',
      'times',
      'clear',
      'no',
    ],
    version: '1.0',
    unicode: 'eb2b',
  },
  IconSquare: {
    category: 'Shapes',
    tags: ['square', 'checkbox'],
    version: '1.0',
    unicode: 'eb2c',
  },
  IconSquaresDiagonal: {
    category: 'Design',
    tags: ['squares', 'diagonal', 'boxes', 'layers'],
    version: '1.39',
    unicode: 'eef5',
  },
  IconSquaresFilled: {
    category: 'Design',
    tags: ['squares', 'filled', 'boxes', 'layers'],
    version: '1.39',
    unicode: 'eef6',
  },
  IconStack2: {
    category: 'Design',
    tags: ['stack', '2', 'pile', 'elements', 'layout', 'wrap'],
    version: '1.39',
    unicode: 'eef7',
  },
  IconStack3: {
    category: 'Design',
    tags: ['stack', '3'],
    version: '1.47',
    unicode: 'ef9d',
  },
  IconStack: {
    category: 'Design',
    tags: ['stack', 'pile', 'elements', 'layout', 'wrap'],
    version: '1.1',
    unicode: 'eb2d',
  },
  IconStairsDown: {
    category: 'Map',
    tags: [
      'stairs',
      'down',
      'building',
      'step',
      'floor',
      'staircase',
      'clamber',
    ],
    version: '1.17',
    unicode: 'eca4',
  },
  IconStairsUp: {
    category: 'Map',
    tags: [
      'stairs',
      'up',
      'building',
      'step',
      'floor',
      'staircase',
      'entryway',
    ],
    version: '1.17',
    unicode: 'eca5',
  },
  IconStairs: {
    category: 'Map',
    tags: ['stairs', 'building', 'step', 'floor', 'staircase'],
    version: '1.17',
    unicode: 'eca6',
  },
  IconStarHalf: {
    category: 'System',
    tags: ['star', 'half', 'favorite', 'like', 'mark', 'bookmark', 'grade'],
    version: '1.24',
    unicode: 'ed19',
  },
  IconStarOff: {
    category: 'System',
    tags: ['star', 'off', 'favorite', 'like', 'mark', 'bookmark', 'grade'],
    version: '1.31',
    unicode: 'ed62',
  },
  IconStar: {
    category: 'System',
    tags: ['star', 'favorite', 'like', 'mark', 'bookmark', 'grade'],
    version: '1.0',
    unicode: 'eb2e',
  },
  IconStars: {
    category: 'System',
    tags: [
      'stars',
      'favorite',
      'like',
      'mark',
      'grade',
      'bookmark',
      'grade',
      'space',
      'universe',
      'extraterrestrial',
      'galaxy',
    ],
    version: '1.27',
    unicode: 'ed38',
  },
  IconSteeringWheel: {
    category: 'Vehicles',
    tags: [
      'steering',
      'wheel',
      'drive',
      'vehicle',
      'direction',
      'turn',
      'holding',
      'racing',
    ],
    version: '1.13',
    unicode: 'ec7b',
  },
  IconStepInto: {
    category: 'Arrows',
    tags: ['step', 'into', 'vector', 'placement', 'among', 'within'],
    version: '1.20',
    unicode: 'ece0',
  },
  IconStepOut: {
    category: 'Arrows',
    tags: ['step', 'out', 'vector', 'placement', 'outside', 'except'],
    version: '1.20',
    unicode: 'ece1',
  },
  IconStethoscope: {
    category: 'Health',
    tags: [
      'stethoscope',
      'doctor',
      'medical',
      'physician',
      'test',
      'examination',
      'health',
      'illness',
      'sickness',
      'scrutiny',
      'test',
      'hospital',
    ],
    version: '1.33',
    unicode: 'edbe',
  },
  IconSticker: {
    category: '',
    tags: ['sticker', 'label', 'stamp', 'adhesive'],
    version: '1.0',
    unicode: 'eb2f',
  },
  IconStrikethrough: {
    category: 'Text',
    tags: [
      'strikethrough',
      'typography',
      'horizontal',
      'deleted',
      'removed',
      'unimportant',
    ],
    version: '1.3',
    unicode: 'eb9e',
  },
  IconSubmarine: {
    category: 'Vehicles',
    tags: ['submarine', 'sea', 'ocean', 'underwater', 'shipwater', 'war'],
    version: '1.34',
    unicode: 'ed94',
  },
  IconSubscript: {
    category: 'Text',
    tags: ['subscript', 'typography', 'below', 'formula', 'maths', 'fraction'],
    version: '1.3',
    unicode: 'eb9f',
  },
  IconSubtask: {
    category: '',
    tags: ['subtask', 'management', 'break down', 'work'],
    version: '1.16',
    unicode: 'ec9f',
  },
  IconSum: {
    category: 'Math',
    tags: ['sum', 'equation', 'add', 'plus', 'amount', 'total'],
    version: '1.2',
    unicode: 'eb73',
  },
  IconSunOff: {
    category: 'Weather',
    tags: ['sun', 'off', 'dark', 'darkmode', 'summer', 'light', 'brightness'],
    version: '1.31',
    unicode: 'ed63',
  },
  IconSun: {
    category: 'Weather',
    tags: ['sun', 'weather', 'light', 'mode', 'brightness'],
    version: '1.0',
    unicode: 'eb30',
  },
  IconSunrise: {
    category: 'Weather',
    tags: ['sunrise', 'west', 'horizon', 'landscape', 'evening'],
    version: '1.10',
    unicode: 'ef1c',
  },
  IconSunset: {
    category: 'Weather',
    tags: ['sunset', 'east', 'horizon', 'landscape', 'morning'],
    version: '1.10',
    unicode: 'ec31',
  },
  IconSuperscript: {
    category: 'Text',
    tags: [
      'superscript',
      'typography',
      'above',
      'maths',
      'fraction',
      'trademark',
      'footer',
    ],
    version: '1.3',
    unicode: 'eba0',
  },
  IconSwimming: {
    category: 'Sport',
    tags: [
      'swimming',
      'sport',
      'water',
      'pool',
      'style',
      'athletics',
      'competitive',
    ],
    version: '1.14',
    unicode: 'ec92',
  },
  IconSwitch2: {
    category: '',
    tags: ['switch', '2'],
    version: '1.35',
    unicode: 'edbf',
  },
  IconSwitch3: {
    category: '',
    tags: ['switch', '3'],
    version: '1.35',
    unicode: 'edc0',
  },
  IconSwitchHorizontal: {
    category: 'Arrows',
    tags: ['switch', 'horizontal', 'toggle', 'left', 'right', 'arrows'],
    version: '1.0',
    unicode: 'eb31',
  },
  IconSwitchVertical: {
    category: 'Arrows',
    tags: ['switch', 'vertical', 'toggle', 'up', 'down', 'arrows'],
    version: '1.0',
    unicode: 'eb32',
  },
  IconSwitch: {
    category: 'Arrows',
    tags: ['switch', 'toggle', 'arrows'],
    version: '1.0',
    unicode: 'eb33',
  },
  IconSword: {
    category: '',
    tags: ['sword'],
    version: '1.55',
    unicode: 'f030',
  },
  IconTableExport: {
    category: 'Database',
    tags: [
      'table',
      'export',
      'spreadsheet',
      'layout',
      'grid',
      'arrange',
      'row',
      'column',
      'cells',
      'sheet',
      'arrow',
    ],
    version: '1.39',
    unicode: 'eef8',
  },
  IconTableImport: {
    category: 'Database',
    tags: [
      'table',
      'import',
      'spreadsheet',
      'layout',
      'grid',
      'arrange',
      'row',
      'column',
      'cells',
      'sheet',
      'arrow',
    ],
    version: '1.39',
    unicode: 'eef9',
  },
  IconTableOff: {
    category: 'Database',
    tags: [
      'table',
      'off',
      'spreadsheet',
      'layout',
      'grid',
      'arrange',
      'row',
      'column',
    ],
    version: '1.39',
    unicode: 'eefa',
  },
  IconTable: {
    category: 'Database',
    tags: [
      'table',
      'spreadsheet',
      'layout',
      'grid',
      'arrange',
      'row',
      'column',
    ],
    version: '1.3',
    unicode: 'eba1',
  },
  IconTagOff: {
    category: '',
    tags: ['tag', 'off'],
    version: '1.49',
    unicode: 'efc0',
  },
  IconTag: {
    category: '',
    tags: ['tag', 'label', 'price'],
    version: '1.0',
    unicode: 'eb34',
  },
  IconTagsOff: {
    category: '',
    tags: ['tags', 'off'],
    version: '1.49',
    unicode: 'efc1',
  },
  IconTags: {
    category: '',
    tags: ['tags'],
    version: '1.46',
    unicode: 'ef86',
  },
  IconTallymark1: {
    category: 'Math',
    tags: [
      'tallymark',
      '1',
      'sign',
      'symbol',
      'numerical',
      'consistent',
      'system',
      'counting',
    ],
    version: '1.11',
    unicode: 'ec46',
  },
  IconTallymark2: {
    category: 'Math',
    tags: [
      'tallymark',
      '2',
      'sign',
      'symbol',
      'numerical',
      'consistent',
      'system',
      'counting',
    ],
    version: '1.11',
    unicode: 'ec47',
  },
  IconTallymark3: {
    category: 'Math',
    tags: [
      'tallymark',
      '3',
      'sign',
      'symbol',
      'numerical',
      'consistent',
      'system',
      'counting',
    ],
    version: '1.11',
    unicode: 'ec48',
  },
  IconTallymark4: {
    category: 'Math',
    tags: [
      'tallymark',
      '4',
      'sign',
      'symbol',
      'numerical',
      'consistent',
      'system',
      'counting',
    ],
    version: '1.11',
    unicode: 'ec49',
  },
  IconTallymarks: {
    category: 'Math',
    tags: [
      'tallymarks',
      'sign',
      'symbol',
      'numerical',
      'consistent',
      'system',
      'counting',
    ],
    version: '1.11',
    unicode: 'ec4a',
  },
  IconTank: {
    category: 'Vehicles',
    tags: [
      'tank',
      'war',
      'military',
      'armour',
      'vehicle',
      'gun',
      'attack',
      'shoot',
      'battle',
      'battlefield',
    ],
    version: '1.34',
    unicode: 'ed95',
  },
  IconTarget: {
    category: 'Map',
    tags: ['target', 'focus', 'bullseye', 'aim'],
    version: '1.0',
    unicode: 'eb35',
  },
  IconTemperatureCelsius: {
    category: 'Weather',
    tags: [
      'temperature',
      'celsius',
      'weather',
      'celcius',
      'fahrenheit',
      'cold',
      'hot',
    ],
    version: '1.1',
    unicode: 'eb36',
  },
  IconTemperatureFahrenheit: {
    category: 'Weather',
    tags: [
      'temperature',
      'fahrenheit',
      'weather',
      'celcius',
      'fahrenheit',
      'cold',
      'hot',
    ],
    version: '1.1',
    unicode: 'eb37',
  },
  IconTemperatureMinus: {
    category: 'Weather',
    tags: [
      'temperature',
      'minus',
      'weather',
      'celcius',
      'fahrenheit',
      'cold',
      'hot',
    ],
    version: '1.7',
    unicode: 'ebed',
  },
  IconTemperaturePlus: {
    category: 'Weather',
    tags: [
      'temperature',
      'plus',
      'weather',
      'celcius',
      'fahrenheit',
      'cold',
      'hot',
    ],
    version: '1.7',
    unicode: 'ebee',
  },
  IconTemperature: {
    category: 'Weather',
    tags: ['temperature', 'weather', 'celcius', 'fahrenheit', 'cold', 'hot'],
    version: '1.1',
    unicode: 'eb38',
  },
  IconTemplate: {
    category: 'Design',
    tags: ['template', 'grid', 'columns', 'masonry', 'collage'],
    version: '1.1',
    unicode: 'eb39',
  },
  IconTent: {
    category: '',
    tags: [
      'tent',
      'camping',
      'holiday',
      'vacation',
      'outdoor',
      'survival',
      'travel',
      'adventure',
    ],
    version: '1.39',
    unicode: 'eefb',
  },
  IconTerminal2: {
    category: '',
    tags: [
      'terminal',
      '2',
      'console',
      'command',
      'git',
      'command line',
      'command prompt',
    ],
    version: '1.7',
    unicode: 'ebef',
  },
  IconTerminal: {
    category: '',
    tags: [
      'terminal',
      'console',
      'command',
      'git',
      'command line',
      'command prompt',
    ],
    version: '1.6',
    unicode: 'ebdc',
  },
  IconTestPipe: {
    category: '',
    tags: ['test', 'pipe', 'sample', 'color'],
    version: '1.0',
    unicode: 'eb3a',
  },
  IconTextDirectionLtr: {
    category: 'Text',
    tags: ['text', 'direction', 'ltr', 'left', 'right', 'bidi'],
    version: '1.39',
    unicode: 'eefc',
  },
  IconTextDirectionRtl: {
    category: 'Text',
    tags: ['text', 'direction', 'rtl', 'left', 'right', 'bidi'],
    version: '1.39',
    unicode: 'eefd',
  },
  IconTextResize: {
    category: 'Design',
    tags: ['text', 'resize'],
    version: '1.46',
    unicode: 'ef87',
  },
  IconTextWrapDisabled: {
    category: 'Text',
    tags: ['text', 'wrap', 'disabled', 'text', 'alignment', 'position'],
    version: '1.17',
    unicode: 'eca7',
  },
  IconTextWrap: {
    category: 'Text',
    tags: ['text', 'wrap', 'text', 'alignment', 'position'],
    version: '1.6',
    unicode: 'ebdd',
  },
  IconThermometer: {
    category: 'Health',
    tags: ['thermometer'],
    version: '1.44',
    unicode: 'ef67',
  },
  IconThumbDown: {
    category: 'System',
    tags: ['thumb', 'down', 'dislike', 'bad', 'emotion'],
    version: '1.0',
    unicode: 'eb3b',
  },
  IconThumbUp: {
    category: 'System',
    tags: ['thumb', 'up', 'like', 'emotion', 'good', 'love'],
    version: '1.0',
    unicode: 'eb3c',
  },
  IconTicket: {
    category: '',
    tags: [
      'ticket',
      'cinema',
      'event',
      'theatre',
      'entry',
      'fine',
      'coupon',
      'pass',
    ],
    version: '1.0',
    unicode: 'eb3d',
  },
  IconTiltShift: {
    category: 'Photography',
    tags: ['tilt', 'shift', 'filter', 'shift', 'photography', 'photo'],
    version: '1.39',
    unicode: 'eefe',
  },
  IconTimeline: {
    category: '',
    tags: ['timeline'],
    version: '1.55',
    unicode: 'f031',
  },
  IconTir: {
    category: 'Vehicles',
    tags: [
      'tir',
      'delivery',
      'transportation',
      'transport',
      'logistics',
      'vehicle',
      'goods',
    ],
    version: '1.7',
    unicode: 'ebf0',
  },
  IconToggleLeft: {
    category: 'System',
    tags: ['toggle', 'left', 'on', 'off', 'switch'],
    version: '1.0',
    unicode: 'eb3e',
  },
  IconToggleRight: {
    category: 'System',
    tags: ['toggle', 'right', 'on', 'off', 'switch'],
    version: '1.0',
    unicode: 'eb3f',
  },
  IconToiletPaper: {
    category: '',
    tags: ['toilet', 'paper'],
    version: '1.50',
    unicode: 'efd3',
  },
  IconTool: {
    category: '',
    tags: ['tool', 'preferences', 'edit', 'settings'],
    version: '1.0',
    unicode: 'eb40',
  },
  IconToolsKitchen2: {
    category: 'Map',
    tags: [
      'tools',
      'kitchen',
      '2',
      'knife',
      'fork',
      'spoon',
      'cutlery',
      'eat',
      'restaurant',
      'menu',
      'cafe',
      'cook',
      'cut',
      'soup',
      'dinner',
      'breakfast',
      'dining',
      'plate',
      'dish',
    ],
    version: '1.39',
    unicode: 'eeff',
  },
  IconToolsKitchen: {
    category: 'Map',
    tags: [
      'tools',
      'kitchen',
      'knife',
      'fork',
      'spoon',
      'cutlery',
      'eat',
      'restaurant',
      'menu',
      'cafe',
      'cook',
      'cut',
      'soup',
      'dinner',
      'breakfast',
      'dining',
      'plate',
      'dish',
    ],
    version: '1.31',
    unicode: 'ed64',
  },
  IconTools: {
    category: 'Design',
    tags: ['tools', 'preferences', 'edit', 'settings'],
    version: '1.5',
    unicode: 'ebca',
  },
  IconTornado: {
    category: 'Weather',
    tags: [
      'tornado',
      'wind',
      'rotate',
      'storm',
      'spin',
      'spinning',
      'air',
      'catastrophe',
      'vortex',
    ],
    version: '1.20',
    unicode: 'ece2',
  },
  IconTournament: {
    category: '',
    tags: [
      'tournament',
      'competition',
      'competitor',
      'sport',
      'game',
      'play',
      'champion',
    ],
    version: '1.19',
    unicode: 'ecd0',
  },
  IconTrack: {
    category: 'Vehicles',
    tags: ['track', 'trail', 'path', 'route', 'train', 'railway', 'railroad'],
    version: '1.39',
    unicode: 'ef00',
  },
  IconTractor: {
    category: 'Vehicles',
    tags: [
      'tractor',
      'countryside',
      'vehicle',
      'harvest',
      'machine',
      'motor',
      'farm',
      'trailer',
    ],
    version: '1.8',
    unicode: 'ec0d',
  },
  IconTrademark: {
    category: 'Symbols',
    tags: [
      'trademark',
      'legal',
      'product',
      'company',
      'own',
      'ownership',
      'brand',
      'law',
      'right',
      'certificate',
    ],
    version: '1.8',
    unicode: 'ec0e',
  },
  IconTrafficCone: {
    category: 'Map',
    tags: [
      'traffic',
      'cone',
      'street',
      'road',
      'vehicle',
      'repair',
      'warning',
      'lane',
      'drive',
    ],
    version: '1.8',
    unicode: 'ec0f',
  },
  IconTrafficLights: {
    category: 'Map',
    tags: [
      'traffic',
      'lights',
      'street',
      'road',
      'green',
      'red',
      'yellow',
      'vehicle',
      'stop',
      'drive',
      'crossing',
      'pedestrian',
      'crossroads',
      'junction',
      'intersection',
    ],
    version: '1.27',
    unicode: 'ed39',
  },
  IconTrain: {
    category: 'Vehicles',
    tags: [
      'train',
      'railway',
      'rails',
      'tgv',
      'journey',
      'travel',
      'network',
      'route',
      'passenger',
    ],
    version: '1.34',
    unicode: 'ed96',
  },
  IconTransferIn: {
    category: 'E-commerce',
    tags: ['transfer', 'in'],
    version: '1.41',
    unicode: 'ef2f',
  },
  IconTransferOut: {
    category: 'E-commerce',
    tags: ['transfer', 'out'],
    version: '1.41',
    unicode: 'ef30',
  },
  IconTrashOff: {
    category: 'System',
    tags: [
      'trash',
      'off',
      'bin',
      'litter',
      'recycle',
      'remove',
      'delete',
      'throw',
      'away',
      'waste',
    ],
    version: '1.31',
    unicode: 'ed65',
  },
  IconTrashX: {
    category: '',
    tags: ['trash', 'x'],
    version: '1.46',
    unicode: 'ef88',
  },
  IconTrash: {
    category: 'System',
    tags: [
      'trash',
      'garbage',
      'delete',
      'remove',
      'bin',
      'ash-bin',
      'uninstall',
      'dustbin',
    ],
    version: '1.0',
    unicode: 'eb41',
  },
  IconTree: {
    category: 'Map',
    tags: [
      'tree',
      'nature',
      'greenery',
      'park',
      'leaf',
      'trunk',
      'stem',
      'root',
      'forest',
      'garden',
    ],
    version: '1.39',
    unicode: 'ef01',
  },
  IconTrees: {
    category: 'Map',
    tags: [
      'trees',
      'nature',
      'greenery',
      'park',
      'leaf',
      'trunk',
      'stem',
      'root',
      'forest',
      'garden',
    ],
    version: '1.35',
    unicode: 'ec10',
  },
  IconTrendingDown2: {
    category: 'Arrows',
    tags: ['trending', 'down', '2', 'arrow', 'decrease', 'fall'],
    version: '1.35',
    unicode: 'edc1',
  },
  IconTrendingDown3: {
    category: 'Arrows',
    tags: ['trending', 'down', '3', 'arrow', 'decrease', 'fall'],
    version: '1.35',
    unicode: 'edc2',
  },
  IconTrendingDown: {
    category: 'Arrows',
    tags: ['trending', 'down', 'arrow', 'decrease', 'fall'],
    version: '1.0',
    unicode: 'eb42',
  },
  IconTrendingUp2: {
    category: 'Arrows',
    tags: ['trending', 'up', '2', 'arrow', 'grow', 'increase'],
    version: '1.35',
    unicode: 'edc3',
  },
  IconTrendingUp3: {
    category: 'Arrows',
    tags: ['trending', 'up', '3', 'arrow', 'grow', 'increase'],
    version: '1.35',
    unicode: 'edc4',
  },
  IconTrendingUp: {
    category: 'Arrows',
    tags: ['trending', 'up', 'arrow', 'grow', 'increase'],
    version: '1.0',
    unicode: 'eb43',
  },
  IconTriangleInverted: {
    category: '',
    tags: ['triangle', 'inverted'],
    version: '1.54',
    unicode: 'f01d',
  },
  IconTriangleOff: {
    category: 'Shapes',
    tags: ['triangle', 'off', 'shape', 'crossed', 'angle'],
    version: '1.39',
    unicode: 'ef02',
  },
  IconTriangleSquareCircle: {
    category: 'Shapes',
    tags: [
      'triangle',
      'square',
      'circle',
      'shape',
      'geometry',
      'round',
      'angle',
    ],
    version: '1.21',
    unicode: 'ece8',
  },
  IconTriangle: {
    category: 'Shapes',
    tags: ['triangle', 'delta'],
    version: '1.0',
    unicode: 'eb44',
  },
  IconTrident: {
    category: '',
    tags: ['trident', 'three', 'spear', 'weapon', 'sharp', 'tool'],
    version: '1.18',
    unicode: 'ecc5',
  },
  IconTrophy: {
    category: '',
    tags: ['trophy', 'success', 'win', 'prize', 'winner'],
    version: '1.0',
    unicode: 'eb45',
  },
  IconTruckDelivery: {
    category: 'E-commerce',
    tags: [
      'truck',
      'delivery',
      'order',
      'purchase',
      'online',
      'shop',
      'store',
      'e-commerce',
      'lorry',
    ],
    version: '1.11',
    unicode: 'ec4b',
  },
  IconTruckOff: {
    category: 'Vehicles',
    tags: [
      'truck',
      'off',
      'order',
      'purchase',
      'online',
      'shop',
      'store',
      'e-commerce',
      'lorry',
    ],
    version: '1.39',
    unicode: 'ef03',
  },
  IconTruckReturn: {
    category: 'E-commerce',
    tags: [
      'truck',
      'return',
      'order',
      'purchase',
      'online',
      'shop',
      'store',
      'e-commerce',
      'lorry',
    ],
    version: '1.11',
    unicode: 'ec4c',
  },
  IconTruck: {
    category: 'Vehicles',
    tags: [
      'truck',
      'transport',
      'vahicle',
      'van',
      'lorry',
      'cargo',
      'delivery',
    ],
    version: '1.5',
    unicode: 'ebc4',
  },
  IconTypography: {
    category: 'Text',
    tags: [
      'typography',
      'type',
      'display',
      'typeface',
      'point size',
      'line length',
      'line-spacing',
      'letter-spacing',
      'font',
    ],
    version: '1.5',
    unicode: 'ebc5',
  },
  IconUmbrella: {
    category: '',
    tags: ['umbrella', 'rain', 'weather', 'storm', 'wet', 'autumn', 'fall'],
    version: '1.7',
    unicode: 'ebf1',
  },
  IconUnderline: {
    category: 'Text',
    tags: ['underline', 'underscore', 'emphasis', 'horizontal', 'typography'],
    version: '1.1',
    unicode: 'eba2',
  },
  IconUnlink: {
    category: 'Text',
    tags: ['unlink', 'chain', 'url', 'address', 'remove', 'broke', 'unconnect'],
    version: '1.0',
    unicode: 'eb46',
  },
  IconUpload: {
    category: 'Arrows',
    tags: ['upload', 'file', 'arrow'],
    version: '1.0',
    unicode: 'eb47',
  },
  IconUrgent: {
    category: '',
    tags: ['urgent', 'alert', 'important'],
    version: '1.0',
    unicode: 'eb48',
  },
  IconUsb: {
    category: '',
    tags: ['usb'],
    version: '1.53',
    unicode: 'f00c',
  },
  IconUserCheck: {
    category: '',
    tags: ['user', 'check', 'tick', 'person', 'account'],
    version: '1.0',
    unicode: 'eb49',
  },
  IconUserCircle: {
    category: '',
    tags: ['user', 'circle'],
    version: '1.44',
    unicode: 'ef68',
  },
  IconUserExclamation: {
    category: '',
    tags: [
      'user',
      'exclamation',
      'user',
      'account',
      'note',
      'excitement',
      'admiration',
      'mark',
    ],
    version: '1.8',
    unicode: 'ec12',
  },
  IconUserMinus: {
    category: '',
    tags: [
      'user',
      'minus',
      'remove',
      'cancel',
      'person',
      'account',
      'unsubscribe',
    ],
    version: '1.0',
    unicode: 'eb4a',
  },
  IconUserOff: {
    category: '',
    tags: ['user', 'off', 'person', 'account'],
    version: '1.22',
    unicode: 'ecf9',
  },
  IconUserPlus: {
    category: '',
    tags: [
      'user',
      'plus',
      'add',
      'create',
      'new',
      'person',
      'people',
      'follow',
      'subscribe',
    ],
    version: '1.0',
    unicode: 'eb4b',
  },
  IconUserSearch: {
    category: '',
    tags: ['user', 'search'],
    version: '1.46',
    unicode: 'ef89',
  },
  IconUserX: {
    category: '',
    tags: ['user', 'x', 'cancel', 'remove', 'person', 'account', 'unsubscribe'],
    version: '1.0',
    unicode: 'eb4c',
  },
  IconUser: {
    category: '',
    tags: ['user', 'person', 'account'],
    version: '1.0',
    unicode: 'eb4d',
  },
  IconUsers: {
    category: '',
    tags: ['users', 'people', 'persons', 'accounts'],
    version: '1.7',
    unicode: 'ebf2',
  },
  IconVaccineBottle: {
    category: 'Health',
    tags: ['vaccine', 'bottle'],
    version: '1.44',
    unicode: 'ef69',
  },
  IconVaccine: {
    category: 'Health',
    tags: [
      'vaccine',
      'illness',
      'sickness',
      'disease',
      'injection',
      'medicine',
      'medical',
      'doctor',
      'nurse',
    ],
    version: '1.39',
    unicode: 'ef04',
  },
  IconVariable: {
    category: 'Math',
    tags: [
      'variable',
      'maths',
      'mathematics',
      'science',
      'calculate',
      'function',
    ],
    version: '1.39',
    unicode: 'ef05',
  },
  IconVectorBeizer2: {
    category: 'Design',
    tags: [
      'vector',
      'beizer',
      '2',
      'curve',
      'parametric',
      'design',
      'vector graphics',
      'representation',
    ],
    version: '1.27',
    unicode: 'ed3a',
  },
  IconVectorBezier: {
    category: 'Design',
    tags: [
      'vector',
      'bezier',
      'curve',
      'parametric',
      'design',
      'vector graphics',
      'representation',
    ],
    version: '1.27',
    unicode: 'ef1d',
  },
  IconVectorTriangle: {
    category: 'Design',
    tags: [
      'vector',
      'triangle',
      'curve',
      'parametric',
      'design',
      'vector graphics',
      'placement',
    ],
    version: '1.17',
    unicode: 'eca8',
  },
  IconVector: {
    category: 'Design',
    tags: [
      'vector',
      'curve',
      'parametric',
      'design',
      'vector graphics',
      'placement',
    ],
    version: '1.17',
    unicode: 'eca9',
  },
  IconVenus: {
    category: 'Symbols',
    tags: ['venus', 'female'],
    version: '1.14',
    unicode: 'ec86',
  },
  IconVersions: {
    category: '',
    tags: [
      'versions',
      'app',
      'variation',
      'different',
      'variant',
      'alternative',
    ],
    version: '1.29',
    unicode: 'ed52',
  },
  IconVideoMinus: {
    category: 'Media',
    tags: [
      'video',
      'minus',
      'film',
      'shoot',
      'recording',
      'taping',
      'camera',
      'remotion',
    ],
    version: '1.25',
    unicode: 'ed1f',
  },
  IconVideoOff: {
    category: 'Media',
    tags: [
      'video',
      'off',
      'film',
      'shoot',
      'recording',
      'taping',
      'camera',
      'end',
      'complete',
    ],
    version: '1.25',
    unicode: 'ed20',
  },
  IconVideoPlus: {
    category: 'Media',
    tags: [
      'video',
      'plus',
      'film',
      'shoot',
      'recording',
      'taping',
      'camera',
      'closeup',
    ],
    version: '1.25',
    unicode: 'ed21',
  },
  IconVideo: {
    category: 'Media',
    tags: ['video', 'film', 'shoot', 'recording', 'taping', 'camera'],
    version: '1.25',
    unicode: 'ed22',
  },
  IconView360: {
    category: '',
    tags: ['view', '360', 'panoramic', 'degrees', 'image', 'around'],
    version: '1.33',
    unicode: 'ed84',
  },
  IconViewfinder: {
    category: 'Map',
    tags: ['viewfinder', 'target', 'aim', 'focus'],
    version: '1.0',
    unicode: 'eb4e',
  },
  IconViewportNarrow: {
    category: 'Devices',
    tags: ['viewport', 'narrow', 'data', 'account', 'excel', 'tight'],
    version: '1.7',
    unicode: 'ebf3',
  },
  IconViewportWide: {
    category: 'Devices',
    tags: ['viewport', 'wide', 'data', 'account', 'broad', 'excel'],
    version: '1.7',
    unicode: 'ebf4',
  },
  IconVinyl: {
    category: '',
    tags: ['vinyl'],
    version: '1.53',
    unicode: 'f00d',
  },
  IconVirusOff: {
    category: 'Health',
    tags: [
      'virus',
      'off',
      'covid',
      'coronavirus',
      'biology',
      'infection',
      'infected',
      'cell',
      'viral',
      'infectious',
      'disease',
    ],
    version: '1.31',
    unicode: 'ed66',
  },
  IconVirusSearch: {
    category: 'Health',
    tags: [
      'virus',
      'search',
      'covid',
      'coronavirus',
      'biology',
      'infection',
      'infected',
      'cell',
      'viral',
      'infectious',
      'disease',
    ],
    version: '1.31',
    unicode: 'ed67',
  },
  IconVirus: {
    category: 'Health',
    tags: ['virus', 'infection', 'illness', 'cell', 'infectious', 'health'],
    version: '1.2',
    unicode: 'eb74',
  },
  IconVocabulary: {
    category: 'Text',
    tags: ['vocabulary'],
    version: '1.40',
    unicode: 'ef1e',
  },
  IconVolume2: {
    category: 'Media',
    tags: ['volume', '2', 'music', 'sound', 'speaker'],
    version: '1.0',
    unicode: 'eb4f',
  },
  IconVolume3: {
    category: 'Media',
    tags: ['volume', '3', 'mute', 'music', 'sound', 'false', 'speaker'],
    version: '1.0',
    unicode: 'eb50',
  },
  IconVolume: {
    category: 'Media',
    tags: ['volume', 'music', 'sound', 'speaker'],
    version: '1.0',
    unicode: 'eb51',
  },
  IconWalk: {
    category: 'Sport',
    tags: [
      'walk',
      'ambulation',
      'dislocating',
      'movement',
      'motion',
      'destination',
    ],
    version: '1.14',
    unicode: 'ec87',
  },
  IconWall: {
    category: '',
    tags: ['wall'],
    version: '1.45',
    unicode: 'ef7a',
  },
  IconWallet: {
    category: '',
    tags: ['wallet', 'money', 'pay', 'banknote', 'coin', 'payment', 'bank'],
    version: '1.2',
    unicode: 'eb75',
  },
  IconWallpaper: {
    category: '',
    tags: ['wallpaper'],
    version: '1.43',
    unicode: 'ef56',
  },
  IconWand: {
    category: '',
    tags: ['wand', 'magic', 'tool', 'color', 'pixel', 'design'],
    version: '1.5',
    unicode: 'ebcb',
  },
  IconWaveSawTool: {
    category: '',
    tags: ['wave', 'saw', 'tool', 'pulse', 'signal', 'ratio', 'rate', 'volume'],
    version: '1.19',
    unicode: 'ecd3',
  },
  IconWaveSine: {
    category: '',
    tags: ['wave', 'sine', 'pulse', 'signal', 'ratio', 'rate', 'volume'],
    version: '1.19',
    unicode: 'ecd4',
  },
  IconWaveSquare: {
    category: '',
    tags: ['wave', 'square', 'pulse', 'signal', 'ratio', 'rate', 'volume'],
    version: '1.19',
    unicode: 'ecd5',
  },
  IconWebhook: {
    category: '',
    tags: ['webhook'],
    version: '1.54',
    unicode: 'f01e',
  },
  IconWifi0: {
    category: 'Devices',
    tags: ['wifi', '0', 'online', 'connection', 'signal', 'wireless'],
    version: '1.3',
    unicode: 'eba3',
  },
  IconWifi1: {
    category: 'Devices',
    tags: ['wifi', '1', 'online', 'connection', 'signal', 'wireless'],
    version: '1.3',
    unicode: 'eba4',
  },
  IconWifi2: {
    category: 'Devices',
    tags: ['wifi', '2', 'online', 'connection', 'signal', 'wireless'],
    version: '1.3',
    unicode: 'eba5',
  },
  IconWifiOff: {
    category: 'Devices',
    tags: ['wifi', 'off', 'online', 'connection', 'signal', 'wireless'],
    version: '1.22',
    unicode: 'ecfa',
  },
  IconWifi: {
    category: 'Devices',
    tags: ['wifi', 'online', 'connection', 'signal', 'wireless'],
    version: '1.0',
    unicode: 'eb52',
  },
  IconWind: {
    category: 'Weather',
    tags: [
      'wind',
      'weather',
      'breeze',
      'tornado',
      'typhoon',
      'cyclone',
      'hurricane',
    ],
    version: '1.10',
    unicode: 'ec34',
  },
  IconWindmill: {
    category: '',
    tags: ['windmill', 'generate', 'power', 'blade', 'energy', 'electricity'],
    version: '1.33',
    unicode: 'ed85',
  },
  IconWindow: {
    category: '',
    tags: [
      'window',
      'house',
      'view',
      'glass',
      'apartment',
      'vehicle',
      'light',
      'frame',
      'home',
      'building',
    ],
    version: '1.39',
    unicode: 'ef06',
  },
  IconWiperWash: {
    category: 'Vehicles',
    tags: ['wiper', 'wash', 'car', 'pane', 'vehicle', 'sprinkler', 'scour'],
    version: '1.17',
    unicode: 'ecaa',
  },
  IconWiper: {
    category: 'Vehicles',
    tags: ['wiper', 'car', 'pane', 'vehicle', 'sprinkler', 'scour'],
    version: '1.17',
    unicode: 'ecab',
  },
  IconWoman: {
    category: '',
    tags: ['woman', 'girl', 'female', 'gender'],
    version: '1.0',
    unicode: 'eb53',
  },
  IconWorldDownload: {
    category: '',
    tags: ['world', 'download'],
    version: '1.46',
    unicode: 'ef8a',
  },
  IconWorldLatitude: {
    category: 'Map',
    tags: [
      'world',
      'latitude',
      'earth',
      'globe',
      'global',
      'language',
      'union',
    ],
    version: '1.26',
    unicode: 'ed2e',
  },
  IconWorldLongitude: {
    category: 'Map',
    tags: [
      'world',
      'longitude',
      'earth',
      'globe',
      'global',
      'language',
      'union',
    ],
    version: '1.26',
    unicode: 'ed2f',
  },
  IconWorldUpload: {
    category: '',
    tags: ['world', 'upload'],
    version: '1.46',
    unicode: 'ef8b',
  },
  IconWorld: {
    category: 'Map',
    tags: ['world', 'earth', 'globe', 'global', 'language', 'union'],
    version: '1.0',
    unicode: 'eb54',
  },
  IconWreckingBall: {
    category: 'Vehicles',
    tags: [
      'wrecking',
      'ball',
      'demolish',
      'building',
      'wrecker',
      'metal',
      'swing',
      'knock',
      'down',
    ],
    version: '1.34',
    unicode: 'ed97',
  },
  IconWritingSign: {
    category: 'Text',
    tags: [
      'writing',
      'sign',
      'name',
      'certficate',
      'sign',
      'edit',
      'write',
      'document',
      'writing',
      'pen',
    ],
    version: '1.39',
    unicode: 'ef07',
  },
  IconWriting: {
    category: 'Text',
    tags: [
      'writing',
      'name',
      'certficate',
      'sign',
      'edit',
      'write',
      'document',
      'pen',
      'drawing',
      'contract',
      'signature',
    ],
    version: '1.39',
    unicode: 'ef08',
  },
  IconX: {
    category: '',
    tags: ['x', 'cancel', 'remove', 'delete', 'empty'],
    version: '1.0',
    unicode: 'eb55',
  },
  IconYinYang: {
    category: 'Symbols',
    tags: ['yin', 'yang', 'equality', 'good', 'evil', 'balance', 'peace'],
    version: '1.10',
    unicode: 'ec35',
  },
  IconYoga: {
    category: '',
    tags: ['yoga'],
    version: '1.54',
    unicode: 'f01f',
  },
  IconZodiacAquarius: {
    category: 'Zodiac',
    tags: ['zodiac', 'aquarius', 'sign', 'horoscope', 'constellation', 'stars'],
    version: '1.17',
    unicode: 'ecac',
  },
  IconZodiacAries: {
    category: 'Zodiac',
    tags: ['zodiac', 'aries', 'sign', 'horoscope', 'constellation', 'stars'],
    version: '1.17',
    unicode: 'ecad',
  },
  IconZodiacCancer: {
    category: 'Zodiac',
    tags: ['zodiac', 'cancer', 'sign', 'horoscope', 'constellation', 'stars'],
    version: '1.17',
    unicode: 'ecae',
  },
  IconZodiacCapricorn: {
    category: 'Zodiac',
    tags: [
      'zodiac',
      'capricorn',
      'sign',
      'horoscope',
      'constellation',
      'stars',
    ],
    version: '1.17',
    unicode: 'ecaf',
  },
  IconZodiacGemini: {
    category: 'Zodiac',
    tags: ['zodiac', 'gemini', 'sign', 'horoscope', 'constellation', 'stars'],
    version: '1.17',
    unicode: 'ecb0',
  },
  IconZodiacLeo: {
    category: 'Zodiac',
    tags: ['zodiac', 'leo', 'sign', 'horoscope', 'constellation', 'stars'],
    version: '1.17',
    unicode: 'ecb1',
  },
  IconZodiacLibra: {
    category: 'Zodiac',
    tags: ['zodiac', 'libra', 'sign', 'horoscope', 'constellation', 'stars'],
    version: '1.17',
    unicode: 'ecb2',
  },
  IconZodiacPisces: {
    category: 'Zodiac',
    tags: ['zodiac', 'pisces', 'sign', 'horoscope', 'constellation', 'stars'],
    version: '1.17',
    unicode: 'ecb3',
  },
  IconZodiacSagittarius: {
    category: 'Zodiac',
    tags: [
      'zodiac',
      'sagittarius',
      'sign',
      'horoscope',
      'constellation',
      'stars',
    ],
    version: '1.17',
    unicode: 'ecb4',
  },
  IconZodiacScorpio: {
    category: 'Zodiac',
    tags: ['zodiac', 'scorpio', 'sign', 'horoscope', 'constellation', 'stars'],
    version: '1.17',
    unicode: 'ecb5',
  },
  IconZodiacTaurus: {
    category: 'Zodiac',
    tags: ['zodiac', 'taurus', 'sign', 'horoscope', 'constellation', 'stars'],
    version: '1.17',
    unicode: 'ecb6',
  },
  IconZodiacVirgo: {
    category: 'Zodiac',
    tags: ['zodiac', 'virgo', 'sign', 'horoscope', 'constellation', 'stars'],
    version: '1.17',
    unicode: 'ecb7',
  },
  IconZoomCancel: {
    category: 'Map',
    tags: ['zoom', 'cancel', 'magnifying glass'],
    version: '1.11',
    unicode: 'ec4d',
  },
  IconZoomCheck: {
    category: '',
    tags: [
      'zoom',
      'check',
      'verify',
      'magnifying',
      'glass',
      'magnifier',
      'ok',
      'done',
    ],
    version: '1.39',
    unicode: 'ef09',
  },
  IconZoomIn: {
    category: 'Map',
    tags: ['zoom', 'in', 'magnifying glass'],
    version: '1.0',
    unicode: 'eb56',
  },
  IconZoomMoney: {
    category: '',
    tags: [
      'zoom',
      'money',
      'magnifying',
      'glass',
      'magnifier',
      'earn',
      'pay',
      'sum',
      'total',
      'finance',
      'financial',
    ],
    version: '1.39',
    unicode: 'ef0a',
  },
}

export const searchIcon = async (tag: string) => {
  const result = []
  for (let i = 0; i < Object.keys(searchTags).length; i++) {
    if (
      Object.values(searchTags)[i].tags.filter((i) => i.indexOf(tag) !== -1)
        .length > 0
    ) {
      result.push(Object.keys(searchTags)[i])
    }
  }

  return result
}
