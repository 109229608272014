const MobileShare = (title: string, text: string, url: string) => {
  if (window && typeof(window.navigator.share) === "function") {
    window.navigator.share({
      title: title,
      text: text,
      url: url,
    })
  }
}

export default MobileShare
