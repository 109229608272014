declare const API_URL: string
import axios from "axios"
import { ReadAccountToken } from "../HandleToken"
import { CheckAccountToken, CheckAPIURL } from "./Check"

/**
 * 對象：任何人
 * 動作：獲取所有公開成就
 */
export const getAchievementsFromSOHO = async () => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(
      // "https://staging-api.swap.work/",
      API_URL,
      {
        query: `
          query {
            getAchievementsFromSOHO {
              payload {
                id
                name
                subtitle
                description
                type
                requirement
                percentage 
                acquisition_method
                picture_url
                tab_type
                expired_at
                creator {
                  personal_id
                  picture
                  nick_name
                }
              }
              status
              message
              errors
            }
          }`,
      }
    )
    result = res.data.data.getAchievementsFromSOHO
    const errors = res.data.data.getAchievementsFromSOHO.errors
    if (errors) {
      throw new Error(result.errors.join(", "));
    }
  } catch (err) {
    console.log("Error of getAchievementsFromSOHO", err)
    throw err
  }
  return result
}

/**
 * 對象：授權用戶
 * 動作：獲取個人成就
 */
export const getPersonalAchievementsFromSOHO = async () => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      // "https://staging-api.swap.work/",
      API_URL,
      {
        query: `
          query {
            getPersonalAchievementsFromSOHO {
                payload {
                  id
                  name
                  subtitle
                  description
                  type
                  requirement
                  percentage 
                  acquisition_method
                  picture_url
                  tab_type
                  expired_at
                  owners {
                    picture_url
                  }
                  creator {
                    personal_id
                    picture
                    nick_name
                  }
                  personal_achievement {
                    id
                    is_claimed
                    is_new
                    is_visible
                    created_at
                  }
                  personal_achievement_progress {        
                    current_progress
                  }
                }
                status
                message
                errors
            }
          }`,
      },
      {
        headers: {
          "x-authorization": ReadAccountToken(),
        },
      }
    )
    result = res.data.data.getPersonalAchievementsFromSOHO
    const errors = res.data.data.getPersonalAchievementsFromSOHO.errors
    if (errors) {
      throw new Error(result.errors.join(", "));
    }
  } catch (err) {
    console.log("Error of getPersonalAchievementsFromSOHO", err)
    throw err
  }
  return result
}

export const updateAchievementsIsNewFromSOHO = async (achievementIds: string[]) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
          mutation($inputData: UpdateAchievementsIsNewFromSOHOInputData!) {
            updateAchievementsIsNewFromSOHO(inputData: $inputData) {
              status
              message
              errors
            }
          }
        `,
        variables: {
          inputData: {
            achievement_ids: achievementIds
          }
        }
      },
      {
        headers: {
          "x-authorization": ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updateAchievementsIsNewFromSOHO
    const errors = result.errors
    if (errors && errors.length > 0) {
      throw new Error(result.errors.join(", "));
    }
  } catch (err) {
    console.log("Error of updateAchievementsIsNewFromSOHO", err)
    throw err
  }
  return result
}

export const toggleAchievementVisibleFromSOHO = async (achievementId: string) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
          mutation($inputData: ToggleAchievementVisibleFromSOHOInputData!) {
            toggleAchievementVisibleFromSOHO(inputData: $inputData) {
              status
              message
              errors
            }
          }
        `,
        variables: {
          inputData: {
            achievement_id: achievementId
          }
        }
      },
      {
        headers: {
          "x-authorization": ReadAccountToken(),
        },
      }
    )
    result = res.data.data.toggleAchievementVisibleFromSOHO;

    const errors = result?.errors
    
    if (errors && errors.length > 0) {
      throw new Error(result.errors.join(", "));
    }
  } catch (err) {
    console.log("Error of toggleAchievementVisibleFromSOHO", err)
    throw err
  }
  return result
}