declare const API_URL: string

import axios from 'axios'
import { ReadAccountToken } from '../../HandleToken'
import { CheckAccountToken, CheckAPIURL } from '../Check'

/**
 * 對象：SOHO會員
 * 動作：新增接案履歷連結
 */
interface CreateBatchMediaLinksFromSOHOInputData {
  links: {
    title: string
    link: string
    type: string
    index: number
    meta_picture: string
  }[]
}

export const createBatchMediaLinksFromSOHO = async (
  inputData: CreateBatchMediaLinksFromSOHOInputData
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
          mutation ($inputData: CreateBatchMediaLinksFromSOHOInputData){
            createBatchMediaLinksFromSOHO(inputData: $inputData) {   
              message
              status
              errors
            }
          }`,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.createBatchMediaLinksFromSOHO
    const errors = res.data.data.createBatchMediaLinksFromSOHO.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of createBatchMediaLinksFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO會員
 * 動作：更新接案履歷連結
 */
interface UpdateMediaLinkFromSOHOInputData {
  id: number
  title: string
  link: string
  type: string
  index: number
  meta_picture: string
}

export const updateMediaLinkFromSOHO = async (
  inputData: UpdateMediaLinkFromSOHOInputData
) => {
  let result
  try {
    CheckAPIURL()
    CheckAccountToken()
    const res = await axios.post(
      API_URL,
      {
        query: `
          mutation ($inputData: UpdateMediaLinkFromSOHOInputData){
            updateMediaLinkFromSOHO(inputData: $inputData) {   
              message
              status
              errors
            }
          }`,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.updateMediaLinkFromSOHO
    const errors = res.data.data.updateMediaLinkFromSOHO.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of updateMediaLinkFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO會員
 * 動作：查看接案履歷連結
 */
interface GetMediaLinksFromSOHOInputData {
  member_id: string
}

export const getMediaLinksFromSOHO = async (
  inputData: GetMediaLinksFromSOHOInputData
) => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(API_URL, {
      query: `
          query ($inputData: GetMediaLinksFromSOHOInputData){
            getMediaLinksFromSOHO(inputData: $inputData) {
              payload{
                id
                title
                link
                type
                index
                meta_picture
              }
              status
              errors
              message
            }
          }`,
      variables: {
        inputData,
      },
    })
    result = res.data.data.getMediaLinksFromSOHO
    const errors = res.data.data.getMediaLinksFromSOHO.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of getMediaLinksFromSOHO', err)
  }
  return result
}

/**
 * 對象：SOHO會員
 * 動作：刪除接案履歷連結
 */
interface DeleteMediaLinkFromSOHOInputData {
  id: string
}

export const deleteMediaLinkFromSOHO = async (
  inputData: DeleteMediaLinkFromSOHOInputData
) => {
  let result
  try {
    CheckAPIURL()
    const res = await axios.post(
      API_URL,
      {
        query: `
          mutation ($inputData: DeleteMediaLinkFromSOHOInputData){
            deleteMediaLinkFromSOHO(inputData: $inputData) {
              status
              errors
              message
            }
          }`,
        variables: {
          inputData,
        },
      },
      {
        headers: {
          'x-authorization': ReadAccountToken(),
        },
      }
    )
    result = res.data.data.deleteMediaLinkFromSOHO
    const errors = res.data.data.deleteMediaLinkFromSOHO.errors
    if (errors) {
      throw errors
    }
  } catch (err) {
    console.log('Error of deleteMediaLinkFromSOHO', err)
  }
  return result
}
