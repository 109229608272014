import { Typography } from '@yosgo/swap-ui'
import React from 'react'
import styled from 'styled-components'

interface MobileEditButtonProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  isUpdated: boolean
  editButtonContent: {
    text: string
    icon: React.ReactNode
    onClick: () => void
  }[]
}

const MobileEditButton = ({
  isOpen,
  setIsOpen,
  isUpdated,
  editButtonContent,
}: MobileEditButtonProps) => {
  return (
    <>
      <EditIcon
        isOpen={isOpen}
        isUpdated={isUpdated}
        onClick={() => {
          setIsOpen(true)
        }}
      >
        {icon_edit}
      </EditIcon>
      <Backdrop
        isOpen={isOpen}
        onClick={() => {
          setIsOpen(false)
        }}
      ></Backdrop>
      <CloseIcon
        isOpen={isOpen}
        isUpdated={isUpdated}
        onClick={() => {
          setIsOpen(false)
        }}
      >
        {icon_close_blue}
      </CloseIcon>
      {editButtonContent.map((i, index) => (
        <FabButton
          isOpen={isOpen}
          isUpdated={isUpdated}
          index={index}
          key={index}
          onClick={() => {
            i.onClick()
          }}
        >
          <Typography
            variant="subtitle"
            color="primary400"
            style={{ marginRight: 4 }}
          >
            {i.text}
          </Typography>
          {i.icon}
        </FabButton>
      ))}
    </>
  )
}

const EditIcon = styled.div<{ isUpdated: boolean; isOpen: boolean }>`
  width: 48px;
  height: 48px;
  border: 1px solid #4862cc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1299;
  right: 16px;
  bottom: ${({ isUpdated }) => 12 + (isUpdated ? 64 : 0)}px;
  visibility: ${({ isOpen }) => (isOpen ? 'hidden' : 'visible')};
  animation-name: ${({ isOpen }) =>
    isOpen ? 'editiconleave' : 'editiconappear'};
  animation-duration: 250ms;
  transition: all 250ms ease-in-out;
  will-change: opacity;
  @keyframes editiconappear {
    from {
      opacity: 0.8;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes editiconleave {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.8;
    }
  }
`

const FabButton = styled.div<{
  index: number
  isUpdated: boolean
  isOpen: boolean
}>`
  width: fit-content;
  height: 48px;
  padding: 0 24px;
  border: 1px solid #4862cc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1300;
  right: 16px;
  bottom: ${({ index, isUpdated }) => 68 + 56 * index + (isUpdated ? 64 : 0)}px;
  visibility: ${({ isOpen }) => (!isOpen ? 'hidden' : 'visible')};
  animation-name: ${({ index, isOpen }) =>
    !isOpen ? `fab${index}leave` : `fab${index}enter`};
  animation-duration: ${({ isOpen }) => (!isOpen ? '200ms' : '250ms')};
  transition: ${({ isOpen }) =>
    !isOpen ? 'all 200ms ease-in-out' : 'all 250ms ease-in-out'};
  will-change: bottom, opacity;
  @keyframes ${({ index }) => `fab${index}enter`} {
    from {
      bottom: ${({ isUpdated }) => 68 + (isUpdated ? 64 : 0)}px;
    }
    to {
      bottom: ${({ index, isUpdated }) =>
        68 + 56 * index + (isUpdated ? 64 : 0)}px;
    }
  }
  @keyframes ${({ index }) => `fab${index}leave`} {
    from {
      bottom: ${({ index, isUpdated }) =>
        68 + 56 * index + (isUpdated ? 64 : 0)}px;
      opacity: 1;
    }
    to {
      bottom: ${({ isUpdated, index }) =>
        68 + (isUpdated ? 64 : 0) + index * 4}px;
      opacity: 0.2;
    }
  }
`

const CloseIcon = styled.div<{ isUpdated: boolean; isOpen: boolean }>`
  width: 48px;
  height: 48px;
  border: 1px solid #4862cc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #c1c7ed;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1300;
  right: 16px;
  bottom: ${({ isUpdated }) => 12 + (isUpdated ? 64 : 0)}px;
  visibility: ${({ isOpen }) => (!isOpen ? 'hidden' : 'visible')};
  animation-name: ${({ isOpen }) =>
    !isOpen ? 'closeiconleave' : 'closeiconappear'};
  animation-duration: ${({ isOpen }) => (!isOpen ? '200ms' : '250ms')};
  transition: ${({ isOpen }) =>
    !isOpen ? 'all 200ms ease-in-out' : 'all 250ms ease-in-out'};
  will-change: opacity;
  @keyframes closeiconappear {
    from {
      opacity: 0.8;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes closeiconleave {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.8;
    }
  }
`
const Backdrop = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.5;
  position: fixed;
  z-index: 1299;
  top: 0;
  left: 0;
  visibility: ${({ isOpen }) => (!isOpen ? 'hidden' : 'visible')};
  animation-name: ${({ isOpen }) => (!isOpen ? 'fadeout' : 'fadein')};
  animation-duration: ${({ isOpen }) => (!isOpen ? '200ms' : '250ms')};
  transition: ${({ isOpen }) =>
    !isOpen ? 'all 200ms ease-in-out' : 'all 250ms ease-in-out'};
  will-change: opacity;
  @keyframes fadein {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 0.5;
    }
  }
  @keyframes fadeout {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 0;
    }
  }
`

const icon_edit = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 3C3.89 3 3 3.89 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V12H19V19H5V5H12V3H5ZM17.78 4C17.61 4 17.43 4.07 17.3 4.2L16.08 5.41L18.58 7.91L19.8 6.7C20.06 6.44 20.06 6 19.8 5.75L18.25 4.2C18.12 4.07 17.95 4 17.78 4ZM15.37 6.12L8 13.5V16H10.5L17.87 8.62L15.37 6.12Z"
      fill="#4862CC"
    />
  </svg>
)

const icon_close_blue = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      fill="#002BA1"
    />
  </svg>
)

export default MobileEditButton
