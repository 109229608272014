import { makeStyles, Theme } from '@material-ui/core'
import { ButtonStyleProps } from '@/types/StyleTypes'

const useStyle = makeStyles<Theme, ButtonStyleProps>(() => ({
  root: (props) => ({
    width: props.width ? props.width : 24,
    height: props.height ? props.height : 24,
    backgroundColor: '#ffffff',
    border: '1px solid #4862cc',
    boxSizing: 'border-box',
    borderRadius: props.borderRadius ? props.borderRadius : 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E6E9F8',
    },
    '&:active': {
      backgroundColor: '#C1C7ED',
    },
  }),
}))

interface EditIconButtonProps {
  icon?: React.ReactNode
  onClick: () => void
  width?: number | string
  height?: number | string
  borderRadius?: number | string
}

const EditIconButton = ({
  icon,
  onClick,
  width,
  height,
  borderRadius,
}: EditIconButtonProps) => {
  const styleProps: ButtonStyleProps = {
    width: width,
    height: height,
    borderRadius: borderRadius,
  }
  const classes = useStyle(styleProps)

  return (
    <div className={classes.root} onClick={onClick}>
      {icon ? icon : icon_edit}
    </div>
  )
}

const icon_edit = (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.85189 0.071106C1.11189 0.071106 0.518555 0.664439 0.518555 1.40444V10.7378C0.518555 11.0914 0.65903 11.4305 0.909079 11.6806C1.15913 11.9306 1.49827 12.0711 1.85189 12.0711H11.1852C11.5388 12.0711 11.878 11.9306 12.128 11.6806C12.3781 11.4305 12.5186 11.0914 12.5186 10.7378V6.07111H11.1852V10.7378H1.85189V1.40444H6.51856V0.071106H1.85189ZM10.3719 0.737773C10.2586 0.737773 10.1386 0.784439 10.0519 0.871106L9.23856 1.67777L10.9052 3.34444L11.7186 2.53777C11.8919 2.36444 11.8919 2.07111 11.7186 1.90444L10.6852 0.871106C10.5986 0.784439 10.4852 0.737773 10.3719 0.737773ZM8.76522 2.15111L3.85189 7.07111V8.73777H5.51856L10.4319 3.81777L8.76522 2.15111Z"
      fill="#4862CC"
    />
  </svg>
)

export default EditIconButton
