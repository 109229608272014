import React, { useEffect, useRef, useState, useCallback } from "react"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { XYCoord, Identifier } from "dnd-core"
import { TablerIconsKeyProps } from "./create/declare"
import * as TablerIcons from "@tabler/icons"
import { LinkProps } from "@/types/LinkTypes"
import { deleteMediaLinkFromSOHO, updateMediaLinkFromSOHO } from "../../../utils/appRequest/profile/link"
import { ImageToBase64 } from "../../../utils/ImageToBase64"
import { Modal,  Typography, IconButton, Menu, MenuItem, Button, SWAPSpace } from "@yosgo/swap-ui"
import { makeStyles } from "@material-ui/core"
import  LinkEditBlock from "./LinkEditBlock"
import { HTML5Backend } from "react-dnd-html5-backend"
import update from "immutability-helper"

interface LinkBlockProps {
    id: any
    title: string
    index: number
    moveLink: (dragIndex: number, hoverIndex: number) => void
    icon: string
    link: string
    setLinks: (links: LinkProps[]) => void
    links: LinkProps[]
    isMobile?: boolean
    iconBase64: string
}

interface DragItem {
    index: number
    id: string
    type: string
}

const ItemTypes = {
    ITEM: "item",
}


const useStyles = makeStyles(() => ({
    minusIconButton: {
      width: 24,
      height: 24,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 8,
      "& svg": { fill: "#909090" },
      "&:hover": { "& svg": { fill: "#000000" } },
    },
    linkBlock: {
      width: "100%",
      height: 92,
      borderRadius: 24,
      backgroundColor: "#ffffff",
      padding: "16px 4px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#F6F6F6",
      },
    },
  }))
  

export const LinkBlock = ({
    id,
    title,
    index,
    moveLink,
    icon,
    link,
    setLinks,
    links,
    isMobile,
    iconBase64,
  }: LinkBlockProps) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [isOpenEditLinkModal, setIsOpenEditLinkModal] = useState(false)
    const [isOpenDeleteLinkModal, setIsOpenDeleteLinkModal] = useState(false)
    const [isFromEditLinkModal, setIsFromEditLinkModal] = useState(false)
    const [temporaryLink, setTemporaryLink] = useState<LinkProps[]>([])
    const [picForBase64, setPicForBase64] = useState("")
    const [updateLinkLoading, setUpdateLinkLoading] = useState(false)
    const [deleteLinkLoading, setDeleteLinkLoading] = useState(false)
    const [isOpenMobileThreeDotsModal, setIsOpenMobileThreeDotsModal] =
      useState(false)
  
    const Icon = TablerIcons[`${icon}` as TablerIconsKeyProps]
  
    useEffect(() => {
      setTemporaryLink([
        {
          id: id,
          title: title,
          link: link,
          type: "normal",
          meta_picture: icon,
          index: index,
          meta_picture_base64: picForBase64,
        },
      ])
    }, [link, title, icon, picForBase64])
  
    const handleMenuClick = (e: any) => {
      setAnchorEl(e.currentTarget)
    }
  
    useEffect(() => {
      if (icon && icon.indexOf("https") !== -1) {
        handleImageToBase64(icon, setPicForBase64)
      } else {
        setPicForBase64("")
      }
    }, [icon])
  
    const handleMenuClose = () => {
      setAnchorEl(null)
    }
    const ref = useRef(null)
  
    const [{ handlerId }, drop] = useDrop<
      DragItem,
      void,
      { handlerId: Identifier | null }
    >({
      accept: ItemTypes.ITEM,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      hover(item: DragItem, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index
  
        // Don"t replace items with themselves
        if (dragIndex === hoverIndex) {
          return
        }
  
        // Determine rectangle on screen
        const r: any = ref.current
        const hoverBoundingRect = r?.getBoundingClientRect()
  
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
  
        // Determine mouse position
        const clientOffset = monitor.getClientOffset()
  
        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
  
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
  
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
  
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
  
        // Time to actually perform the action
        moveLink(dragIndex, hoverIndex)
  
        // Note: we"re mutating the monitor item here!
        // Generally it"s better to avoid mutations,
        // but it"s good here for the sake of performance
        // to avoid expensive index searches.
        item.index = hoverIndex
      },
    })
  
    const [{}, drag] = useDrag({
      type: ItemTypes.ITEM,
      item: () => {
        return { id, index }
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    })
  
    drag(drop(ref))
  
    const resetLink = () => {
      setTemporaryLink([links[index]])
    }
  
    const deleteLink = () => {
      const a: LinkProps[] = []
  
      for (let i = 0; i < links.length; i++) {
        if (i < index) {
          a[i] = links[i]
        } else if (i === index) {
          continue
        } else {
          a[i - 1] = links[i]
        }
      }
      setLinks(a)
    }
  
    const handleDeleteLink = async () => {
      try {
        setDeleteLinkLoading(true)
        const result = await deleteMediaLinkFromSOHO({ id: id })
        setDeleteLinkLoading(false)
        if (result && result.errors === null) {
          deleteLink()
          setIsOpenDeleteLinkModal(false)
        } else {
          throw result.errors
        }
      } catch (error) {
        setDeleteLinkLoading(false)
        console.log(error)
      }
    }
  
    const handleEditLink = async () => {
      try {
        setUpdateLinkLoading(true)
        const result = await updateMediaLinkFromSOHO({
          id: temporaryLink[0].id,
          title: temporaryLink[0].title,
          meta_picture: temporaryLink[0].meta_picture,
          link: temporaryLink[0].link,
          type: temporaryLink[0].type,
          index: temporaryLink[0].index,
        })
        setUpdateLinkLoading(false)
        if (result && result.errors === null) {
          const a: LinkProps[] = []
          for (let i = 0; i < links.length; i++) {
            if (i === index) {
              a[i] = temporaryLink[0]
            } else {
              a[i] = links[i]
            }
          }
          setLinks(a)
          setIsOpenEditLinkModal(false)
        } else {
          throw result.errors
        }
      } catch (error) {
        setUpdateLinkLoading(false)
        console.log(error)
      }
    }
  
    const classes = useStyles()
    
    return (
      <div ref={ref} data-handler-id={handlerId} className={classes.linkBlock}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "move",
          }}
        >
          {icon_drag}
        </div>
        <div
          draggable="false"
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", width: "100%" }}
            onClick={() => {
              window.open(link.indexOf("http") === -1 ? `https://${link}` : link)
            }}
          >
            {icon ? (
              icon.indexOf("https") !== -1 ? (
                <img
                  alt="icon_suggested"
                  src={iconBase64 || picForBase64 || icon}
                  height={60}
                  width={60}
                  style={{ borderRadius: 16, marginRight: 16 }}
                />
              ) : (
                <div
                  style={{
                    width: 60,
                    height: 60,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f6f6f6",
                    borderRadius: 16,
                    marginRight: 16,
                  }}
                >
                  <Icon stroke={1.5} color="#909090" size={40} />
                </div>
              )
            ) : null}
            <Typography variant="body1" style={{ wordBreak: "break-all" }}>
              {title}
            </Typography>
          </div>
          <IconButton
            width={40}
            height={40}
            onClick={
              isMobile
                ? () => setIsOpenMobileThreeDotsModal(true)
                : handleMenuClick
            }
            style={{ marginRight: 12 }}
          >
            {icon_threedots}
          </IconButton>
          {!isMobile && (
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: -160,
                vertical: "bottom",
              }}
              getContentAnchorEl={null}
              keepMounted
              onClose={handleMenuClose}
            >
              <MenuItem
                width={200}
                onClick={() => {
                  setIsOpenEditLinkModal(true)
                  handleMenuClose()
                }}
              >
                {icon_edit_gray}
                編輯連結
              </MenuItem>
              <MenuItem
                width={200}
                onClick={() => {
                  setIsOpenDeleteLinkModal(true)
                  handleMenuClose()
                }}
              >
                {icon_delete}
                刪除連結
              </MenuItem>
            </Menu>
          )}
        </div>
        {/* 編輯連結 */}
        <Modal
          mobile={isMobile}
          fullWidth={isMobile}
          open={isOpenEditLinkModal}
          onClose={() => {
            resetLink()
            setIsOpenEditLinkModal(false)
          }}
          maxWidth={isMobile ? undefined : 640}
          title="編輯連結"
          primaryButton={{
            title: "儲存",
            onClick: handleEditLink,
            loading: updateLinkLoading,
            disabled: updateLinkLoading,
          }}
          secondaryButton={{
            title: "取消",
            onClick: () => {
              resetLink()
              setIsOpenEditLinkModal(false)
            },
          }}
          children={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                className={classes.minusIconButton}
                onClick={() => {
                  setIsOpenEditLinkModal(false)
                  setIsOpenDeleteLinkModal(true)
                  setIsFromEditLinkModal(true)
                }}
              >
                {icon_minus}
              </div>
              <LinkEditBlock
                links={temporaryLink}
                setLinks={setTemporaryLink}
                blockIndex={0}
                isMobile={isMobile}
              />
            </div>
          }
        />
        {/* 刪除連結 */}
        <Modal
          mobile={isMobile}
          fullWidth={isMobile}
          buttonFullWidth
          open={isOpenDeleteLinkModal}
          onClose={() => {
            if (isFromEditLinkModal) {
              setIsOpenEditLinkModal(true)
              setIsFromEditLinkModal(false)
            }
            setIsOpenDeleteLinkModal(false)
          }}
          maxWidth={isMobile ? undefined : 400}
          title="確定要刪除連結？"
          primaryButton={{
            title: "刪除",
            onClick: handleDeleteLink,
            disabled: deleteLinkLoading,
            loading: deleteLinkLoading,
          }}
          secondaryButton={{
            title: "保留",
            onClick: () => {
              if (isFromEditLinkModal) {
                setIsFromEditLinkModal(false)
                setIsOpenEditLinkModal(true)
              }
              setIsOpenDeleteLinkModal(false)
            },
          }}
        />
        {/* 手機版 連結操作選項 */}
        {isMobile && (
          <Modal
            mobile
            fullWidth
            open={isOpenMobileThreeDotsModal}
            onClose={() => {
              setIsOpenMobileThreeDotsModal(false)
            }}
            title="連結選項"
            primaryButton={{
              title: "取消",
              onClick: () => {
                setIsOpenMobileThreeDotsModal(false)
              },
              variant: "secondary",
            }}
            children={
              <>
                {[
                  {
                    icon: icon_edit_gray,
                    text: "編輯連結",
                    onClick: () => {
                      setIsOpenEditLinkModal(true)
                      setIsOpenMobileThreeDotsModal(false)
                    },
                  },
                  {
                    icon: icon_delete,
                    text: "刪除連結",
                    onClick: () => {
                      setIsOpenDeleteLinkModal(true)
                      setIsOpenMobileThreeDotsModal(false)
                    },
                  },
                ].map((i, index) => (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      height: 54,
                      padding: "0 16px",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: 8,
                      border: "1px solid #cccccc",
                      backgroundColor: "#ffffff",
                      marginBottom: index === 0 ? 8 : undefined,
                    }}
                    onClick={i.onClick}
                  >
                    {i.icon}
                    <Typography variant="subtitle">{i.text}</Typography>
                  </div>
                ))}
              </>
            }
          />
        )}
      </div>
    )
  }


interface LinksBlockProps {
    isPreview?: boolean
    links: LinkProps[]
    setLinks: (links: React.SetStateAction<LinkProps[]>) => void
    setIsOpenAddLinkModal: (isOpen: boolean) => void
  }
  
  export const LinksBlock = ({
    isPreview,
    links,
    setLinks,
    setIsOpenAddLinkModal,
  }: LinksBlockProps) => {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "40px 40px 24px 40px",
            boxShadow: "0px 1px 0px #ECECEC",
          }}
        >
          <Typography variant="h6">我的連結</Typography>
          {!isPreview && (
            <Button
              size="small"
              onClick={() => {
                setIsOpenAddLinkModal(true)
              }}
            >
              新增連結
            </Button>
          )}
        </div>
        <LinksBlocksComponent
          isPreview={isPreview}
          links={links}
          setLinks={setLinks}
        />
      </>
    )
  }


interface LinksBlocksComponentProps {
    isPreview?: boolean
    links: LinkProps[]
    setLinks: (links: React.SetStateAction<LinkProps[]>) => void
    isMobile?: boolean
    isFromGuest?: boolean
  }
  
  export const LinksBlocksComponent = ({
    links,
    setLinks,
    isPreview,
    isMobile,
    isFromGuest,
  }: LinksBlocksComponentProps) => {
    const handleUpdateLinksIndex = async (
      dragIndex: number,
      hoverIndex: number
    ) => {
      try {
        await updateMediaLinkFromSOHO({
          id: links[dragIndex].id,
          title: links[dragIndex].title,
          meta_picture: links[dragIndex].meta_picture,
          link: links[dragIndex].link,
          type: links[dragIndex].type,
          index: links[hoverIndex].index,
        })
        await updateMediaLinkFromSOHO({
          id: links[hoverIndex].id,
          title: links[hoverIndex].title,
          meta_picture: links[hoverIndex].meta_picture,
          link: links[hoverIndex].link,
          type: links[hoverIndex].type,
          index: links[dragIndex].index,
        })
      } catch (error) {
        console.log(error)
      }
    }
  
    const moveLink = useCallback(
      (dragIndex: number, hoverIndex: number) => {
        handleUpdateLinksIndex(dragIndex, hoverIndex)
        setLinks((prevLinks: LinkProps[]) =>
          update(prevLinks, {
            $splice: [
              [
                dragIndex,
                1,
                {
                  id: prevLinks[hoverIndex].id,
                  title: prevLinks[hoverIndex].title,
                  meta_picture: prevLinks[hoverIndex].meta_picture,
                  link: prevLinks[hoverIndex].link,
                  type: prevLinks[hoverIndex].type,
                  index: prevLinks[dragIndex].index,
                } as LinkProps,
              ],
              [
                hoverIndex,
                1,
                {
                  id: prevLinks[dragIndex].id,
                  title: prevLinks[dragIndex].title,
                  meta_picture: prevLinks[dragIndex].meta_picture,
                  link: prevLinks[dragIndex].link,
                  type: prevLinks[dragIndex].type,
                  index: prevLinks[hoverIndex].index,
                } as LinkProps,
              ],
            ],
          })
        )
      },
      [links]
    )
  
    const renderLink = useCallback(
      (
        link: {
          id: number
          title: string
          meta_picture: string
          link: string
          meta_picture_base64: string
        },
        index: number
      ) => {
        return (
          <LinkBlock
            key={link.id}
            index={index}
            id={link.id}
            title={link.title}
            moveLink={moveLink}
            icon={link.meta_picture}
            link={link.link}
            setLinks={setLinks}
            links={links}
            isMobile={isMobile}
            iconBase64={link.meta_picture_base64}
          />
        )
      },
      [links]
    )
  
    const classes = useStyles()
  
    return links.length > 0 ? (
      !isPreview ? (
        <DndProvider backend={HTML5Backend}>
          <>
            <div
              style={{
                padding: isMobile ? undefined : "10px 24px",
              }}
            >
              {links.map((link, i) => renderLink(link, i))}
            </div>
          </>
        </DndProvider>
      ) : (
        <div style={{ padding: isMobile ? undefined : "10px 24px" }}>
          {links.map((i, index) => {
            const Icon = TablerIcons[`${i.meta_picture}` as TablerIconsKeyProps]
            return (
              <div
                key={index}
                className={classes.linkBlock}
                onClick={() =>
                  window.open(
                    i.link.indexOf("http") === -1 ? `https://${i.link}` : i.link
                  )
                }
                style={{ padding: 16 }}
              >
                {i.meta_picture ? (
                  i.meta_picture.indexOf("https") !== -1 ? (
                    <img
                      alt="meta_picture_link"
                      src={i.meta_picture}
                      height={60}
                      width={60}
                      style={{ marginRight: "16px", borderRadius: 16 }}
                    />
                  ) : (
                    <div
                      style={{
                        width: 60,
                        height: 60,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#f6f6f6",
                        borderRadius: 16,
                        marginRight: "16px",
                      }}
                    >
                      <Icon stroke={1.5} color="#909090" size={40} />
                    </div>
                  )
                ) : null}
                <Typography variant="body1">{i.title}</Typography>
              </div>
            )
          })}
        </div>
      )
    ) : !isFromGuest ? (
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: 320,
            height: 92,
            borderRadius: 24,
            backgroundColor: "#ffffff",
            boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            padding: 16,
            justifyContent: "space-between",
            position: "absolute",
            top: 88,
            zIndex: 1,
            marginLeft: 28,
          }}
        >
          <div
            style={{
              width: 60,
              height: 60,
              borderRadius: 16,
              backgroundColor: "#ECECEC",
            }}
          ></div>
          <div
            style={{
              width: 148,
              height: 16,
              borderRadius: 4,
              backgroundColor: "#ECECEC",
              marginLeft: 4,
            }}
          ></div>
          <div
            style={{
              width: 48,
              height: 48,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon_threedots}
          </div>
        </div>
        <div
          style={{
            width: 320,
            height: 92,
            borderRadius: 24,
            backgroundColor: "#ffffff",
            boxShadow: "0px 6px 24px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            padding: 16,
            justifyContent: "space-between",
            position: "absolute",
            top: 158,
            zIndex: 2,
            marginRight: 28,
          }}
        >
          <img
            alt="new_feature_icon"
            src="https://swap-img.swap.work/media/profile/newFeatureModal/icon1.png"
            width={60}
            height={60}
          />
          <Typography variant="body1" style={{ marginLeft: 4 }}>
            1 對 1 設計&職涯諮詢
          </Typography>
          <div
            style={{
              width: 48,
              height: 48,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon_threedots}
          </div>
        </div>
        <SWAPSpace size={290} />
        <Typography variant="h4">整合你的個人連結</Typography>
        <SWAPSpace />
        <Typography variant="body2_loose" color="black800">
          透過接案履歷連結，客戶可以直接看到
          <br />
          你的所有相關內容
        </Typography>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 68,
        }}
      >
        <img
          alt="no-link image"
          src="https://swap-img.swap.work/media/profile/nolink.svg"
          height={113}
        />
        <SWAPSpace size={40} />
        <Typography variant="body2" color="black800">
          目前尚無連結
        </Typography>
      </div>
    )
  }
  

  export async function handleImageToBase64(
    img: string,
    setImg: (img: string) => void
  ) {
    try {
      const result = await ImageToBase64(img)
      if (result) {
        setImg(`data:image/png;base64,${result}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  export const icon_drag = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: "16px" }}
    >
      <path
        d="M19 26C19 27.1 18.1 28 17 28C15.9 28 15 27.1 15 26C15 24.9 15.9 24 17 24C18.1 24 19 24.9 19 26ZM17 18C15.9 18 15 18.9 15 20C15 21.1 15.9 22 17 22C18.1 22 19 21.1 19 20C19 18.9 18.1 18 17 18ZM17 12C15.9 12 15 12.9 15 14C15 15.1 15.9 16 17 16C18.1 16 19 15.1 19 14C19 12.9 18.1 12 17 12ZM23 16C24.1 16 25 15.1 25 14C25 12.9 24.1 12 23 12C21.9 12 21 12.9 21 14C21 15.1 21.9 16 23 16ZM23 18C21.9 18 21 18.9 21 20C21 21.1 21.9 22 23 22C24.1 22 25 21.1 25 20C25 18.9 24.1 18 23 18ZM23 24C21.9 24 21 24.9 21 26C21 27.1 21.9 28 23 28C24.1 28 25 27.1 25 26C25 24.9 24.1 24 23 24Z"
        fill="#909090"
      />
    </svg>
  )

  export const icon_edit_gray = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 8 }}
    >
      <path
        d="M4.16667 2.5C3.24167 2.5 2.5 3.24167 2.5 4.16667V15.8333C2.5 16.2754 2.67559 16.6993 2.98816 17.0118C3.30072 17.3244 3.72464 17.5 4.16667 17.5H15.8333C16.2754 17.5 16.6993 17.3244 17.0118 17.0118C17.3244 16.6993 17.5 16.2754 17.5 15.8333V10H15.8333V15.8333H4.16667V4.16667H10V2.5H4.16667ZM14.8167 3.33333C14.675 3.33333 14.525 3.39167 14.4167 3.5L13.4 4.50833L15.4833 6.59167L16.5 5.58333C16.7167 5.36667 16.7167 5 16.5 4.79167L15.2083 3.5C15.1 3.39167 14.9583 3.33333 14.8167 3.33333ZM12.8083 5.1L6.66667 11.25V13.3333H8.75L14.8917 7.18333L12.8083 5.1Z"
        fill="#6F6F6F"
      />
    </svg>
  )
  
  export const icon_delete = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginRight: 8 }}
    >
      <path
        d="M7.49998 2.5V3.33333H3.33331V5H4.16665V15.8333C4.16665 16.2754 4.34224 16.6993 4.6548 17.0118C4.96736 17.3244 5.39129 17.5 5.83331 17.5H14.1666C14.6087 17.5 15.0326 17.3244 15.3452 17.0118C15.6577 16.6993 15.8333 16.2754 15.8333 15.8333V5H16.6666V3.33333H12.5V2.5H7.49998ZM5.83331 5H14.1666V15.8333H5.83331V5ZM7.49998 6.66667V14.1667H9.16665V6.66667H7.49998ZM10.8333 6.66667V14.1667H12.5V6.66667H10.8333Z"
        fill="#6F6F6F"
      />
    </svg>
  )


  const icon_minus = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12Z"
      />
    </svg>
  )

const icon_threedots = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 12C16 11.4696 16.2107 10.9609 16.5858 10.5858C16.9609 10.2107 17.4696 10 18 10C18.5304 10 19.0391 10.2107 19.4142 10.5858C19.7893 10.9609 20 11.4696 20 12C20 12.5304 19.7893 13.0391 19.4142 13.4142C19.0391 13.7893 18.5304 14 18 14C17.4696 14 16.9609 13.7893 16.5858 13.4142C16.2107 13.0391 16 12.5304 16 12ZM10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12ZM4 12C4 11.4696 4.21071 10.9609 4.58579 10.5858C4.96086 10.2107 5.46957 10 6 10C6.53043 10 7.03914 10.2107 7.41421 10.5858C7.78929 10.9609 8 11.4696 8 12C8 12.5304 7.78929 13.0391 7.41421 13.4142C7.03914 13.7893 6.53043 14 6 14C5.46957 14 4.96086 13.7893 4.58579 13.4142C4.21071 13.0391 4 12.5304 4 12Z"
        fill="#6F6F6F"
      />
    </svg>
  )