import axios from "axios"
import { ChangeEvent } from "react"

interface InvisibleUploadImageInputProps {
  beforeUploadFunction: () => void
  afterUploadFunction: (response: any, base64: string) => void
  width: number | string
  height: number | string
}

const InvisibleUploadImageInput = ({
  afterUploadFunction,
  beforeUploadFunction,
  width,
  height,
}: InvisibleUploadImageInputProps) => {
  const uploadPic = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      beforeUploadFunction()
      if (e.target.files?.length) {
        let isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)
        const file = e.target.files[0]
        // 大小限制15MB
        if (file.size < 15728640) {
          if (
            file.type == "image/jpeg" ||
            file.type == "image/jpg" ||
            file.type == "image/png" ||
            (isIOS && file.type == "image/heic")
          ) {
            /**單一檔案上傳 */
            const formData = new FormData()
            formData.append("disableWatermark", "YES")
            formData.append("photos", file)
            formData.append("id", "id")
            formData.append("name", "name")
            let reader = new FileReader()
            reader.readAsDataURL(file)

            const response = await axios.post(
              "https://img-work.swap.work/_watermark",
              formData
            )
            afterUploadFunction(response.data[0], reader.result as string)
          } else {
            throw isIOS
              ? "請使用 jpg, jpeg, heic 或 png 的圖片格式。"
              : "請使用 jpg, jpeg 或 png 的圖片格式。"
          }
        } else {
          throw "請選擇檔案大小在15MB以內的圖片檔案。"
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <input
      aria-label="upload image"
      type="file"
      style={{
        position: "absolute",
        width: width,
        height: height,
        cursor: "pointer",
        opacity: 0,
      }}
      onChange={uploadPic}
    />
  )
}

export default InvisibleUploadImageInput
